import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'emerson-app-tab-link',
  templateUrl: './app-tab-link.component.html',
  styleUrls: ['./app-tab-link.scss'],
})
export class AppTabLinkComponent {

  @Input() shortMessage = '';
  @Input() message = 'Message';
  @Output() clicked: EventEmitter<void> = new EventEmitter();
  @Output() shortClicked: EventEmitter<void> = new EventEmitter();

  constructor() {
  }
}
