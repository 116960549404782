import {Component} from '@angular/core';
import {LearningResource} from '../../../model/learning-resource.model';
import {GoogleAnalyticsService} from '../../../../../core';

@Component({
    selector: 'ee-learning-resource-section',
    templateUrl: './learning-resource-section.component.html',
    styleUrls: ['./learning-resource-section.component.scss']
})

export class LearningResourceSectionComponent{
    public learningResources: LearningResource[] = [
        {
            title: 'Blog',
            content: 'Guidance on supplements and practice management articles, interviews, videos and webinars.',
            url: 'https://www.emersonecologics.com/blog/'
        },
        {
            title: 'Experts',
            content: 'Stay upto-date on best practices, protocols and research from Emerson\'s Medical Advisory Board and team of medical experts.',
            url: 'https://www.emersonecologics.com/blog/category/emerson-experts'
        },
        {
            title: 'Resources',
            content: 'Access supplement and health condition database for real-time, research-driven information.',
            url: 'https://www.emersonecologics.com/education/clinical-resources'
        }
    ] as LearningResource[];

    constructor(private ga: GoogleAnalyticsService) {
    }

    gaTrackingLearningResource(resourceName: string){
        this.ga.sendEvent('MegaMenu', 'LearningResource', resourceName, 1);
    }
}
