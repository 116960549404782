import {
  Component,
  OnInit,
  Input,
  Injectable,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {ItemModel} from '../../models';
import {AccountModel} from 'client/angular/core/models';
import {GoogleAnalyticsService} from '../../../../core';

@Injectable({
  providedIn: 'root'
})
export class LocationProvider {
  getLocation() {
    return document.location;
  }
}

export class ProductDescriptionEvents {
  public productView = {
    event: 'productView',
    customerType: 'practitioner',
    practitionerId: '',
    customerLoginStat: '',
    platform: '',
    ecommerce: {
      items: []
    }
  };
}

@Component({
  providers: [ProductDescriptionEvents],
  selector: 'ee-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.scss']
})
export class ProductDescriptionComponent implements OnInit, OnChanges {
  constructor(
    private locationProvider: LocationProvider,
    private googleAnalyticsService: GoogleAnalyticsService,
    private productDescriptionEvents: ProductDescriptionEvents
  ) {
  }

  @Input() user: AccountModel;
  @Input() item: ItemModel;
  @Input() purchaseNumber: number;
  @Input() hasInsufficientQty: boolean;

  hasBrandAgreement: boolean;
  isDoctorType: boolean;
  isLoggedIn = false;
  showBackInStockDate: boolean;

  isDescriptionOverLimit: boolean;
  descriptionCharLimit = 400;
  showFullDescription = true;
  displayProductDescription: string;

  analyticsViewEvent: { isSent: boolean, timeoutId: any, event: { [key: string]: any } } = {
    isSent: false,
    timeoutId: null,
    event: {}
  };

  ngOnChanges(change: SimpleChanges) {
    // Watch for changes to the user input
    if (change.user) {
      if (change.user.currentValue === undefined) {
        // Set a timeout to send the data as an unauthenticated user
        this.analyticsViewEvent.timeoutId = setTimeout(() => {
          this.analyticsViewEvent.isSent = true;
          this.googleAnalyticsService.sendGTMEvent(
            this.productDescriptionEvents.productView,
            this.analyticsViewEvent.event);
        }, 3000);
      } else if (!change.user.firstChange) {
        // Send data for an authenticated user viewing the page
        clearTimeout(this.analyticsViewEvent.timeoutId);
        this.analyticsViewEvent.isSent = true;
        // Update the authentication details
        // @ts-ignore
        this.analyticsViewEvent.event.practitionerId = this.user.customerId;
        this.analyticsViewEvent.event.customerLoginStat = true;
        this.googleAnalyticsService.sendGTMEvent(
          this.productDescriptionEvents.productView,
          this.analyticsViewEvent.event);
      }
    }
  }

  ngOnInit() {
    if (this.user) {
      this.isLoggedIn = true;
      this.isDoctorType = this.user.doctorTypes.length > 0;
    }
    this.hasBrandAgreement = this.isLoggedIn ? this.user.agreementTypes.includes(this.item.productDetails.brandCode) : false;
    const currentDate = new Date();
    this.showBackInStockDate = this.item.defaultInventory.expectedDeliverDate > currentDate;

    this.isDescriptionOverLimit = this.item.productDetails.description.length > this.descriptionCharLimit;
    this.showFullDescription = this.isDescriptionOverLimit;
    this.changeDescriptionLimit();

    // Save the view event
    this.analyticsViewEvent.event = {
      practitionerId: (this.user ? this.user : null),
      customerLoginStat: this.isLoggedIn,
      ecommerce: {
        items: [{
          // @ts-ignore
          item_name: this.item.productDetails.productName,
          // @ts-ignore
          item_id: this.item.itemId,
          price: this.item.userPrice,
          item_brand: this.item.productDetails.brandName,
          // @ts-ignore
          item_category: this.item.productDetails.category,
          item_variant: '',
          item_list_name: this.item.itemName
        }]
      }
    };
  }

  goToBrandSearch() {
    if (!this.item) {
      return;
    }
    const brandCodeUrl = this.item.productDetails.brandName.replace(' ', '-');
    const brandIdUrl = '-B' + this.item.productDetails.brandId.toString();
    const searchBrandUrl = '/shop/' + brandCodeUrl + brandIdUrl;
    this.locationProvider.getLocation().href = searchBrandUrl;
  }

  hasLimitedQuantity() {
    return this.hasInsufficientQty ||
      this.item.defaultInventory.stock > 0 &&
      this.purchaseNumber > this.item.defaultInventory.stock &&
      !this.item.isRestricted &&
      !this.item.defaultInventory.vendorBackOrder &&
      !this.item.defaultInventory.backOrder &&
      !this.item.isDiscontinued;
  }

  isItemDiscontinued() {
    return this.item.isDiscontinued &&
      !this.item.isRestricted;
  }

  isSpecialOrderItem() {
    return this.item.specialOrder &&
      !this.item.isRestricted;
  }

  hasInventoryStock() {
    return this.item.defaultInventory.stock > 0;
  }

  hasNoStockInformation() {
    return this.item.inventory.length === 0;
  }

  hasExpectedDeliveryDate() {
    return this.item.defaultInventory.expectedDeliverDate && this.showBackInStockDate;
  }

  isVendorBackOrderNoStock() {
    return this.item.inventory.length !== 0 &&
      this.item.defaultInventory.vendorBackOrder &&
      !this.item.isRestricted &&
      this.item.defaultInventory.stock <= 0;
  }

  isVendorBackOrderLimitedStock() {
    return this.item.defaultInventory.vendorBackOrder &&
      !this.item.isRestricted &&
      this.item.defaultInventory.stock > 0;
  }

  isOutOfStock() {
    return !this.hasInsufficientQty &&
      this.item.defaultInventory.stock <= 0 &&
      !this.item.isRestricted &&
      this.item.defaultInventory.backOrder &&
      !this.item.defaultInventory.vendorBackOrder &&
      !this.item.isDiscontinued;
  }

  hasUnsignedBrandAgreement() {
    return !this.hasBrandAgreement &&
      this.item.isRestricted &&
      this.item.restrictionCode === -15 &&
      this.isDoctorType;
  }

  isRestricted() {
    return !this.hasBrandAgreement &&
      this.item.restrictionCode !== -15 &&
      this.item.isRestricted;
  }

  isInStock() {
    return !this.hasInsufficientQty &&
      this.item.defaultInventory.stock > 0 &&
      !this.item.isRestricted &&
      !this.item.defaultInventory.backOrder &&
      !this.item.defaultInventory.vendorBackOrder &&
      !this.item.isDiscontinued;
  }

  changeDescriptionLimit() {
    const fullDescription = this.item.productDetails.description;
    if (this.showFullDescription && this.isDescriptionOverLimit) {
      const partialDescription =
        fullDescription.substring(0, fullDescription.lastIndexOf(' ', this.descriptionCharLimit)).trimRight();
      this.displayProductDescription = this.stripLastHtmlTag(partialDescription) + '...';
      this.showFullDescription = false;
    } else {
      this.displayProductDescription = fullDescription;
      this.showFullDescription = true;
    }
  }

  private stripLastHtmlTag(htmlContent: string) {
    return htmlContent.replace(/(<.+?>)$/, '');
  }
}
