<div #sliderContainer class="slider-container">
    <div #sliderGroup class="slider" [style.width]="100 * images.length+'%'">
        <div class="slider-panel" *ngFor="let image of images">
            <div class="slider-image img-responsive" (pan)="onPan($event)">
                <ee-product-image alt="slider-image"
                                  [fit]="true"
                                  [src]="image.detail"></ee-product-image>
            </div>
        </div>
    </div>
    <div #sliderPaginationGroup class="slider-pagination-group" *ngIf="images.length > 1">
        <div [id]="'slider-pagination-' + i" class="slider-pagination" *ngFor="let image of images; index as i"></div>
    </div>
</div>
