import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';

import { ToastService, LocalStorageFactoryService, ILocalStorageService, ServiceClientErrorModel, ISortOptionModel } from 'client/angular/core';

import { BackorderItemModel } from '../../../models';
import { BackorderFilteringService, BackorderSortType, BackorderSortTypes } from '../services/backorder-filtering/backorder-filtering.service';
import { BackorderDataService } from '../services/backorder-data/backorder-data.service';
import { BackorderCancellationDialogService } from '../services/backorder-cancellation-dialog/backorder-cancellation-dialog.service';
import { SeoMetaService } from '../../../../../core/services/seo-meta/seometa.service'

@Component({
    selector: 'app-backorder-list',
    templateUrl: './backorder-list.component.html',
    styleUrls: ['./backorder-list.component.scss']
})
export class BackorderListComponent implements OnInit, OnDestroy {
    constructor(
        private backorderDataService: BackorderDataService,
        private toastService: ToastService,
        private filteringService: BackorderFilteringService,
        private cancellationDialogService: BackorderCancellationDialogService,
        private seoMetaService: SeoMetaService,
        localStorageFactory: LocalStorageFactoryService) {
        this.seoMetaService = seoMetaService;
        this.seoMetaService.updateSeoMetaData(this, 'BackorderListComponent');
        this.localStorage = localStorageFactory.toComponentService('BackorderListComponent');
    }

    private static SortOptionLocalStorageKey = 'sort-option';

    private localStorage: ILocalStorageService;
    private allBackorderItems: BackorderItemModel[];

    @Output() redirectToPdp = new EventEmitter<{ item: BackorderItemModel, scrollToAlternatives: boolean }>();

    BackorderSortTypes = BackorderSortTypes;

    backorderItems: BackorderItemModel[];
    sortOption: ISortOptionModel<BackorderSortType> = { name: BackorderSortTypes[0], direction: 'asc' };
    filterSubstring = '';
    currentPage = 1;
    status: 'loading'|'failed'|'fetched' = 'loading';
    errorMessage: string;

    ngOnInit(): void {
        this.sortOption = this.localStorage.get(BackorderListComponent.SortOptionLocalStorageKey, { name: BackorderSortTypes[0], direction: 'asc' });
        this.status = 'loading';
        this.backorderDataService.getBackorderItems().subscribe(
            backorderItems => this.handleSuccess(backorderItems),
            error => this.handleError(error));
    }

    ngOnDestroy(): void {
        this.localStorage.save(BackorderListComponent.SortOptionLocalStorageKey, this.sortOption);
        this.seoMetaService.setDefaultSeoMetaData();
    }

    onSelectSort(sortOption: ISortOptionModel<BackorderSortType>) {
        this.sortOption = sortOption;
        this.currentPage = 1;
        this.sortAndFilter();
    }

    onFilterSubstringChanged() {
        this.currentPage = 1;
        this.sortAndFilter();
    }

    onViewAlternates(item: BackorderItemModel) {
        this.waitForCancellationDialogResponse(this.cancellationDialogService.openShowSimilarItemsDialog(item));
    }

    onCancelBackorder(item: BackorderItemModel) {
        this.waitForCancellationDialogResponse(this.cancellationDialogService.openCancelBackorderDialog(item));
    }

    onRedirectToPdp(item: BackorderItemModel) {
        this.redirectToPdp.emit({ item, scrollToAlternatives: false });
    }

    totalNumberOfItems(): number {
        return (this.allBackorderItems || []).length;
    }

    private waitForCancellationDialogResponse(promise: Promise<{viewAlternates: boolean, cancelled: boolean, item: BackorderItemModel}>) {
        promise.then(result => {
            if (result.cancelled) {
                this.allBackorderItems = this.allBackorderItems.filter(i => i !== result.item);
                // note: will move to appropriate page if all curret page items are cancelled
                if (this.allBackorderItems.length <= ((this.currentPage - 1) * 10)) this.currentPage--;
                this.sortAndFilter();
                this.toastService.showSuccessText(`Item ${result.item.itemCode} has been cancelled`);
            }
            if (result.viewAlternates) {
                this.redirectToPdp.emit({ item: result.item, scrollToAlternatives: true });
            }
        }, () => { });
    }

    private handleSuccess(backorderItems: BackorderItemModel[]) {
        this.allBackorderItems = backorderItems;
        this.sortAndFilter();
        this.status = 'fetched';
    }

    private handleError(error: ServiceClientErrorModel) {
        this.errorMessage = error.message;
        this.status = 'failed';
    }

    private sortAndFilter() {
        this.backorderItems = this.filteringService.filterAndSort(
            this.allBackorderItems,
            this.filterSubstring,
            this.sortOption
        );
    }
}