import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BannerModel } from '../../models';
import { ResponsiveImageModel } from 'client/angular/core';

@Component({
    selector: 'ee-banner',
    templateUrl: './banner.component.html',
    styleUrls: [],
})
export class BannerComponent implements OnInit {
    @Input() banner: BannerModel;

    @Output() clicked = new EventEmitter<BannerModel>();

    images: { [key: string]: ResponsiveImageModel };

    ngOnInit(): void {

        const images = {
            '(min-width: 1140px)': {
                cssStyles: {
                    width: '1110px',
                    padding: '0',
                    cursor: 'pointer',
                    'margin-top': '-1px',
                    'margin-bottom': '-40px'
                },
                url: this.banner.desktopImageUrl
            },
            '(max-width: 1139px) and (min-width: 768px)': {
                cssStyles: {
                    width: '100%',
                    padding: '0 15px',
                    cursor: 'pointer',
                    'margin-top': '-1px',
                    'margin-bottom': '-40px'
                },
                url: this.banner.desktopImageUrl
            },
            '(max-width: 767px)': {
                cssStyles: {
                    width: '100%',
                    padding: '0 15px',
                    cursor: 'pointer',
                    'margin-top': '-1px',
                    'margin-bottom': '-40px'
                },
                url: this.banner.mobileImageUrl
            }
        };

        this.images = images;
    }

    onClicked() {
        this.clicked.emit(this.banner);
    }
}