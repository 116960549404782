<ee-expandable-panel panelTitle="Product Info"
                     [hideBordersWhenPrinted]="true">

    <ee-item-ingredients [item]="item"></ee-item-ingredients>

</ee-expandable-panel>


<ee-expandable-panel panelTitle="Dietary Considerations"
                     [hideBordersWhenPrinted]="true"
                     *ngIf="hasDietaryConsiderations()">
    <ul class="dietary-considerations">
        <li *ngFor="let item of item.productDetails.dietaryConsiderations">{{item}}</li>
    </ul>
</ee-expandable-panel>


<ee-expandable-panel panelTitle="Suggested Use"
                     [hideBordersWhenPrinted]="true"
                     *ngIf="hasSuggestedUse()">
    <ee-safe-html spacing="20"
                  [html]="item.productDetails.suggestedUse"></ee-safe-html>
</ee-expandable-panel>


<ee-expandable-panel panelTitle="Warnings"
                     [hideBordersWhenPrinted]="true"
                     *ngIf="hasWarnings()">
    <div class="vertical-spacing-20-px">
        <ee-safe-html *ngIf="hasWarningHtml()"
                      spacing="20"
                      [html]="item.productDetails.warnings"></ee-safe-html>

        <div *ngIf="isHeatSensitive()">
            <span class="bolded">Heat Sensitive:</span>
            <span>Overnight shipping is recommended. Not recommended for international shipping.</span>
        </div>

        <div *ngIf="shortShelfLife()">
            <span class="bolded">Short Shelf Life:</span>
            <span>Item has a short shelf life of 6 months or less.</span>
        </div>
    </div>
</ee-expandable-panel>


<ee-expandable-panel panelTitle="Education"
                     [hideBordersWhenPrinted]="true"
                     class="education-panel"
                     *ngIf="isPractitioner()">
    <a href="https://www.emersonecologics.com/education/clinical-resources">RESOURCES</a> - Access supplement and health condition databases for real-time, research-driven information.
</ee-expandable-panel>
