import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ItemModel } from '../../models';
import { ProductActionsBackInStockDialogComponent } from '../../components/product-actions-back-in-stock-dialog/product-actions-back-in-stock-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class BackInStockDialogService {

    constructor(private modalService: NgbModal) { }

    openDialog(item: ItemModel, outOfStockMessage: string, similarProductsExist: boolean) {
        const ref = this.modalService.open(
            ProductActionsBackInStockDialogComponent,
            { centered: true, windowClass: 'squared-dialog-style' });

        const component = ref.componentInstance as ProductActionsBackInStockDialogComponent;
        component.item = item;
        component.outOfStockMessage = outOfStockMessage;
        component.similarProductsExist = similarProductsExist;

        return {
            destroy: () => this.modalService.dismissAll(),
            callback: this.createCallbackPromise(ref.result, component)
        };
    }

    private createCallbackPromise(promise: Promise<any>, component: ProductActionsBackInStockDialogComponent) {
        return promise.then(success => {
            return success as {
                scrollToSimilarProducts: boolean,
                subscribed: boolean
            };
        }, () => {
            return {
                scrollToSimilarProducts: false,
                subscribed: component.hasSuccess
            };
        });
    }
}
