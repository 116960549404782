import {Injectable} from '@angular/core';
import {LineItemModel} from '../../../modules/product-details/models';

@Injectable({
  providedIn: 'root'
})
export class WindowProvider {
  getWindow(): Window {
    return window;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor(private windowProvider: WindowProvider) {
  }

  private getWindow() {
    return this.windowProvider.getWindow();
  }

  sendEvent(eventCategory: string, eventAction: string, eventLabel: string, eventValue: number) {
    if (!(this.getWindow() as any).ga) return;
    (this.getWindow() as any).ga('send', 'event', eventCategory, eventAction, eventLabel, eventValue);
  }

  sendGTMEvent(data: { [key: string]: any }, patchData: { [key: string]: any }) {
    // Make sure dataLayer is defined on the window scope
    // NOTE, GTM can read from this if included after this code has been evaluated within the call stack
    if (!(this.getWindow() as any).dataLayer) {
      (this.getWindow() as any).dataLayer = [];
    }
    // Force the item to be an object
    if (data.constructor !== Object) {
      data = {};
    }
    // Specify a default when needed
    if (patchData) {
      for (const key in patchData) {
        if (patchData.hasOwnProperty(key)) {
          data[key] = patchData[key];
        }
      }
    }
    // Every event should have the platform set to 'emerson'
    data.platform = 'emerson';
    (this.getWindow() as any).dataLayer.push(data);
  }

  /**
   * Responsible for creating a line item used for tracking with Google Analytics
   * @param lineItems - the list of line items from cart.aresLineItems
   */
  generateLineItemsTracking(lineItems: any[]) {
    const returnArr = [];
    lineItems.forEach((lineItem) => {
      const returnObj = {
        item_name: lineItem.productName,
        item_id: lineItem.itemId,
        price: lineItem.unitPrice,
        item_brand: lineItem.brandName,
        item_variant: '',
        item_list_name: null,
        quantity: lineItem.quantity
      };
      let categories = lineItem.taxonomies ?? [];
      categories = [...new Set(categories)];
      categories.forEach((categoryName, index) => {
        let key = 'item_category';
        if (index) {
          key += index;
        }
        returnObj[key] = categoryName;
      });
      returnArr.push(returnObj);
    });
    return returnArr;
  }
}
