import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ServiceClientService} from '../../../core';

@Injectable({
    providedIn: 'root'
})
export class SsoService{
    constructor(private client: ServiceClientService){
    }

    resendEmail(): Observable<any> {
        return this.client.postAsync('auth', `/sso/merge-email?isThrottled=false`, {});
    }
}
