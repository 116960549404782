import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreModule } from 'client/angular/core';

import { ProductThumbnailsComponent } from './components/product-thumbnails/product-thumbnails.component';
import { ProductImagesComponent } from './components/product-images/product-images.component';
import { ProductImageComponent } from './components/product-image/product-image.component';
import { TouchSlideImageComponent } from './components/touch-slide-image/touch-slide-image.component';
import { ProductCarouselComponent } from './components/product-carousel/product-carousel.component';
import { ProductActionsComponent } from './components/product-actions/product-actions.component';
import { ProductActionsPriceComponent } from './components/product-actions-price/product-actions-price.component';
import { ProductActionsFreeShippingComponent } from './components/product-actions-free-shipping/product-actions-free-shipping.component';
import { ProductActionsContactsComponent } from './components/product-actions-contacts/product-actions-contacts.component';
import { ProductActionsChatUnavailableComponent } from './components/product-actions-chat-unavailable/product-actions-chat-unavailable.component';
import { ProductActionsUnauthenticatedComponent } from './components/product-actions-unauthenticated/product-actions-unauthenticated.component';
import { ProductActionsAddComponent } from './components/product-actions-add/product-actions-add.component';
import { ProductActionsBackInStockComponent } from './components/product-actions-back-in-stock/product-actions-back-in-stock.component';
import { ProductDescriptionComponent } from './components/product-description/product-description.component';
import { ProductAccordionComponent } from './components/product-accordion/product-accordion.component';
import { SafeHtmlComponent } from './components/safe-html/safe-html.component';
import { ItemIngredientsComponent } from './components/item-ingredients/item-ingredients.component';
import { ProductActionsBackInStockDialogComponent } from './components';


@NgModule({
    declarations: [
        ProductThumbnailsComponent,
        ProductImagesComponent,
        ProductImageComponent,
        TouchSlideImageComponent,
        ProductCarouselComponent,

        ProductActionsComponent,
        ProductActionsPriceComponent,
        ProductActionsFreeShippingComponent,
        ProductActionsContactsComponent,
        ProductActionsChatUnavailableComponent,
        ProductActionsBackInStockDialogComponent,
        ProductActionsUnauthenticatedComponent,
        ProductActionsAddComponent,
        ProductActionsBackInStockComponent,

        ProductDescriptionComponent,

        ProductAccordionComponent,
        SafeHtmlComponent,
        ItemIngredientsComponent
    ],
    entryComponents: [
        ProductActionsChatUnavailableComponent,
        ProductActionsBackInStockDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        CoreModule,
        PinchZoomModule,
        NgbModule
    ]
})
export class ProductDetailsModule { }
