<div>

    <div class="zoomed"
         [style.visibility]="hovering ? 'visible' : 'hidden'"
         [style.left.px]="zoomedRect.left"
         [style.top.px]="zoomedRect.top"
         [style.width.px]="zoomedRect.width"
         [style.height.px]="zoomedRect.height">

        <div [style.left.px]="zoomedPosition.left"
             [style.top.px]="zoomedPosition.top"
             #zoomedContentHolder>
            <ng-content select="[full]"></ng-content>
        </div>
    </div>

    <div class="frame" *ngIf="frameRect != null"
         [style.display]="hovering ? 'inline-block' : 'none'">

        <div [style.left.px]="frameRect.left"
             [style.top.px]="frameRect.top"
             [style.width.px]="frameRect.width"
             [style.height.px]="frameRect.height"></div>
    </div>

    <div class="overlay" *ngIf="overlaySize != null">

        <div (mousemove)="onMouseMove($event)"
             mouseoverz="onMouseIn($event)"
             (mouseout)="onMouseOut()"

             [style.width.px]="overlaySize.width"
             [style.height.px]="overlaySize.height"></div>
    </div>

    <div #hoverableContentHolder (mouseover)="onMouseIn($event)" class="hoverable">
        <ng-content></ng-content>
    </div>

</div>