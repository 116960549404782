<div *ngIf="images != null && eqpStatus != 'None'" class="eqp-badge">
    <img *ngIf="eqpStatus == 'Silver'" src="../../../../../assets/images/eqp_seal_silver.png" />
    <img *ngIf="eqpStatus == 'Gold'" src="../../../../../assets/images/eqp_seal.png" />
</div>

<div *ngIf="images != null && !showSwipableComponent" class="non-swipable">

    <product-thumbnail [images]="images" (selectionChanged)="onSelected($event)"></product-thumbnail>

    <ee-zoomable *ngIf="current != null && !currentLargeImageFailed" (click)="onClicked()">

        <ee-product-image alt="zoomable detailed image"
                          [fit]="true"
                          [src]="current.detail"></ee-product-image>

        <div style="width: 1500px" full>
            <ee-product-image [fit]="true"
                              alt="zoomed image"
                              [src]="current.zoom"
                              (productImageError)="failedWithZoomImage()"></ee-product-image>
        </div>

    </ee-zoomable>

    <div class="non-hoverable" *ngIf="current != null && !currentLargeImageFailed" (click)="onClicked()">
        <ee-product-image alt="detailed image"
                          [fit]="true"
                          [src]="current.detail"></ee-product-image>
    </div>

    <div *ngIf="current != null && currentLargeImageFailed" (click)="onClicked()">
        <ee-product-image alt="detailed image"
                          [fit]="true"
                          [src]="current.detail"></ee-product-image>
    </div>
</div>

<div *ngIf="images != null && showSwipableComponent">
    <touch-slide-image [images]="images"></touch-slide-image>
</div>

<div *ngIf="hasError" class="error">
    <img src="../../../../../assets/images/icons/Image_Not_Available.png" alt="failed loading images" />
</div>
