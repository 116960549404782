import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BannerModel } from '../../models';
import { BannerService, SearchOptionsType } from '../../services/banner/banner.service';

@Component({
    selector: 'ee-banner-placeholder',
    templateUrl: './banner-placeholder.component.html',
    styleUrls: [],
})
export class BannerPlaceholderComponent implements OnInit {
    constructor(private bannerService: BannerService) { }

    @Input() searchOptions: SearchOptionsType;
    @Output() actionTriggered = new EventEmitter<{ actionUrl: string }>();

    banner: BannerModel;

    ngOnInit(): void {
        this.bannerService.findApplicableBanner(this.searchOptions).then(result => {
            this.display(result);
        }, () => {
            this.display(null);
        });
    }

    onClick() {
        const actionUrl = this.banner.actionUrl;
        this.actionTriggered.emit({ actionUrl });
    }

    private display(banner: BannerModel) {
        this.banner = banner;
    }
}