<div *ngIf="showMegaMenu" class="accordion-menu-container">
    <ul class="accordion-root-level">
        <li *ngIf="singleRegFeatureFlagEnabled && !accountInfo" class="accordion-root-level-item">
            <div class="accordion-root-level-container">
                <a class="accordion-root-level-title" href="#" (click)="redirectToSignup($event)">
                    SIGN UP
                </a>
            </div>
        </li>
        <li class="accordion-root-level-item">
            <div class="accordion-root-level-container" (click)="setActiveSection('shop')">
                <a class="accordion-root-level-title">
                    SHOP
                    <span class="arrow-icon green" [ngClass]="{'expand-arrow': activeSection == 'shop'}"></span>
                </a>
            </div>
            <ul *ngIf="activeSection == 'shop'" class="accordion-first-level" [ngClass]="{'active-section': activeSection == 'shop'}">
                <li class="accordion-first-level-item" *ngFor="let category of categories">
                    <div class="accordion-first-level-container" (click)="setActiveCategory(category.category)">
                        <a class="accordion-first-level-title">
                            {{category.category}}
                            <span class="arrow-icon green" [ngClass]="{'expand-arrow': activeCategory == category.category}"></span>
                        </a>
                    </div>
                    <ul *ngIf="activeCategory == category.category" class="accordion-second-level" [ngClass]="{'active-category': activeCategory == category.category}">
                        <li class="accordion-second-level-item" *ngFor="let subCategory of category.subCategory">
                            <div class="accordion-second-level-container">
                                <a class="accordion-second-level-title" (click)="gaTracking(category.category, subCategory.subCategory); resetActiveSection();" href="./shop/{{urlStrip(subCategory.subCategory)}}-S{{subCategory.taxonomyId}}">{{subCategory.subCategory}}</a>
                            </div>
                        </li>
                    </ul>
                </li>

                <li class="accordion-first-level-item">
                    <div class="accordion-first-level-container" (click)="setActiveCategory('brands')">
                        <a class="accordion-first-level-title">
                            Brands
                            <span class="arrow-icon green" [ngClass]="{'expand-arrow': activeCategory == 'brands'}"></span>
                        </a>
                    </div>
                    <div *ngIf="activeCategory == 'brands'" class="accordion-first-level" [ngClass]="{'active-category': activeCategory == 'brands'}">
                        <div class="accordion-first-level-container">
                            <ee-brand-section></ee-brand-section>
                        </div>
                    </div>
                </li>
                <li class="accordion-first-level-item">
                    <div class="accordion-first-level-container" (click)="setActiveCategory('newProducts')">
                        <a class="accordion-first-level-title">
                            New Products
                            <span class="arrow-icon green" [ngClass]="{'expand-arrow': activeCategory == 'newProducts'}"></span>
                        </a>
                    </div>
                    <div *ngIf="activeCategory == 'newProducts'" class="accordion-first-level" [ngClass]="{'active-category': activeCategory == 'newProducts'}">
                        <div class="accordion-first-level-container">
                            <div class="new-products-header">
                                <div class="new-products-header-text"><img class="new-products-header-logo" alt="logo" src="/assets/images/EE-leaf-logo.svg">
                                    Shop All New Products </div>
                            </div>
                            <div class="new-products-sub-header">Featured New Products</div>
                            <div class="new-products">
                                <div class="new-product-card" *ngFor="let product of newProducts | slice: 0: 4;">
                                    <div class="new-product-container">
                                        <a class="new-product-link" (click)="gaTracking('FeaturedProduct', product.itemId); resetActiveSection();" href="/products/detail/{{product.attributeName | urlParameter}}/{{product.productName | urlParameter}}/{{product.productId}}/{{product.itemId}}">
                                            {{product.productName}}
                                            <div class="new-product-image-container">
                                                <img class="new-product-image" alt="new product" [src]="product.itemImageUrl">
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="accordion-first-level-item">
                    <div class="accordion-first-level-container" (click)="setActiveCategory('specials')">
                        <a class="accordion-first-level-title">
                            Specials
                            <span class="arrow-icon green" [ngClass]="{'expand-arrow': activeCategory == 'specials'}"></span>
                        </a>
                    </div>
                  <div *ngIf="activeCategory == 'specials'" class="accordion-first-level" [ngClass]="{'active-category': activeCategory == 'specials'}">
                    <div class="accordion-first-level-container">
                      <ee-specials-section mobileMenu="true"></ee-specials-section>
                    </div>
                  </div>
                </li>
                <li class="accordion-first-level-item">
                    <div class="accordion-first-level-container" (click)="setActiveCategory('learningResources')">
                        <a class="accordion-first-level-title">
                            Learning Resources
                            <span class="arrow-icon green" [ngClass]="{'expand-arrow': activeCategory == 'learningResources'}"></span>
                        </a>
                    </div>
                    <div *ngIf="activeCategory == 'learningResources'" class="accordion-first-level" [ngClass]="{'active-category': activeCategory == 'learningResources'}">
                        <div class="accordion-first-level-container">
                            <ee-learning-resource-section></ee-learning-resource-section>
                        </div>
                    </div>
                </li>

                <li *ngIf="accountInfo" class="accordion-first-level-item reorder-theme" [ngClass]="{'highlighted': activeCategory == 'reorderProducts'}">
                    <div class="accordion-first-level-container" (click)="setActiveCategory('reorderProducts')">
                        <a class="accordion-first-level-title reorder-theme" [ngClass]="{'highlighted': activeCategory == 'reorderProducts'}">
                            Reorder Products
                            <span class="arrow-icon" [ngClass]="{'expand-arrow': activeCategory == 'reorderProducts'}"></span>
                        </a>
                    </div>
                    <div *ngIf="activeCategory == 'reorderProducts'" class="accordion-first-level reorder-theme" [ngClass]="{'active-category': activeCategory == 'reorderProducts'}">
                        <div class="accordion-first-level-container">
                            <ee-reorder-products-section></ee-reorder-products-section>
                        </div>
                    </div>
                </li>
            </ul>
        </li>
<!--        hide for future mega menu development-->
            <li class="accordion-root-level-item" *ngIf="false">
                <div class="accordion-root-level-container" (click)="setActiveSection('brands')">
                    <a class="accordion-root-level-title">
                        BRANDS
                        <span class="arrow-icon green" [ngClass]="{'expand-arrow': activeSection == 'brands'}"></span>
                    </a>
                </div>
            </li>
            <li class="accordion-root-level-item" *ngIf="false">
                <div class="accordion-root-level-container" (click)="setActiveSection('education')">
                    <a class="accordion-root-level-title">
                        EDUCATION
                        <span class="arrow-icon green" [ngClass]="{'expand-arrow': activeSection == 'education'}"></span>
                    </a>
                </div>
            </li>
            <li class="accordion-root-level-item" *ngIf="false">
                <div class="accordion-root-level-container" (click)="setActiveSection('quality')">
                    <a class="accordion-root-level-title">
                        QUALITY
                        <span class="arrow-icon green" [ngClass]="{'expand-arrow': activeSection == 'quality'}"></span>
                    </a>
                </div>
            </li>
    </ul>
</div>
