<div class="resource-section">
    <a class="resource-section-link"
       target="_blank"
       (click)="gaTrackingLearningResource(resource.title)" 
       *ngFor="let resource of learningResources" 
       [href]="resource.url">
        <div class="resource-section-container">
            <h1 class="resource-section-title">{{resource.title}}</h1>
            <div class="resource-section-content">
                {{resource.content}}
            </div>
        </div>
    </a>
</div>
