import { Component, OnInit, Input } from '@angular/core';

import { OrderHistoryService } from '../../services/order-history.service';
import { InvoiceModel } from '../../models';
import { AddressPipeType, ServiceClientErrorModel } from 'client/angular/core';

export enum PageStatus {
    Done,
    Waiting,
    Unauthorized,
    Custom,
    Unexpected
}

@Component({
    selector: 'app-invoice-page',
    templateUrl: './invoice-page.component.html',
    styleUrls: ['./invoice-page.component.less']
})
export class InvoicePageComponent implements OnInit {

    @Input() invoiceNumber: string;

    AddressPipeType = AddressPipeType;
    PageStatus = PageStatus;

    invoice: InvoiceModel;
    status: PageStatus;
    errorMessage: string;

    constructor(private orderService: OrderHistoryService) {
    }

    ngOnInit(): void {
        this.requestData();
    }

    requestData() {
        this.status = PageStatus.Waiting;
        this.orderService.getInvoice(this.invoiceNumber)
            .subscribe(
                invoice => this.handleSuccess(invoice),
                error => this.handleError(error));
    }

    private handleSuccess(invoice: InvoiceModel) {
        this.invoice = invoice;
        this.status = PageStatus.Done;
    }

    private handleError(error: ServiceClientErrorModel) {
        if (error.code > 0) {
            this.status = PageStatus.Custom;
            this.errorMessage = error.message;
        } else {
            if (error.httpStatusCode === 401)
                this.status = PageStatus.Unauthorized;
            else
                this.status = PageStatus.Unexpected;
        }
    }

}
