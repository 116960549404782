import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { from, Observable, Subscription } from 'rxjs';

import { CartChangesService, ServiceClientErrorModel, CartSummaryModel, GoogleAnalyticsService, AccountModel } from 'client/angular/core';
import { ItemModel, CartModel } from '../../models';
import { ProductDetailsService } from '../../services';

@Component({
    selector: 'ee-product-actions',
    templateUrl: './product-actions.component.html',
    styleUrls: ['./product-actions.component.scss']
})
export class ProductActionsComponent implements OnInit, OnDestroy {
    constructor(private service: ProductDetailsService, private cartChanges: CartChangesService) {}

    @Input() productId: number;
    @Input() itemCode: string;
    @Input() similarProductsExist: boolean;
    @Input() showBackInStockSubscription: boolean;
    @Input() outOfStockMessage: string;
    @Output() viewSimilarProducts = new EventEmitter();
    @Output() itemSelected = new EventEmitter<{ item: ItemModel }>();
    @Output() productDetailsLoaded = new EventEmitter<{
        item?: ItemModel,
        failed: boolean,
        httpStatusCode: number
    }>();
    @Output() userLoaded = new EventEmitter<AccountModel>();

    item: ItemModel;
    cart: CartModel;
    user: AccountModel;
    cartItemQuantity = 0;
    notLoggedIn = false;

    private subscription: Subscription;

    ngOnInit() {
        this.subscription = this.cartChanges.cartId().subscribe(_ => {
            this.cart = null;
            this.service.getCart().subscribe(cart => {
                this.cart = cart;
                this.calculateCartQuantity();
            }, () => {
                // legitimate reason to fail: user is not authenticated
            });
        });

        this.getAccountInfo().subscribe(user => {
            this.user = user;

            this.userLoaded.emit(user);
        }, () => {
            this.notLoggedIn = true;
        });

        this.service.getProductDetails(this.productId, this.itemCode).subscribe(productDetails => {
            const filtered = productDetails.items.length === 1
                                ? productDetails.items
                                : productDetails.items.filter(it => it.itemCode === this.itemCode);
            const item = filtered.length > 0 ? filtered[0] : null;
            if (item == null) {
                this.productDetailsLoaded.emit({ failed: true, httpStatusCode: 404 });
                return;
            }

            const defaultInventories = (item.inventory || []).filter(inx => inx.isDefault);
            const defaultInventory = defaultInventories.length > 0
                                        ? defaultInventories[0]
                                        : { stock: 0,
                                            isDefault: true,
                                            vendorBackOrder: false,
                                            shortShelfLife: false,
                                            backOrder: false,
                                            expectedDeliverDate: null };

            productDetails.items.forEach(eachItem => eachItem.productDetails = productDetails);
            item.defaultInventory = defaultInventory;

            this.item = item;

            this.productDetailsLoaded.emit({
                item: this.item,
                failed: false,
                httpStatusCode: 200
            });
        }, (error: ServiceClientErrorModel) => {
            this.productDetailsLoaded.emit({ failed: true, httpStatusCode: error.httpStatusCode });
        });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    showAddButton() {
        return this.user != null &&
               this.item != null &&
               this.item.defaultInventory != null &&
               !this.isViewSimilarProductsButtonVisible();
    }

    disableAddButton() {
        if (this.user == null || this.item == null)
            return true;

        if (this.item.defaultInventory.stock <= 0 && (this.item.defaultInventory.vendorBackOrder || this.item.isDiscontinued))
            return true;

        if (this.item.isRestricted)
            return true;

        if (this.getMaximumItemQuantityAllowed() <= 0)
            return true;

        return false;
    }

    isViewSimilarProductsButtonVisible() {
        if (this.user == null)
            return false;

        if (!this.similarProductsExist)
            return false;

        if (this.item == null)
            return false;

        if (this.item.defaultInventory.stock > 0)
            return false;

        if (!this.item.defaultInventory.vendorBackOrder && !this.item.isDiscontinued)
            return false;

        return true;
    }

    onViewSimilarProducts() {
        this.viewSimilarProducts.emit();
    }

    onItemSelected(item: ItemModel) {
        this.itemSelected.emit({ item });
    }

    onItemAdded(data: { cartSummary: CartSummaryModel, quantityAdded: number }) {
        this.cart.discountedSubTotal = data.cartSummary.discountedSubtotal;
        this.cartChanges.setNewCartSummary(data.cartSummary);
        this.cartItemQuantity += data.quantityAdded;
    }

    getMaximumItemQuantityAllowed() {
        const maximumItemQuantityAllowed = 9999;
        if (this.item == null || this.item.defaultInventory == null || !this.item.defaultInventory.vendorBackOrder)
            return maximumItemQuantityAllowed;

        const stock = this.item.defaultInventory.stock;
        const cartQuantity = this.cartItemQuantity;

        return stock - cartQuantity;
    }

    private calculateCartQuantity() {
        if (this.cart == null || this.cart.aresLineItems == null)
            return;

        const lineItem = this.cart.aresLineItems.find(item => item.itemId === this.itemCode);
        this.cartItemQuantity = lineItem != null ? lineItem.quantity : 0;
    }

    getAccountInfo(): Observable<AccountModel> {
        return from(this.service.getAccountInfo());
    }
}
