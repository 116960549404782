import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ToastService } from 'client/angular/core';
import { ItemModel, CartSummaryModel } from '../../models';
import { ProductDetailsService } from '../../services';

@Component({
    selector: 'ee-product-actions-add',
    templateUrl: './product-actions-add.component.html',
    styleUrls: ['./product-actions-add.component.scss']
})
export class ProductActionsAddComponent implements OnInit {
    constructor(private service: ProductDetailsService, private toasts: ToastService) { }

    @Input() disableAddButton: boolean;
    @Input() showAddButton: boolean;
    @Input() item: ItemModel;
    @Input() maximumValue = 9999;
    @Output() itemSelected = new EventEmitter<ItemModel>();
    @Output() itemAdded = new EventEmitter<{ cartSummary: CartSummaryModel, quantityAdded: number }>();

    selectedIndex = 0;
    processing = false;
    amount = 1;

    addToAmount(value: number) {
        this.amount += value;
    }

    onBlur($event) {
        if (this.amount > this.maximumValue)
            this.amount = this.maximumValue;
        $event.srcElement.value = '' + this.amount;
    }

    onInput($event) {
        const value = $event.srcElement.value;
        if (value === '')
            return;

        let parsed = parseInt(value, 10);
        if (isNaN(parsed)) {
            $event.srcElement.value = '' + this.amount;
            return;
        }

        if (parsed < 1) parsed = 1;
        if (value !== ('' + parsed)) {
            $event.srcElement.value = '' + this.amount;
            return;
        }

        this.amount = parsed;
    }

    onItemSelected() {
        this.itemSelected.emit(this.item.productDetails.items[this.selectedIndex]);
    }

    onAddItem() {
        this.processing = true;
        this.service.addItemToCurrentCart(this.item.itemCode, this.amount, this.item)
                    .subscribe(cartSummary => {
                        this.toasts.showSuccessText(`Successfully added ${this.item.itemCode} item to the cart.`);
                        this.itemAdded.emit({ cartSummary, quantityAdded: this.amount });
                        this.amount = 1;
                    }, () => {
                        this.toasts.showFailedText(`Failed to add ${this.item.itemCode} item to the cart. Please, try again later.`);
                    })
                    .add(() => {
                        this.processing = false;
                    });
    }

    ngOnInit() {
        const selectedIndex = this.item.productDetails.items.findIndex(item => item.itemCode === this.item.itemCode);
        this.selectedIndex = selectedIndex === -1 ? 0 : selectedIndex;
    }
}
