import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { InvoicePageComponent } from './pages/invoice-page/invoice-page.component';
import { BackorderListComponent } from './pages/backorder-page/backorder-list/backorder-list.component';
import { BackorderCardComponent } from './pages/backorder-page/backorder-card/backorder-card.component';
import { BackorderCancellationDialogComponent } from './pages/backorder-page/backorder-cancellation-dialog/backorder-cancellation-dialog.component';
import { CoreModule, CacheInterceptor } from 'client/angular/core';


@NgModule({
    declarations: [
        InvoicePageComponent,
        BackorderListComponent,
        BackorderCardComponent,
        BackorderCancellationDialogComponent
    ],
    entryComponents: [
        BackorderCancellationDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        CoreModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
    ]
})
export class OrderHistoryModule { }
