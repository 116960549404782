import { Component, Input, OnInit} from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';

import { Agreement, AgreementStatus, AgreementSigningProvider, AgreementSignature } from '../../../models';
import { BrandAgreementsService } from '../../../services/brand-agreements.service';
import { AccountModel, ToastService } from 'client/angular/core';

interface Presentation {
    hasApplyButton: boolean;
    informationText: string[];
    applyButtonText?: string;
    sendRequestMethod?: () => Observable<AgreementSignature>;
}

@Component({
    selector: 'brand-agreement-popup',
    templateUrl: './brand-agreement-popup.component.html',
    styleUrls: ['./brand-agreement-popup.component.scss']
})
export class BrandAgreementPopupComponent implements OnInit {
    constructor(private brandAgreementsService: BrandAgreementsService,
                private activeModal: NgbActiveModal,
                private datePipe: DatePipe,
                private toasts: ToastService) {
    }

    @Input() brandAgreement: Agreement;
    @Input() email: string;
    @Input() accountInfo: AccountModel;

    presentation: Presentation;
    waiting = false;
    errorMessage: string;
    tipContent = 'THIS WILL RESET THE DOCUSIGN PROCESS FOR THE CUSTOMER';

    ngOnInit(){
        this.presentation = this.getPresentation();
    }

    private getPresentation(): Presentation {
        const date = this.brandAgreement.signature?.recentActionDate;
        const recentDate = this.datePipe.transform(date != null ? new Date(date) : new Date(), 'MM/dd/yyyy');

        switch (this.brandAgreement.signature?.status) {
            case AgreementStatus.CustomerSigning:
                return {
                    hasApplyButton: true,
                    applyButtonText: 'Resend Email',
                    sendRequestMethod: () => this.brandAgreementsService.resendDocusign(this.brandAgreement.agreementId),
                    informationText: [`The agreement has been sent to your email on ${recentDate}.`,
                                      `Didn't receive an email? Press "Resend Email" button to reissue an email to "${this.email}".`]
                };
            case AgreementStatus.CsaDeclined:
            case AgreementStatus.CustomerDeclined:
                return {
                    hasApplyButton: true,
                    applyButtonText: 'Start Over',
                    sendRequestMethod: () => this.brandAgreementsService.sendDocusign(this.brandAgreement.agreementId),
                    informationText: this.brandAgreement.signature.status === AgreementStatus.CustomerDeclined
                        ? [`You have chosen to decline the agreement on ${recentDate}.`,
                            `You will have to start the signing process again.`]
                        : [`The agreement has been declined by customer support.`,
                           `You will have to start the signing process again.`]
                };
            case AgreementStatus.CustomerSigned:
                return  {
                            hasApplyButton: false,
                            informationText: [`The agreement is pending on review from our customer support team.`]
                        };
            case AgreementStatus.Signed:
                return {
                    hasApplyButton: false,
                    informationText: [`You have accepted this agreement on ${recentDate}.`]
                };
            default:
                return this.isDocusign()
                    ? {
                        hasApplyButton: true,
                        applyButtonText: 'Send Email',
                        sendRequestMethod: () => this.brandAgreementsService.sendDocusign(this.brandAgreement.agreementId),
                        informationText: [`By clicking "Send Email", the agreement will be sent to your email "${this.email}".`]
                    } : {
                        hasApplyButton: true,
                        applyButtonText: 'I Agree',
                        sendRequestMethod: () => this.brandAgreementsService.signBrandAgreement(this.brandAgreement.agreementId),
                        informationText: [`Click "I Agree" button to accept the agreement.`]
                    };
        }
    }

    closeModal(){
        this.activeModal.close();
    }

    apply() {
        this.applyWithMethod(this.presentation.sendRequestMethod());
    }

    sendHardReset() {
        this.applyWithMethod(this.brandAgreementsService.hardResetDocusign(this.brandAgreement.agreementId));
    }

    isDocusign() {
        return this.brandAgreement.signingProvider === AgreementSigningProvider.Docusign;
    }

    private applyWithMethod(callback: Observable<AgreementSignature>) {
        this.waiting = true;
        this.errorMessage = null;
        callback.subscribe(
            signature => {
                this.brandAgreement.signature = signature;
                this.closeModal();
                const message = this.brandAgreement.signingProvider === AgreementSigningProvider.Direct
                    ? `Successfully agreed with "${this.brandAgreement.title}" brand agreement.`
                    : `The email has been sent to "${this.email}".`;
                this.toasts.showSuccessText(message);
                this.waiting = false;
            },
            () => {
                this.errorMessage = 'Unexpected error occurred, please try again later.';
                this.waiting = false;
            }
        );
    }
}
