import { Pipe, PipeTransform } from '@angular/core';
import { AddressModel } from '../models';
import { AddressService } from '../services';

export enum AddressPipeType {
    Recipient,
    Street,
    City
}

@Pipe({name: 'address'})
export class AddressPipe implements PipeTransform {
    constructor(private addressService: AddressService) {}

    transform(address: AddressModel, type: AddressPipeType): string {
        if (address == null)
            return '';

        switch (type) {
            case AddressPipeType.Recipient:
                return address.recipientName || '';

            case AddressPipeType.Street:
                return this.joinNonEmptyValues([address.street1, address.street2], ', ');

            case AddressPipeType.City:
                let postalCode = address.postalCode;
                if (this.addressService.isDomesticAddress(address) && address.postalCode?.length === 9) {
                    postalCode = address.postalCode.replace(/(\d{5})(\d{4})/, '$1-$2');
                }
                return this.joinNonEmptyValues([
                    this.joinNonEmptyValues([
                        address.city,
                        this.joinNonEmptyValues([address.regionCode, postalCode], ' ')
                    ], ', '),
                    address.countryCode
                ], ' ');

            default:
                throw new Error(`Unrecognized address pipe parameter '${type}', expecting one of AddressPipeType values.`);
        }
    }

    private joinNonEmptyValues(values: string[], separator: string) {
        return values
            .filter(value => (value || '').length > 0)
            .join(separator);
    }
}
