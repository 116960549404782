import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from 'client/angular/core';
import { BannerComponent } from './components/banner/banner.component';
import { BannerPreviewComponent } from './components/banner-preview/banner-preview.component';
import { BannerPlaceholderComponent } from './components/banner-placeholder/banner-placeholder.component';

@NgModule({
    declarations: [
        BannerComponent,
        BannerPreviewComponent,
        BannerPlaceholderComponent
    ],
    entryComponents: [
    ],
    imports: [
        CommonModule,
        CoreModule
    ],
    providers: [
    ]
})
export class SearchModule { }
