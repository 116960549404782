import {Component} from '@angular/core';
import { GoogleAnalyticsService } from 'client/angular/core';

@Component({
  selector: 'ee-reorder-products-section',
  templateUrl: './reorder-products-section.component.html',
  styleUrls: ['./reorder-products-section.component.scss']
})
export class ReorderProductsSectionComponent {
  constructor(private ga: GoogleAnalyticsService) {
  }

  gaTrackingReorderProduct(linkLocation: string){
      this.ga.sendEvent('MegaMenu', 'ReorderProduct', linkLocation, 1);
  }
}
