import { Component } from '@angular/core';

@Component({
    selector: 'ee-product-actions-unauthenticated',
    templateUrl: './product-actions-unauthenticated.component.html',
    styleUrls: ['./product-actions-unauthenticated.component.scss']
})
export class ProductActionsUnauthenticatedComponent {
    onLogin() {
        window.location.assign('/login');
    }
}