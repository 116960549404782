<div class="modal-header">
    <h4 class="modal-title">{{presentation.title}}</h4>
    <button
        type="button"
        class="close btn btn-outline-primary btn-outline-transparent-border"
        ee-google-tag="Click Cross Button"
        (click)="onDismiss()">X</button>
</div>
<div class="modal-body">
    <p>{{presentation.body}}</p>
    <div *ngIf="status == 'failed'" class="error-message">
        {{errorMessage}}
    </div>
</div>

<div class="modal-footer">
    <ee-button
        *ngIf="backorderItem.hasAlternatives"
        styling="outline"
        ee-google-tag="Click Cancel Item and View Alternatives"
        (clicked)="onDoBoth()"
        [disabled]="status === 'cancelling'"
        [waiting]="status == 'cancelling' && buttonClicked == 'secondary'">Cancel Item and View Alternatives</ee-button>

    <ee-button
        ngbautofocus
        styling="orange"
        [ee-google-tag]="'Click ' + presentation.primaryButtonText"
        (clicked)="onCancelOrView()"
        [disabled]="status === 'cancelling'"
        [waiting]="status === 'cancelling' && buttonClicked == 'primary'">{{presentation.primaryButtonText}}</ee-button>
</div>
