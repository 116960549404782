import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export abstract class ISupportKeyService {
    abstract getSupportKey(): Promise<string>;
}

@Injectable({ providedIn: 'root' })
export class SupportKeyService implements ISupportKeyService {
    private supportKey: string;

    constructor(private httpClient: HttpClient) { }

    public async getSupportKey(): Promise<string> {
        this.supportKey = this.supportKey || await this.getSupportKeyFromApi() || null;
        return this.supportKey;
    }

    private async getSupportKeyFromApi(): Promise<string> {
        try {
            const response = await this.httpClient.get<{ supportKey: string }>('/api/account/supportkey').toPromise();
            return response.supportKey;
        }
        catch (error) {
            return null;
        }
    }
}
