<ng-template #popTitle>Notifications ({{notificationListItems ? notificationListItems.length : '0'}})</ng-template>
 <ng-template #popContent>
    <div class="container">
    <ng-container  *ngIf="notificationListItems.length > 0">
        <div *ngFor="let item of notificationListItems let indexOfelement=index;">
         <div class="{{indexOfelement > 0 ? 'notification-item-pad-top' : ''}}">
            <emerson-notification-bell-list-item  [notificationItem]="item"></emerson-notification-bell-list-item>
         </div>
        </div>
    </ng-container>
    <ng-container *ngIf="notificationListItems.length == 0">
        <div style="text-align: center; font-weight:bold">You have no new notifications</div>
    </ng-container>
   </div>
 </ng-template>
 <span style="position: relative;">
   <button class="btn btn-outline-none notification-bell-button-position shadow-none"
           type="button"
           placement="bottom"
           popoverClass="notification-bell-popover"
           [ngbPopover]="popContent"
           [popoverTitle]="popTitle"
           #notifyPop="ngbPopover"
           container="body"
           (mouseover)="changeStyle($event)" (mouseout)="changeStyle($event)">
      <i [ngStyle]="notifyBellColorStyle"  class="fa fa-bell notification-bell-ee"></i>
   </button>
   <div  [ngStyle]="notifyTitleColorStyle" class="notification-button-title" (mouseover)="changeStyle($event)" (click)="notifyPop.open()" (mouseout)="changeStyle($event)">Notifications</div>
   <span *ngIf="notificationListItems && notificationListItems.length > 0" class="notification-bell-badge notification-bell-badge-danger" (click)="notifyPop.open()" (mouseover)="changeStyle($event)" (mouseout)="changeStyle($event)">{{notificationListItems.length}}</span>
</span>
