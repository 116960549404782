<div class="brands-container">
    <div class="brands-sub-header">Featured Brands</div>
    <div class="brands">
        <div class="brand-card" *ngFor="let brand of brands | slice: 0: 3;">
            <span class="center-helper"></span>
            <img alt="{{brand.brandName}}" class="brand-image" [src]="brand.brandLogoUrl">
            <ee-button class="brand-link"
                styling="green"
                (clicked)="searchBrandUrl(brand.brandName, brand.brandId)"
            >Shop</ee-button>
        </div>
    </div>
    <div class="brands-menu">
        <div><a href="/brands/directory/all" (click)="gaTrackingBrand('All')">ALL BRANDS</a></div>
        <div><a href="/brands/directory/a-g" (click)="gaTrackingBrand('A-G')">BRANDS A - G</a></div>
        <div><a href="/brands/directory/h-l" (click)="gaTrackingBrand('H-L')">BRANDS H - L</a></div>
        <div><a href="/brands/directory/m-r" (click)="gaTrackingBrand('M-R')">BRANDS M - R</a></div>
        <div><a href="/brands/directory/s-z" (click)="gaTrackingBrand('S-Z')">BRANDS S - Z</a></div>
    </div>
</div>

