import {Component, Input} from '@angular/core';
import {FeaturedProduct} from '../../../model/featured-product.model';
import {GoogleAnalyticsService} from '../../../../../core';

@Component({
    selector: 'ee-new-product-section',
    templateUrl: './new-product-section.component.html',
    styleUrls: ['./new-product-section.component.scss']
})
export class NewProductSectionComponent{
    @Input() newProducts: FeaturedProduct[];

    constructor(private ga: GoogleAnalyticsService) {
    }

    gaTrackingNewProduct(product: FeaturedProduct){
        this.ga.sendEvent('MegaMenu', 'FeaturedProduct', product.itemId, 1);
    }

}
