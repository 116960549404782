import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';

import { AddressPipeType, GoogleTagDirectiveHost } from 'client/angular/core';
import { exception } from 'console';

import { BackorderItemModel } from '../../../models';

@Component({
    selector: 'backorder-card',
    templateUrl: './backorder-card.component.html',
    styleUrls: ['./backorder-card.component.scss'],
    providers: [{ provide: GoogleTagDirectiveHost, useExisting: forwardRef(() => BackorderCardComponent) }]
})
export class BackorderCardComponent implements GoogleTagDirectiveHost {
    constructor() { }

    getGoogleTagData() {
        return {
            componentName: 'Back Order Page',
            textDetail: this.backorderItem.itemCode,
            numericDetail: this.backorderItem.productId
        };
    }

    dislpayImageFallback = false;

    @Input() backorderItem: BackorderItemModel;
    AddressPipeType = AddressPipeType;

    @Output() viewAlternates = new EventEmitter<BackorderItemModel>();
    @Output() cancelBackorder = new EventEmitter<BackorderItemModel>();
    @Output() redirectToPdp = new EventEmitter<BackorderItemModel>();

    isLessThanToday(expectedDeliveryDate: Date){
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (expectedDeliveryDate < today)
        {
            return true;
        }
        return false;
    }

    onViewAlternates() {
        this.viewAlternates.emit(this.backorderItem);
    }

    onCancelBackorder() {
        this.cancelBackorder.emit(this.backorderItem);
    }

    onRedirectToPdp() {
        this.redirectToPdp.emit(this.backorderItem);
    }

    onImageFailed() {
        this.dislpayImageFallback = true;
    }
}
