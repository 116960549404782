import { Component, forwardRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleTagDirectiveHost } from 'client/angular/core';

@Component({
    selector: 'ee-product-actions-chat-unavailable',
    templateUrl: './product-actions-chat-unavailable.component.html',
    styleUrls: ['./product-actions-chat-unavailable.component.scss'],
    providers: [{ provide: GoogleTagDirectiveHost, useExisting: forwardRef(() => ProductActionsChatUnavailableComponent)}]
})
export class ProductActionsChatUnavailableComponent implements GoogleTagDirectiveHost {
    constructor(private activeModal: NgbActiveModal) { }

    getGoogleTagData(): { componentName: string; textDetail: string; numericDetail: number; } {
        return {
            componentName: 'product-details-contact-us',
            textDetail: '',
            numericDetail: 0
        };
    }

    onDismiss() {
        this.activeModal.dismiss();
    }
}
