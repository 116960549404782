import { Component, OnInit, Input } from '@angular/core';

import { AddressService } from 'client/angular/core';
import { CartModel } from '../../models';

@Component({
    selector: 'ee-product-actions-free-shipping',
    templateUrl: './product-actions-free-shipping.component.html',
    styleUrls: ['./product-actions-free-shipping.component.scss']
})
export class ProductActionsFreeShippingComponent implements OnInit {
    constructor(private addressService: AddressService) { }

    private static SubtotalThreshold = 250;

    @Input() cart: CartModel;

    isVisible: boolean;

    ngOnInit() {
        this.isVisible = this.getIsVisible(this.cart);
    }

    getCostToAddForEligibility() {
        return Math.max(0, ProductActionsFreeShippingComponent.SubtotalThreshold - this.cart.discountedSubTotal);
    }

    private getIsVisible(cart: CartModel) {
        // by default we will assume that cart will belong to domestic shipment
        if (cart.shippingAddress == null)
            return true;

        return this.addressService.isDomesticAddress(cart.shippingAddress);
    }
}