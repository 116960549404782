import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { IContentService } from '@emersonecologics/emerson-angular-trove';
import { SpecialProductModel } from '../../../model/special-product.model';
import { GoogleTagDirectiveHost } from 'client/angular/core';

@Component({
  selector: 'ee-specials-section',
  templateUrl: './specials-section.component.html',
  styleUrls: ['./specials-section.component.scss'],
  providers: [{ provide: GoogleTagDirectiveHost, useExisting: forwardRef(() => SpecialsSectionComponent)}]
})
export class SpecialsSectionComponent implements OnInit, GoogleTagDirectiveHost {
  @Input() specialProducts: SpecialProductModel[];
  @Input() mobileMenu: false;

  constructor(private contentfulService: IContentService) {}

  getGoogleTagData() {
    return {
      componentName: 'shop-nav-specials-section',
      textDetail: '',
      numericDetail: 0
    };
  }


  async ngOnInit(): Promise<void> {
    try {
      const specials = await this.contentfulService.getEntriesByContentType('eeSpecial');
      this.specialProducts = this.parseData(specials);
    }
    catch (error) {
      console.log(error);
    }
  }

  parseData(data): any {
    const parsedData = [];
    const itemData = data.items;
    itemData.forEach( (value) => {
      const dataRecord = {} as SpecialProductModel;
      dataRecord.offerText = value.fields.offerText;
      dataRecord.offerCouponCode = value.fields.offerCouponCode;
      dataRecord.offerImage = value.fields.offerImage.fields.file.url;
      dataRecord.sortOrder = value.fields.sortOrder;
      dataRecord.brandId = value.fields.brandId;
      parsedData.push(dataRecord);
    });
    return parsedData;
  }

  clickHandler(brandId): void {
    window.open(
      'https://go.emersonecologics.com/specials?brand=' + brandId, '_blank');
  }

}
