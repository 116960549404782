import { Component, forwardRef, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { GoogleTagDirectiveHost } from 'client/angular/core';

import { ItemModel } from '../../models';
import { ProductDetailsService } from '../../services/product-details/product-details.service';

@Component({
    selector: 'ee-product-actions-back-in-stock-dialog',
    templateUrl: './product-actions-back-in-stock-dialog.component.html',
    styleUrls: ['./product-actions-back-in-stock-dialog.component.scss'],
    providers: [{ provide: GoogleTagDirectiveHost, useExisting: forwardRef(() => ProductActionsBackInStockDialogComponent)}]
})
export class ProductActionsBackInStockDialogComponent implements GoogleTagDirectiveHost {

    constructor(private activeModal: NgbActiveModal, private service: ProductDetailsService) { }

    getGoogleTagData() {
        return {
            componentName: 'Customer - Notify Me Dialog',
            textDetail: this.item.itemCode,
            numericDetail: 0
        };
    }

    @Input() item: ItemModel;
    @Input() outOfStockMessage: string;
    @Input() similarProductsExist: boolean;

    hasSuccess = false;
    hasError = false;
    pending = false;

    onSubscribe() {
        this.hasError = false;
        this.hasSuccess = false;
        this.pending = true;

        this.service.subscribeToBackInStock(this.item.itemCode).subscribe(() => {
            this.pending = false;
            this.hasSuccess = true;
        }, () => {
            this.pending = false;
            this.hasError = true;
        });
    }

    onDismiss() {
        this.closeDialog(false);
    }

    onCloseDialog() {
        this.closeDialog(false);
    }

    onViewSimilar() {
        this.closeDialog(true);
    }

    private closeDialog(requestScrollToSimilarProducts: boolean) {
        this.activeModal.close({
            scrollToSimilarProducts: requestScrollToSimilarProducts,
            subscribed: this.hasSuccess
        });
    }
}
