import { Component, Input } from '@angular/core';
import { AccountModel } from 'client/angular/core/models';

import { ItemModel } from '../../models';

@Component({
    selector: 'ee-product-accordion',
    templateUrl: './product-accordion.component.html',
    styleUrls: ['./product-accordion.component.scss']
})
export class ProductAccordionComponent {
    constructor() { }

    @Input() user: AccountModel;
    @Input() item: ItemModel;

    hasDietaryConsiderations() {
        return (((this.item || {}).productDetails || {}).dietaryConsiderations || []).length > 0;
    }

    hasSuggestedUse() {
        return (((this.item || {}).productDetails || {}).suggestedUse || '').length > 0;
    }

    hasWarnings() {
        return this.hasWarningHtml() || this.isHeatSensitive() || this.shortShelfLife();
    }

    hasWarningHtml() {
        return (((this.item || {}).productDetails || {}).warnings || '').length > 0;
    }

    isHeatSensitive() {
        return ((this.item || {}).productDetails || {}).isHeatSensitive;
    }

    shortShelfLife() {
        return ((this.item || {}).defaultInventory || {}).shortShelfLife;
    }

    isPractitioner() {
        return (this.user || {}).accountType === 'Practitioner';
    }
}
