import { Component, Directive, EventEmitter, HostBinding, HostListener,
         Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { BrandAgreementsService} from '../../../services/brand-agreements.service';
import { Agreement, AgreementSigningProvider, AgreementStatus} from '../../../models';
import { BrandAgreementPopupComponent } from '../brand-agreement-popup/brand-agreement-popup.component';
import { AccountModel, ServiceClientErrorModel } from '../../../../../core/models';
import { AccountService } from 'client/angular/core';

export type SortColumn = string | number;
export type SortDirection = 'asc' | 'desc' | '';

export interface SortEvent {
    column: SortColumn;
    direction: SortDirection;
}

@Directive({
    selector: '[ee-sortable]'
})
export class SortableHeaderItemDirective {

    @HostBinding('class.asc') get isAscending() {
        return this.direction === 'asc';
    }

    @HostBinding('class.desc') get isDescending() {
        return this.direction === 'desc';
    }

    @HostListener('click') rotate() {
        this.direction = SortableHeaderItemDirective.rotateMap[this.direction];
        this.sort.emit({column: this.sortable, direction: this.direction});
    }

    @Input('ee-sortable') sortable: SortColumn = '';
    @Input() direction: SortDirection = '';
    @Output() sort = new EventEmitter<SortEvent>();

    private static rotateMap: {[key: string]: SortDirection} = { asc: 'desc', desc: 'asc', '': 'asc' };
}

@Component({
    selector: 'ee-brand-agreement-list',
    templateUrl: './brand-agreement-list.component.html',
    styleUrls: ['./brand-agreement-list.component.scss']
})
export class BrandAgreementListComponent implements OnInit{
    accountInfo: AccountModel;

    constructor(private service: BrandAgreementsService,
                private modalService: NgbModal,
                private accountService: AccountService) {
    }

    brandAgreements: Agreement[];
    email: string;
    errorMessage: string;

    ngOnInit(): void {
        this.service.getBrandAgreementList().subscribe(
            data => this.brandAgreements = data,
            error => this.handleError(error)
        );
        this.service.getUserEmail().subscribe(
            res => this.email = res.email
        );
        this.accountService.getCachedAccountInfo().then(accountInfo => {
            this.accountInfo = accountInfo;
        });
    }

    handleError(error: ServiceClientErrorModel){
        this.errorMessage = error.message;
        setTimeout(() => this.errorMessage = '', 5000);
    }

    isAgreementSigned(agreement: Agreement) {
        return agreement.signature?.status === AgreementStatus.Signed;
    }

    docusignPending(agreement: Agreement) {
        return agreement.signingProvider === AgreementSigningProvider.Docusign
               && agreement.signature != null
               && (agreement.signature.status === AgreementStatus.CustomerSigning ||
                   agreement.signature.status === AgreementStatus.CustomerSigned);
    }

    docusignPendingMessage(agreement: Agreement) {
        switch (agreement.signature?.status) {
            case AgreementStatus.CustomerSigning:
                return 'Awaiting for customer`s signature.';
            case AgreementStatus.CustomerSigned:
                return 'Awaiting for customer support team for approval.';
        }
    }

    @ViewChildren(SortableHeaderItemDirective) headers: QueryList<SortableHeaderItemDirective>;

    onSort({column, direction}: SortEvent) {

        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        const comparator = BrandAgreementListComponent.getComparator(column);
        this.brandAgreements.sort((a, b) => {
            return direction === 'asc' ? comparator(a, b) : -comparator(a, b);
        });
    }

    showBrandModal(brandAgreement: Agreement) {
        const modalRef = this.modalService.open(BrandAgreementPopupComponent, {centered: true, keyboard: true, size: 'xl'});
        modalRef.componentInstance.brandAgreement = brandAgreement;
        modalRef.componentInstance.email = this.email;
        modalRef.componentInstance.accountInfo = this.accountInfo;
    }

    private static getComparator(column: SortColumn) {
        switch (column) {
            case 'agreedDate': return BrandAgreementListComponent.compareDate;
            case 'signed': return BrandAgreementListComponent.compareSignature;
            default: return BrandAgreementListComponent.compareTitle;
        }
    }

    private static compareDate(a: Agreement, b: Agreement) {
        const v1 = a.signature?.status === AgreementStatus.Signed ? new Date(a.signature.recentActionDate).getTime() : 0;
        const v2 = b.signature?.status === AgreementStatus.Signed ? new Date(b.signature.recentActionDate).getTime() : 0;
        const comparison = BrandAgreementListComponent.compareValue(v1, v2);
        return BrandAgreementListComponent.thenCompare(comparison, a, b);
    }

    private static compareSignature(a: Agreement, b: Agreement) {
        const v1 = a.signature?.status === AgreementStatus.Signed ? 0 : a.signature?.status != null ? 1 : 2;
        const v2 = b.signature?.status === AgreementStatus.Signed ? 0 : b.signature?.status != null ? 1 : 2;
        const comparison = BrandAgreementListComponent.compareValue(v1, v2);
        return BrandAgreementListComponent.thenCompare(comparison, a, b);
    }

    private static compareTitle(a: Agreement, b: Agreement) {
        return BrandAgreementListComponent.compareValue(a.title, b.title);
    }

    private static thenCompare(comparison: number, a: Agreement, b: Agreement) {
        return comparison === 0 ? BrandAgreementListComponent.compareTitle(a, b) : comparison;
    }

    private static compareValue(a: string | number, b: string | number) {
        return a < b ? -1 : a > b ? 1 : 0;
    }
}
