<ee-button
    *ngIf="!pendingSubscriptionCheck && (!subscribed || hasSubscriptionCheckError)"
    styling="outline"
    data-qa-selector="subscribe"
    ee-google-tag="PDP Subscribe"
    (clicked)="onSubscribe()">Email me when available</ee-button>

<ee-button
    *ngIf="!pendingSubscriptionCheck && !hasSubscriptionCheckError && subscribed"
    styling="outline"
    data-qa-selector="unsubscribe"
    ee-google-tag="PDP Unsubscribe"
    [waiting]="unsubscribing"
    (clicked)="onUnsubscribe()">Unsubscribe</ee-button>