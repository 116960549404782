import { Component, OnInit, Input } from '@angular/core';
import { ItemModel, IngredientModel } from '../../models';

@Component({
    selector: 'ee-item-ingredients',
    templateUrl: './item-ingredients.component.html',
    styleUrls: ['./item-ingredients.component.scss']
})
export class ItemIngredientsComponent implements OnInit {
    constructor() { }

    @Input() item: ItemModel;

    activeIngredients: IngredientModel[];
    otherIngredients: string;

    getIngredientAmount(ingredient: IngredientModel) {
        const abbreviation = ingredient.unitOfMeasureAbbreviation ? ' ' + ingredient.unitOfMeasureAbbreviation : '';
        const ingredientAmount = this.determineIngredientAmountFormat(ingredient);
        return ingredientAmount + abbreviation;
    }

    hasDailyValue() {
        const ingredients = this.activeIngredients;
        return ingredients.find(ing => ing.percentageDailyValue > 0) != null;
    }

    ngOnInit() {
        this.activeIngredients = this.getActiveIngredients();
        this.otherIngredients = this.getOtherIngredients();
    }

    private getActiveIngredients() {
        const ingredients = ((this.item || {}).productDetails || {}).ingredients || [];
        return ingredients.filter(ing => ing.isActive);
    }

    private getOtherIngredients() {
        const ingredients = ((this.item || {}).productDetails || {}).ingredients || [];
        const inactiveIngredients = ingredients.filter(ing => !ing.isActive).map(ing => ing.supplierName);
        return inactiveIngredients.join(', ');
    }

    private determineIngredientAmountFormat(ingredient: IngredientModel) {
        if (ingredient.amount) {
            return ingredient.amount.toString();
        } else if (ingredient.unitOfMeasureAbbreviation) {
            return '0';
        } else {
            return '';
        }
    }
}
