<div class="mega-menu-container" [ngClass]="{'empty-column': !activeSection, 'two-column': !hasWideColumns}" *ngIf="showMegaMenu">
    <ul class="mega-menu-dropdown" [ngClass]="{'two-column': !hasWideColumns, 'three-column': hasWideColumns}">
        <li class="mega-menu-section" *ngFor="let category of categories; index as i">
            <div class="mega-menu-first-level">
                <a class="first-level-title" (mouseenter)="setActiveCategory(category)">
                    {{category.category}}
                    <span class="arrow-icon"></span>
                </a>
            </div>
            <div class="mega-menu-second-level" *ngIf="activeSection == category.category" [style.left.%]="category.subCategory.length > 18 ? 33 : 50" [style.top.%]="-100 * i" [style.height.px]="menuHeight">
                <div class="mega-menu-sub-column" [style.height.px]="menuHeight">
                    <div class="sub-category-container" [style.margin-top.px]="menuHeight / 20" [style.height.px]="menuHeight * 0.9" [ngClass]="{'vertical-split': category.subCategory.length > 18}">
                        <ul class="sub-category-list">
                            <li class="mega-menu-list-item" *ngFor="let subCategory of category.subCategory | slice:0:18;">
                                <a class="second-level-link" (click)="gaTrackingMegaMenu(category.category, subCategory.subCategory)" href="./shop/{{urlStrip(subCategory.subCategory)}}-S{{subCategory.taxonomyId}}">{{subCategory.subCategory}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="mega-menu-sub-column" *ngIf="category.subCategory.length > 18">
                    <div class="sub-category-container" [style.margin-top.px]="menuHeight / 20" [style.height.px]="menuHeight * 0.9">
                        <ul class="sub-category-list">
                            <li class="mega-menu-list-item" *ngFor="let subCategory of category.subCategory | slice:18:36;">
                                <a class="second-level-link" (click)="gaTrackingMegaMenu(category.category, subCategory.subCategory)" href="./shop/{{urlStrip(subCategory.subCategory)}}-S{{subCategory.taxonomyId}}">{{subCategory.subCategory}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </li>

        <li class="mega-menu-section">
            <div class="mega-menu-first-level">
                <a class="first-level-title" (mouseenter)="setActiveSection('brand')">
                    Brands
                    <span class="arrow-icon"></span>
                </a>
            </div>
            <div class="mega-menu-second-level general-narrow-section" *ngIf="activeSection == 'brand'" [style.top.%]="-100 * (categories.length)" [style.height.px]="menuHeight">
                <div class="mega-menu-narrow-sub-container">
                    <ee-brand-section></ee-brand-section>
                </div>
            </div>
        </li>

        <li class="mega-menu-section">
            <div class="mega-menu-first-level">
                <a class="first-level-title" (mouseenter)="setActiveSection('newProducts')">
                    New Products
                    <span class="arrow-icon"></span>
                </a>
            </div>
            <div class="mega-menu-second-level general-section" *ngIf="activeSection == 'newProducts'" [style.top.%]="-100 * (categories.length + 1)" [style.height.px]="menuHeight">
                <div class="mega-menu-wide-sub-container">
                    <ee-new-product-section [newProducts]="newProducts"></ee-new-product-section>
                </div>
            </div>
        </li>

        <li *ngIf="accountInfo" class="mega-menu-section">
            <div class="mega-menu-first-level reorder-theme">
                <a class="first-level-title reorder-theme"
                   target="_blank"
                   href="/account/orders/reorder-products"
                   (click)="gaTrackingMegaMenu('ReOrderProduct', 'sectionLink')"
                   (mouseenter)="setActiveSection('reorderProducts')">
                    Reorder Products
                    <span class="arrow-icon reorder-theme"></span>
                </a>
            </div>
            <div class="mega-menu-second-level general-narrow-section" *ngIf="activeSection == 'reorderProducts'" [style.top.%]="-100 * (categories.length + 4)" [style.height.px]="menuHeight">
                <div class="mega-menu-narrow-sub-container full-height">
                    <ee-reorder-products-section></ee-reorder-products-section>
                </div>
            </div>
        </li>

        <li class="mega-menu-section">
            <div class="mega-menu-first-level">
                <a class="first-level-title" (mouseenter)="setActiveSection('specials')">
                    Specials
                    <span class="arrow-icon"></span>
                </a>
            </div>
            <div class="mega-menu-second-level general-section" *ngIf="activeSection == 'specials'" [style.top.%]="-100 * (categories.length + 2)" [style.height.px]="menuHeight">
                <div class="mega-menu-wide-sub-container">
                    <ee-specials-section></ee-specials-section>
                </div>
            </div>
        </li>

        <li class="mega-menu-section">
            <div class="mega-menu-first-level">
                <a class="first-level-title" (mouseenter)="setActiveSection('learningResources')">
                    Learning Resources
                    <span class="arrow-icon"></span>
                </a>
            </div>
            <div class="mega-menu-second-level general-narrow-section" *ngIf="activeSection == 'learningResources'" [style.top.%]="-100 * (categories.length + 3)" [style.height.px]="menuHeight">
                <div class="mega-menu-narrow-sub-container full-height">
                    <ee-learning-resource-section></ee-learning-resource-section>
                </div>
            </div>
        </li>


    </ul>
</div>


