import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductActionsChatUnavailableComponent } from '../../components/product-actions-chat-unavailable/product-actions-chat-unavailable.component';

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    constructor(private modalService: NgbModal) { }

    hourStart = 8;
    minuteStart = 30;
    hourEnd = 20;
    minuteEnd = 0;

    isBusinessHours() {
        const estNow = new Date(new Date().toLocaleString('en-US', {timeZone: 'America/New_York'}));

        return this.isBusinessHoursByDate(estNow);
    }

    isBusinessHoursByDate(estNow: Date) {
        // don`t work during weekends
        const dayOfWeek = estNow.getDay();
        if (dayOfWeek === 0 || dayOfWeek === 6)
            return false;

        const hours = estNow.getHours();
        const minutes = estNow.getMinutes();
        const minutesSinceMidnight = hours * 60 + minutes;

        // too early in the morning
        if (minutesSinceMidnight < this.hourStart * 60 + this.minuteStart )
            return false;

        // too late in the evening
        if (minutesSinceMidnight > this.hourEnd * 60 + this.minuteEnd )
            return false;

        return true;
    }

    openChatWindow() {
        try { (window as any).$zopim.livechat.window.show(); } catch (e) { }
    }

    showChatUnavailableDialog(): { destroy: () => void } {
        this.modalService.open(ProductActionsChatUnavailableComponent, { centered: true, windowClass: 'squared-dialog-style' });
        return { destroy: () => this.modalService.dismissAll() };
    }
}