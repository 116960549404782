import { Injectable } from '@angular/core';
import { Observable, from, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { ServiceClientService, ProductAlternativesService } from 'client/angular/core';
import { BackorderItemModel, NotShippedItemModel } from '../../../../models';

export interface IITemDetails {
    itemCode: string;
    itemName: string;
    thumbnailUrl: string;
    productId: number;
}

export interface IInventoryDetails {
    itemCode: string;
    stock: number;
    warehouse: number;
    expectedDeliverDate: Date;
}

@Injectable({
    providedIn: 'root'
})
export class BackorderDataService {
    constructor(private client: ServiceClientService, private alternatives: ProductAlternativesService) { }

    getBackorderItems(): Observable<BackorderItemModel[]> {
        return this.client.getAsync<NotShippedItemModel[]>(
            'order', '/GetNotShippedItems'
            ).pipe(
                mergeMap(notShippedItems => {
                    const itemCodes = [...new Set(notShippedItems.map(item => item.itemCode))];

                    return forkJoin({
                        details: this.getItemDetailsMap(itemCodes),
                        inventories: this.getInventoriesMap(itemCodes)
                    }).pipe(
                        mergeMap(vars => {
                            const { details, inventories } = vars;
                            const productIds = [...new Set(Object.values(details).map(detail => detail.productId))];
                            return this.alternatives.getProductIdsWithAlternatives(productIds).pipe(
                                map((productIdsWithAlternateives) => {
                                    return this.mapToBackorderItems(notShippedItems, details, inventories, productIdsWithAlternateives);
                                })
                            );
                        })
                    );
                })
            );
    }

    cancelBackorderItem(item: BackorderItemModel): Observable<boolean> {
        return this.client.putAsync<boolean>('order', `/CancelBackOrderItem/${item.orderId}/${item.itemCode}`, null);
    }

    private getItemDetailsMap(itemCodes: string[]): Observable< { [key: string]: IITemDetails } > {
        if (itemCodes.length === 0) return from([{}]);

        return this.client.postAsync<IITemDetails[]>(
            'ares-api', '/products/backorder-items-info', itemCodes
            ).pipe(
                map(items => {
                    return items.reduce((obj, item) => {
                        obj[item.itemCode] = item;
                        return obj;
                    }, {} as { [key: string]: IITemDetails });
                })
            );
    }

    private getInventoriesMap(itemCodes: string[]): Observable< { [key: string]: IInventoryDetails } > {
        if (itemCodes.length === 0) return from([{}]);

        return this.client.postAsync<IInventoryDetails[]>(
            'inventory', '/GetInventory', { itemCodes }
            ).pipe(
                map(items => {
                    return items.reduce((obj, item) => {
                        obj[`${item.itemCode}_${item.warehouse}`] = item;
                        return obj;
                    }, {} as { [key: string]: IInventoryDetails });
                })
            );
    }

    private mapToBackorderItems(
        items: NotShippedItemModel[],
        details: { [key: string]: IITemDetails; },
        inventories: { [key: string]: IInventoryDetails; },
        productIdsWithAlternatives: Set<number>
    ): BackorderItemModel[] {

        const result = items.map(item => {
            const detail = details[item.itemCode];
            const inventory = inventories[`${item.itemCode}_${item.warehouse}`];

            // note: if item is in stock, then we should hide it from backorders list
            if (inventory == null || inventory.stock > 0 || detail == null) return null;

            const hasAlternates = productIdsWithAlternatives.has(detail.productId);

            const backorderItem = item as BackorderItemModel;
            backorderItem.thumbnailUrl = detail.thumbnailUrl;
            backorderItem.itemName = detail.itemName;
            backorderItem.productId = detail.productId;
            backorderItem.expectedDeliverDate = inventory.expectedDeliverDate;
            backorderItem.hasAlternatives = hasAlternates;

            // note: interesting issue to solve - how to "serialize" dates by just using the type of expected element
            backorderItem.expectedDeliverDate = this.parseStringAsDate(backorderItem.expectedDeliverDate as any as string);
            backorderItem.orderDate = this.parseStringAsDate(backorderItem.orderDate as any as string);

            return backorderItem;
        }).filter(item => item != null);

        return result;
    }

    private parseStringAsDate(date: string): Date {
        return date == null ? null : new Date(date);
    }
}
