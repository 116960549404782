<div class="reorder-products-container">
    <a (click)="gaTrackingReorderProduct('imageLink')" target="_blank" href="/account/orders/reorder-products">
        <img alt="reorder flyout" class="reorder-products-image" src="./assets/images/mega-menu/reorder-products/reorder.png">
    </a>    
    <h3 class="reorder-header-text">
        <a (click)="gaTrackingReorderProduct('headerLink')" target="_blank" href="/account/orders/reorder-products">Reordering Made Easy</a>
    </h3>
    <p class="reorder-sub-header">
        Reorder products, review order history, and quick shop with product codes, 
        <a (click)="gaTrackingReorderProduct('textLink')" class="reorder-sub-link" target="_blank" href="/account/orders/reorder-products">all from one convenient location</a>.
    </p>
</div>