import { Component, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'ee-expandable-panel',
    templateUrl: './expandable-panel.component.html',
    styleUrls: ['./expandable-panel.component.scss']
})
export class ExpandablePanelComponent {

    constructor() { }

    @Input() panelTitle: string;
    @Input() hideBordersWhenPrinted = false;

    @HostBinding('class.expanded')
    expanded = false;

    @HostBinding('class.no-borders')
    get hideBorders() { return this.hideBordersWhenPrinted; }

    toggleExpanded() {
        this.expanded = !this.expanded;
    }
}
