<div class="backorder-card-header layout-row">

    <div class="flex-50 flex-gt-sm-25">
        <div>Order Date</div>
        <div class="value">{{backorderItem.orderDate | date : 'MM/dd/y'}}</div>
    </div>

    <div class="flex-50 flex-gt-sm-25">
        <div>Est. Ship Date</div>
        <div *ngIf="backorderItem.expectedDeliverDate != null && !isLessThanToday(backorderItem.expectedDeliverDate)" class="value">{{backorderItem.expectedDeliverDate | date : 'MM/dd/y'}}</div>
        <div *ngIf="backorderItem.expectedDeliverDate == null || isLessThanToday(backorderItem.expectedDeliverDate)" class="value">Unknown</div>
    </div>

</div>

<div class="backorder-card-body layout-row layout-wrap">

    <div class="flex-50 flex-gt-sm-25">
        <div class="image-holder" (click)="onRedirectToPdp()">
            <img *ngIf="!dislpayImageFallback" alt="{{backorderItem.itemCode}}" [src]="backorderItem.thumbnailUrl" (error)="onImageFailed()" />
            <img *ngIf="dislpayImageFallback" alt="image unavailable" src="../../../../../../assets/images/icons/Image_Not_Available.png" />
        </div>
    </div>

    <div class="flex-50 flex-gt-sm-25">
        <div>Item</div>
        <div class="value text-link" (click)="onRedirectToPdp()">{{backorderItem.itemName}}</div>
    </div>

    <div class="flex-50 flex-gt-sm-25 item-code">
        <div>Item Code</div>
        <div class="value">{{backorderItem.itemCode}}</div>
    </div>

    <div class="flex-50 flex-gt-sm-25">
        <div [ngbTooltip]="!backorderItem.hasAlternatives ? 'No alternatives available for this product' : ''">
            <ee-button
                styling="orange"
                ee-google-tag="Click View Similar Products on Backorder Management"
                [disabled]="!backorderItem.hasAlternatives"
                (clicked)="onViewAlternates()">View Alternatives</ee-button>
        </div>
        <div class="cancel-holder">
            <ee-button
                styling="link"
                ee-google-tag="Click Cancel Backorder Button"
                (clicked)="onCancelBackorder()">Cancel Back Order</ee-button>
        </div>
    </div>

</div>

<div class="backorder-card-footer layout-row layout-wrap">
    <div class="flex-50 flex-gt-sm-25">
        <div>Quantity Ordered</div>
        <div class="value">{{backorderItem.quantityOrdered | number}}</div>
    </div>

    <div class="flex-50 flex-gt-sm-25">
        <div>Quantity on Back Order</div>
        <div class="value">{{backorderItem.quantityOrdered - backorderItem.quantityShipped | number}}</div>
    </div>

    <div class="flex-50 flex-gt-sm-25">
        <div>Shipping Address</div>
        <div class="value">{{backorderItem.shipToAddress | address:AddressPipeType.Street}},</div>
        <div class="value">{{backorderItem.shipToAddress | address:AddressPipeType.City}}</div>
    </div>

    <div class="flex-50 flex-gt-sm-25">
        <div>Order Number</div>
        <div class="value">{{backorderItem.fulfillmentId}}</div>
    </div>
</div>