import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ProductCarouselComponent } from '../../components/product-carousel/product-carousel.component';
import { ImageModel } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class ProductCarouselDialogService {
    constructor(private modalService: NgbModal) {}

     openDialog(images: ImageModel[], activeImage: ImageModel): Promise<{}> {
        const modalRef = this.modalService.open(ProductCarouselComponent, { centered: true, size: 'xl' });
        const component = modalRef.componentInstance as ProductCarouselComponent;
        component.images = images;
        component.activeImage = activeImage;

        return modalRef.result.then(
            () => ({}),
            () => ({})
       );
    }
}
