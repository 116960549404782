import * as angular from 'angular';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import {
  CartChangesService,
  ProductAlternativesService,
  GoogleAnalyticsService,
  WarehouseService,
  NewRelicService,
  ConfigurationService,
  SeoMetaService
} from './angular/core/services';

import { BackorderListComponent } from './angular/modules/order-history/pages/backorder-page/backorder-list/backorder-list.component';
import { InvoicePageComponent } from './angular/modules/order-history/pages/invoice-page/invoice-page.component';
import { ToastsComponent } from './angular/core/components';
import { BannerPreviewComponent } from './angular/modules/search/components/banner-preview/banner-preview.component';
import { BannerPlaceholderComponent } from './angular/modules/search/components/banner-placeholder/banner-placeholder.component';

import { BrandAgreementListComponent } from './angular/modules/account-info/brand-agreements/components/brand-agreement-list/brand-agreement-list.component';
import { GoogleAnalyticsAndTagManagerComponent } from './angular/core/components/google-analytics-and-tag-manager/google-analytics-and-tag-manager.component';
import { GoogleRecaptchaComponent } from './angular/core/components/google-recaptcha/google-recaptcha.component';
import { ProductImagesComponent } from './angular/modules/product-details/components/product-images/product-images.component';
import { ProductActionsComponent } from './angular/modules/product-details/components/product-actions/product-actions.component';
import { ProductAccordionComponent } from './angular/modules/product-details/components/product-accordion/product-accordion.component';
import { ProductDescriptionComponent } from './angular/modules/product-details/components/product-description/product-description.component';
import { ItemIngredientsComponent } from './angular/modules/product-details/components/item-ingredients/item-ingredients.component';
import { MegaMenuComponent } from './angular/modules/mega-menu/component/mega-menu/mega-menu.component';
import { NotificationBellComponent } from '../client/angular/core/components/notification-bell/notification-bell.component';
import { NotificationBellService } from '../client/angular/core/components/notification-bell/services/notification-bell.service';
import { MobileAccordionMenuComponent } from './angular/modules/mega-menu/component/mobile-accordion-menu/mobile-accordion-menu.component';
import { FeatureService, ConfigurationService as TroveConfigurationService, ValidateaddressService, ValidateAddressComponent } from '@emersonecologics/emerson-angular-trove';
import { AppTabLinkComponent } from './angular/core/components/app-tab-link/app-tab-link.component';
import { SsoModalComponent } from './angular/modules/sso/components/sso-modal/sso-modal.component';

export function AngularDowngradeTo(existingAngularJsModuleName: string) {

    const directives = [
        { name: 'invoicePage', type: InvoicePageComponent },
        { name: 'backorderList', type: BackorderListComponent },
        { name: 'eeToasts', type: ToastsComponent },
        { name: 'googleAnalyticsAndTagManager', type: GoogleAnalyticsAndTagManagerComponent },
        { name: 'googleRecaptcha', type: GoogleRecaptchaComponent },
        { name: 'eeBannerPreview', type: BannerPreviewComponent },
        { name: 'eeBannerPlaceholder', type: BannerPlaceholderComponent },
        { name: 'eeProductImages', type: ProductImagesComponent },
        { name: 'eeProductActions', type: ProductActionsComponent },
        { name: 'eeProductAccordion', type: ProductAccordionComponent },
        { name: 'eeProductDescription', type: ProductDescriptionComponent},
        { name: 'eeBrandAgreementList', type: BrandAgreementListComponent},
        { name: 'eeItemIngredients', type: ItemIngredientsComponent },
        { name: 'eeMegaMenu', type: MegaMenuComponent },
        { name: 'emersonNotificationBell', type: NotificationBellComponent},
        { name: 'eeMobileAccordionMenu', type: MobileAccordionMenuComponent},
        { name: 'eeSsoModal', type: SsoModalComponent},
        { name: 'emersonAppTabLink', type: AppTabLinkComponent},
        { name: 'emersonValidateAddress', type: ValidateAddressComponent}
    ];

    const module = angular.module(existingAngularJsModuleName);

    module
        .service('cartChanges', downgradeInjectable(CartChangesService) as any)
        .service('productAlternatives', downgradeInjectable(ProductAlternativesService) as any)
        .service('googleAnalytic', downgradeInjectable(GoogleAnalyticsService) as any)
        .service('warehouseService', downgradeInjectable(WarehouseService) as any)
        .service('notifyBellService', downgradeInjectable(NotificationBellService) as any)
        .service('TroveConfigurationService', downgradeInjectable(TroveConfigurationService) as any)
        .service('configurationService', downgradeInjectable(ConfigurationService) as any)
        .service('unleashFeatureService', downgradeInjectable(FeatureService) as any)
        .service('troveValidateaddressService', downgradeInjectable(ValidateaddressService) as any)
        .service('newRelicService', downgradeInjectable(NewRelicService) as any)
        .service('seoMetaService', downgradeInjectable(SeoMetaService) as any)
        .run(['configurationService', 'unleashFeatureService',
          (configurationService: ConfigurationService, unleashFeatureService: FeatureService) => {
          const config = configurationService.getEnvironmentConfiguration();
          unleashFeatureService.connectUnleash(config.unleashConfig);
        }])
        .run(['CartService', 'cartChanges', (CartService, cartChanges: CartChangesService) => {

            CartService.subscribeToSelectedCartChange({$on: () => null}, (id: any) => {
                cartChanges.raiseCartChangeEvent(id != null ? parseInt(id, 10) : null);
            });

            cartChanges.initialize(() => {
                const cartId = CartService.getCartId();
                return cartId != null ? parseInt(cartId, 10) : null;
            }, cartSummary => {
                CartService.setNewCartSummary(cartSummary);
            });
        }]);

    directives.forEach(directive =>
        module.directive(
            directive.name,
            downgradeComponent({ component: directive.type }) as angular.IDirectiveFactory));
}
