<div class="component-container large-view" (click)="clicked.emit()">
  <div class="tab-link-content">
    <span class="icon">
      <svg _ngcontent-kwi-c149="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           width="23px" height="20px" viewBox="0 0 50 50" version="1.1">
        <title _ngcontent-kwi-c149="">Emerson Ecologics Logo</title>
        <g _ngcontent-kwi-c149="" id="Final" stroke="none"
           stroke-width="1" fill="none" fill-rule="evenodd">
          <g _ngcontent-kwi-c149="" id="WV-Logged-Out-Tab-Final" transform="translate(-845.000000, -10.000000)">
            <rect _ngcontent-kwi-c149="" x="0" y="0" width="1466" height="858"></rect>
            <g _ngcontent-kwi-c149="" id="tab" transform="translate(800.000000, 0.000000)">
              <polygon _ngcontent-kwi-c149="" id="Rectangle"
                       transform="translate(333.000000, 20.000000) scale(-1, 1) translate(-333.000000, -20.000000) "
                       points="0 0 666 0 636.783743 40 0 40"></polygon>
              <g _ngcontent-kwi-c149="" id="Group" transform="translate(45.000000, 10.000000)" fill="#FFFFFF" fill-rule="nonzero">
                <g id="_x30_6" transform="translate(-402.000000, -371.000000)">
                  <g id="Page-1" transform="translate(402.000000, 371.000000)">
                    <path id="Fill-3" class="st0" d="M45.7,17c0,0,0,0.1,0,0.1c0,0.6-0.9,14.2-17.9,20.3c0,0,0,0,0,0v-8.4l0,0l10.2-11
                      c0.9-1,0.9-2.5-0.1-3.5c-1-0.9-2.6-0.9-3.5,0.1l-6.6,7.1l0,0v-7.3c0,0,0,0,0-0.1c0.8-1.8,4.7-9.1,14.4-9.1c0.1,0,0.2,0,0.3,0
                      C46.3,9.9,45.7,16.9,45.7,17z M22.9,21.9L22.9,21.9l-6.6-7.1c-0.9-1-2.5-1.1-3.5-0.1c-1,0.9-1.1,2.5-0.1,3.5l10.2,11l0,0v8.4
                      c0,0,0,0,0,0C5.9,31.3,5,17.7,5,17.1c0,0,0-0.1,0-0.1c0-0.1-0.6-7.1,3.2-11.6c0.1,0,0.2,0,0.3,0c9.7,0,13.6,7.3,14.4,9.2
                      c0,0,0,0,0,0V21.9z M45.4,1.2c-0.4-0.4-1-0.7-1.6-0.7C34.5-0.1,28.5,4.6,25.3,9c-3.2-4.4-9.2-9-18.5-8.5c-0.6,0-1.2,0.3-1.6,0.7
                      c-6,5.9-5.3,15.3-5.2,16.2c0,0.7,0.4,5.3,3.5,10.5c2.9,5.1,8.9,11.8,21,15.2c0.1,0,0.1,0,0.2,0.1h0c0,0,0.1,0,0.1,0
                      c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0l0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c22.3-6.3,24.3-24,24.4-25.6
                      C50.6,16.9,51.5,7.3,45.4,1.2z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
    <div class="message">
      <span>
        {{message}}
      </span>
    </div>
  </div>
</div>

<div class="component-container small-view" (click)="shortClicked.emit()">
  <div class="tab-link-content">
    <span class="icon">
      <svg _ngcontent-kwi-c149="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           width="23px" height="20px" viewBox="0 0 50 50" version="1.1">
        <title _ngcontent-kwi-c149="">Emerson Ecologics Logo</title>
        <g _ngcontent-kwi-c149="" id="Final" stroke="none"
           stroke-width="1" fill="none" fill-rule="evenodd">
          <g _ngcontent-kwi-c149="" id="WV-Logged-Out-Tab-Final" transform="translate(-845.000000, -10.000000)">
            <rect _ngcontent-kwi-c149="" x="0" y="0" width="1466" height="858"></rect>
            <g _ngcontent-kwi-c149="" id="tab" transform="translate(800.000000, 0.000000)">
              <polygon _ngcontent-kwi-c149="" id="Rectangle"
                       transform="translate(333.000000, 20.000000) scale(-1, 1) translate(-333.000000, -20.000000) "
                       points="0 0 666 0 636.783743 40 0 40"></polygon>
              <g _ngcontent-kwi-c149="" id="Group" transform="translate(45.000000, 10.000000)" fill="#FFFFFF" fill-rule="nonzero">
                <g id="_x30_6" transform="translate(-402.000000, -371.000000)">
                  <g id="Page-1" transform="translate(402.000000, 371.000000)">
                    <path id="Fill-3" class="st0" d="M45.7,17c0,0,0,0.1,0,0.1c0,0.6-0.9,14.2-17.9,20.3c0,0,0,0,0,0v-8.4l0,0l10.2-11
                      c0.9-1,0.9-2.5-0.1-3.5c-1-0.9-2.6-0.9-3.5,0.1l-6.6,7.1l0,0v-7.3c0,0,0,0,0-0.1c0.8-1.8,4.7-9.1,14.4-9.1c0.1,0,0.2,0,0.3,0
                      C46.3,9.9,45.7,16.9,45.7,17z M22.9,21.9L22.9,21.9l-6.6-7.1c-0.9-1-2.5-1.1-3.5-0.1c-1,0.9-1.1,2.5-0.1,3.5l10.2,11l0,0v8.4
                      c0,0,0,0,0,0C5.9,31.3,5,17.7,5,17.1c0,0,0-0.1,0-0.1c0-0.1-0.6-7.1,3.2-11.6c0.1,0,0.2,0,0.3,0c9.7,0,13.6,7.3,14.4,9.2
                      c0,0,0,0,0,0V21.9z M45.4,1.2c-0.4-0.4-1-0.7-1.6-0.7C34.5-0.1,28.5,4.6,25.3,9c-3.2-4.4-9.2-9-18.5-8.5c-0.6,0-1.2,0.3-1.6,0.7
                      c-6,5.9-5.3,15.3-5.2,16.2c0,0.7,0.4,5.3,3.5,10.5c2.9,5.1,8.9,11.8,21,15.2c0.1,0,0.1,0,0.2,0.1h0c0,0,0.1,0,0.1,0
                      c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0l0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c22.3-6.3,24.3-24,24.4-25.6
                      C50.6,16.9,51.5,7.3,45.4,1.2z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
    <div class="message">
      <span *ngIf="!shortMessage">
        {{message}}
      </span>
      <span *ngIf="shortMessage">
        {{shortMessage}}
      </span>
    </div>
  </div>
</div>
