<div>

    <ngb-alert class="error-alert" *ngIf="errorMessage" type="info" [dismissible]="false" (close)="errorMessage = ''">{{ errorMessage }}</ngb-alert>

    <div class="data" *ngIf="brandAgreements != null">
        <div class="header">
            <div class="signed"><span ee-sortable="signed" (sort)="onSort($event)">Signed</span></div>
            <div class="name"><span ee-sortable="title" direction="asc" (sort)="onSort($event)">Company</span></div>
            <div class="date"><span ee-sortable="agreedDate" (sort)="onSort($event)">Agreement Date</span></div>
        </div>
        <div class="content">
            <div *ngFor="let agreement of brandAgreements">
                <div class="mobile-name">
                    <span (click)="showBrandModal(agreement)">{{agreement.title}}</span>
                </div>
                <div class="signed">
                    <div class="mobile-caption">Signed</div>
                    <div *ngIf="isAgreementSigned(agreement)">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                        </svg>
                    </div>
                    <div *ngIf="docusignPending(agreement)">
                        <svg [ngbTooltip]="docusignPendingMessage(agreement)" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-hourglass-split" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13s-.866-1.299-3-1.48V8.35z"/>
                        </svg>
                    </div>
                </div>
                <div class="name">
                    <span (click)="showBrandModal(agreement)">{{agreement.title}}</span>
                </div>
                <div class="date">
                    <div class="mobile-caption">Agreement Date</div>
                    <div>{{(isAgreementSigned(agreement) ? agreement.signature?.recentActionDate : null) | date:'MM/dd/yyyy'}}</div>
                </div>
            </div>
        </div>
    </div>

</div>
