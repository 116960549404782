<div class="servings"
     *ngIf="item != null && item.servingSizeAmount > 0 && item.servingsPerContainer > 0">

    <div *ngIf="item.servingSizeAmount > 0">
        <span class="text">Serving Size:</span>
        <span class="amount">{{item.servingSizeAmount}} {{item.servingSizeUnitOfMeasureAbbreviation}}</span>
    </div>

    <div *ngIf="item.servingsPerContainer > 0">
        <span class="text">Servings Per Container:</span>
        <span class="amount">{{item.servingsPerContainer}}</span>
    </div>

    <div class="disclaimer-holder">
        <ng-template #tipContent>
            <div class="disclaimer-tooltip">
                <h3>Disclaimer: </h3>
                <p>
                    We work to ensure that product information is correct;
                    however, brands may make changes to products from time to time,
                    and actual product packaging and materials may contain more and/or different information than that shown on our website.
                    Please consult all labels, warnings, and directions prior to using or consuming products for the most accurate product information.
                </p>
            </div>
        </ng-template>
        <span [ngbTooltip]="tipContent" class="hoverable" triggers="click hover blur" tooltipClass="ngb-tooltip-no-backround">Disclaimer</span>
        <div class="disclaimer-tooltip disclaimer-tooltip-no-hover">
            <h3>Disclaimer: </h3>
            <p>
                We work to ensure that product information is correct;
                however, brands may make changes to products from time to time,
                and actual product packaging and materials may contain more and/or different information than that shown on our website.
                Please consult all labels, warnings, and directions prior to using or consuming products for the most accurate product information.
            </p>
        </div>
    </div>
</div>

<div class="ingredients" *ngIf="item != null && item.productDetails != null">
    <ee-safe-html *ngIf="item.productDetails.productDescriptionView == 2 && (item.productDetails.labelText || '') != ''"
                  spacing="20"
                  [html]="item.productDetails.labelText"></ee-safe-html>

    <div *ngIf="item.productDetails.productDescriptionView != 2 && activeIngredients.length > 0 "
         class="ingredients-table">

        <div class="ingredients-header">
            <span class="ingredient-name"></span>
            <span class="ingredient-amount">Amount Per Serving</span>
            <span class="ingredient-percentage">
                <span *ngIf="hasDailyValue()">% Daily Value</span>
            </span>
        </div>

        <div *ngFor="let ingredient of activeIngredients"
             class="ingredient-row">
            <span class="ingredient-name">{{ingredient.supplierName}}</span>
            <span class="ingredient-amount">
                <span class="tablet-label">Amount Per Serving:</span>
                <span>{{getIngredientAmount(ingredient)}}</span>
            </span>
            <span class="ingredient-percentage">
                <span *ngIf="ingredient.percentageDailyValue != null && ingredient.percentageDailyValue > 0">
                    <span class="tablet-label">Daily Value:</span>
                    <span>{{ingredient.percentageDailyValue}}%</span>
                </span>
            </span>
        </div>
    </div>
</div>

<div class="other-ingredients" *ngIf="otherIngredients != ''">
    <span>Other Ingredients:</span>
    <div>{{otherIngredients}}</div>
</div>