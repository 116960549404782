import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BannerModel } from '../../models';
import { BannerService } from '../../services/banner/banner.service';

@Component({
    selector: 'ee-banner-preview',
    templateUrl: './banner-preview.component.html',
    styleUrls: ['./banner-preview.component.less']
})
export class BannerPreviewComponent implements OnInit {
    constructor(private bannerService: BannerService) {}

    ngOnInit(): void {

        this.bannerService.getPreviewEntry(this.bannerId).then(
            banner => this.banner = banner,
            error => this.error = error.message
        );
    }

    @Input() bannerId: string;
    @Output() actionTriggered = new EventEmitter<{ actionUrl: string }>();

    banner: BannerModel;
    error: string;

    onClick() {
        const actionUrl = this.banner.actionUrl;
        this.actionTriggered.emit({ actionUrl });
    }
}