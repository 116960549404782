import { Component, OnInit, Input } from '@angular/core';

import { ImagesService, ProductCarouselDialogService, BrowserFeatureDetectorService } from '../../services';
import { ImageModel, EqpStatusModel } from '../../models';

@Component({
    selector: 'ee-product-images',
    templateUrl: './product-images.component.html',
    styleUrls: ['./product-images.component.scss']
})
export class ProductImagesComponent implements OnInit {
    constructor(private service: ImagesService,
                private carouselService: ProductCarouselDialogService,
                detector: BrowserFeatureDetectorService) {
        this.showSwipableComponent = detector.hasTouchScreen();
    }

    images: ImageModel[];
    current: ImageModel;
    hasError = false;
    currentLargeImageFailed: boolean;
    showSwipableComponent: boolean;

    @Input() productId: number;
    @Input() itemCode: string;
    @Input() eqpStatus: EqpStatusModel;

    ngOnInit(): void {
        this.service
            .getItemImages(this.productId, this.itemCode)
            .subscribe(images => {
                this.images = images.length > 0 ? images : null;
                this.hasError = images.length === 0;
            }, () => {
                this.hasError = true;
            });
    }

    onSelected(image: ImageModel){
        this.current = image;
        this.currentLargeImageFailed = false;

        // NOTE: just disable the front image as per requirement
        if (this.current === this.images[0])
            this.currentLargeImageFailed = true;
    }

    failedWithZoomImage() {
        this.currentLargeImageFailed = true;
    }

    onClicked() {
        this.carouselService.openDialog(this.images, this.current);
    }
}