import { Component, OnInit } from '@angular/core';
import { Category } from '../../model/category.model';
import { MegaMenuService } from '../../mega-menu.service';
import { FeaturedProduct } from '../../model/featured-product.model';
import {AccountModel, AccountService, ConfigurationService, GoogleAnalyticsService} from '../../../../core';
import { FeatureService } from '@emersonecologics/emerson-angular-trove';

@Component({
    selector: 'ee-mobile-accordion-menu',
    templateUrl: './mobile-accordion-menu.component.html',
    styleUrls: ['./mobile-accordion-menu.component.scss']
})

export class MobileAccordionMenuComponent implements OnInit{

    public showMegaMenu: boolean;

    public categories: Category[];
    public newProducts: FeaturedProduct[];
    public activeSection: string;
    public activeCategory: string;
    public accountInfo: AccountModel = null;
    public singleRegFeatureFlagEnabled: boolean;
    public signupUrl: string;

    public categoryDisplayRank = {
        'Nutritional Supplements': 10,
        'Vitamins & Minerals': 20,
        'Herbs & Acupuncture': 30,
        Homeopathy: 40,
        'Lab Tests & Medical Supplies': 50,
        'Personal Care & Healthy Living': 60,
        'Animal Health': 70
    };

    constructor(
        private service: MegaMenuService,
        private ga: GoogleAnalyticsService,
        private accountService: AccountService,
        private featureService: FeatureService,
        private configurationService: ConfigurationService
    ) {
        this.showMegaMenu = true;
        this.featureService = featureService;
        this.signupUrl = configurationService.getEnvironmentConfiguration().registrationServiceUrl + 'emerson/register';
    }

    setActiveSection(section: string): void{
        this.activeCategory = null;
        this.activeSection = this.activeSection === section ? null : section;
    }

    setActiveCategory(category: string): void{
        this.activeCategory = this.activeCategory === category ? null : category;
    }

    resetActiveSection(): void{
        this.activeCategory = null;
        this.activeSection = null;
    }

    urlStrip(taxonomyName: string): string{
        if (!taxonomyName) return '';
        return taxonomyName.replace(/[^\w\d\s]*/g, '').replace(/\s+/g, '-');
    }

    ngOnInit(): void {
        this.service.getCategories().subscribe(
            data => {
                data.sort((s1, s2) => {
                    const v1: number = (s1.category in this.categoryDisplayRank) ? this.categoryDisplayRank[s1.category] : 1000;
                    const v2: number = (s2.category in this.categoryDisplayRank) ? this.categoryDisplayRank[s2.category] : 1000;
                    return v1 >= v2 ? v1 > v2 ? 1 : 0 : -1;
                });
                this.categories = data;
                this.categories.forEach((category) => {
                    category.subCategory.sort((sub1, sub2) => {
                        return sub1.subCategory >= sub2.subCategory ? sub1.subCategory === sub2.subCategory ? 0 : 1 : -1;
                    });
                });
            }
        );

        this.service.getFeaturedProducts().subscribe(
            data => {
                this.newProducts = data;
                this.newProducts.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
            }
        );

        this.accountService.getCachedAccountInfo().then(accountInfo => {
            this.accountInfo = accountInfo;
        }).catch(error => {
            this.accountInfo = null;
        });

        this.featureService.isEnabledObserveViaName('ares-single-registration').subscribe(isEnabled => {
            this.singleRegFeatureFlagEnabled = isEnabled;
        });

        this.featureService.isEnabledObserveViaName('eev2-signup').subscribe(isEnabled => {
          if (isEnabled) this.signupUrl = '/practitioner-signup';
      });
    }

    redirectToSignup(event): void {
        event.preventDefault();
        window.location.href = this.signupUrl;
    }
    gaTracking(action: string, label: string): void{
        this.ga.sendEvent('MegaMenu', action, label, 1);
    }
}
