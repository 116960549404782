import { Component } from '@angular/core';
import { OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ConfigurationService } from '../../services';

@Component({
    selector: 'google-recaptcha',
    template: ''
})

export class GoogleRecaptchaComponent implements OnInit{
    constructor(private configurationService: ConfigurationService, private render2: Renderer2, @Inject(DOCUMENT) public document: any) {
        this.environment = configurationService.getEnvironmentConfiguration();
        this.siteKey = this.environment.recaptchaConfig.siteKey;
    }

    private environment;
    private siteKey: string;

    ngOnInit() {
        if (!this.siteKey) return;
        const recaptchaScript = this.render2.createElement('script');
        recaptchaScript.src = 'https://www.google.com/recaptcha/api.js?render=' + this.siteKey + '&badge=bottomleft';
        this.render2.appendChild(this.document.body, recaptchaScript);
    }
}