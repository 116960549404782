import { Injectable } from '@angular/core';
import { CartSummaryModel } from 'client/angular/core/models';

import { Subject, Observable, from, concat } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CartChangesService {
    private cartIdGetter: () => number;
    private subject = new Subject<number>();
    private setNewCartSummaryCallback: (s: CartSummaryModel) => void;

    // methods to be called from AngularJS
    raiseCartChangeEvent(cartId: number) {
        this.subject.next(cartId);
    }

    initialize(cartIdGetter: () => number, setNewCartSummary: (s: CartSummaryModel) => void) {
        this.cartIdGetter = cartIdGetter;
        this.setNewCartSummaryCallback = setNewCartSummary;
    }

    // methods to be called from Angular
    cartId(): Observable<number> {
        const currentValueObservable = from([this.getCurrentCartId()]);
        const futureObservable = this.subject.asObservable();
        const combined = concat(currentValueObservable, futureObservable);
        return combined;
    }

    getCurrentCartId() {
        return this.cartIdGetter != null ? this.cartIdGetter() : null;
    }

    setNewCartSummary(cartSummary: CartSummaryModel) {
        if (this.setNewCartSummaryCallback != null)
            this.setNewCartSummaryCallback(cartSummary);
    }
}