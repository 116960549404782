<div class="brand-agreement-popup">
    <h1 class="brand-agreement-popup-header">Brand Agreement</h1>
    <button type="button" class="brand-agreement-popup-close-button" aria-describedby="modal-title" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="brand-agreement-popup-body" >
        <div [innerHTML]="brandAgreement.agreementBodyMarkdown | markdown"></div>
    </div>

    <div class="brand-agreement-popup-footer">
        <div class="brand-agreement-popup-footer-text">
            <div *ngFor="let line of presentation.informationText">{{line}}</div>
            <div class="error" *ngIf="errorMessage != null">{{errorMessage}}</div>
        </div>
        <div class="brand-agreement-popup-button-group">
            <ee-button styling="link" (click)="closeModal()">Close</ee-button>
            <ee-button
                styling="green"
                *ngIf="presentation.hasApplyButton"
                (click)="apply()"
                [waiting]="waiting">{{presentation.applyButtonText}}</ee-button>
            <span *ngIf="accountInfo.isImpersonated && isDocusign()" [ngbTooltip]="tipContent" class="hoverable" triggers="click hover blur">
                <ee-button
                    styling="orange"
                    (click)="sendHardReset()"
                    [waiting]="waiting">
                    HARD RESET
                </ee-button>
            </span>
        </div>
    </div>
</div>
