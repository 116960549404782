import {NgModule, APP_INITIALIZER, Injectable} from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';

import moduleName from './app/app.angularjs';

import { AngularDowngradeTo } from './angular-downgrade';
import { OrderHistoryModule } from './angular/modules/order-history/order-history.module';
import { SearchModule } from './angular/modules/search/search.module';

import {ProductDetailsModule} from './angular/modules/product-details/product-details.module';
import {AccountInfoModule} from './angular/modules/account-info/account-info.module';
import {MegaMenuModule} from './angular/modules/mega-menu/mega-menu.module';
import { ConfigurationService as TroveConfigurationService, ContentfulService, IContentService } from '@emersonecologics/emerson-angular-trove';
import {ConfigurationService} from './angular/core';

AngularDowngradeTo(moduleName);

const appInitializerFn = (configService: ConfigurationService) => {
  return () => {
    return configService.setEnvironmentConfiguration();
  };
};

@Injectable()
export class ContentfulConfigurationClass {
  public configurations: any;
  constructor(private configService: ConfigurationService) {
    const environmentConfiguration = this.configService.getEnvironmentConfiguration();
    this.configurations = {
      contentful: {
        space: environmentConfiguration.contentfulSpace,
        environment: environmentConfiguration.contentfulEnvironment,
        accessToken: environmentConfiguration.contentfulToken
      }
    };
  }
}

@NgModule({
    imports: [
        BrowserModule,
        HammerModule,
        UpgradeModule,
        OrderHistoryModule,
        SearchModule,
        ProductDetailsModule,
        AccountInfoModule,
        MegaMenuModule
    ],
    declarations: [
    ],
    providers: [
      ConfigurationService,
      {
        provide: APP_INITIALIZER,
        useFactory: appInitializerFn,
        multi: true,
        deps: [ConfigurationService]
      },
      {
        provide: TroveConfigurationService,
        useClass: ContentfulConfigurationClass
      },
      {provide: IContentService, useClass: ContentfulService}
    ]
})
export class AppModule {
    constructor(private upgrade: UpgradeModule) { }
    ngDoBootstrap() {
        this.upgrade.bootstrap(document.body, [moduleName], { strictDi: true });
    }
}
