<div>
    <div *ngIf="margin > 0" class="margin-section">
        <div>
            Retail Price {{retailPrice | currency}}
        </div>
        <div>
            Margin {{margin | currency}} ({{marginPercentage | number: '1.0-2'}}%)
        </div>
    </div>
    <div class="price-section">
        Your Price {{userPrice | currency}}
    </div>
</div>