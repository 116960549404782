import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

window['pdfjs-dist/build/pdf'] = window['pdfjs-dist/build/pdf'] || { GlobalWorkerOptions: {} };
const pdfjsLib = window['pdfjs-dist/build/pdf'];
pdfjsLib.GlobalWorkerOptions.workerSrc = '/assets/pdfjs/pdf.worker.js';

export type RendererMethodType = {
    render: (canvas: HTMLCanvasElement, size: { width: number, height: number }) => Promise<boolean>
};

@Injectable({
    providedIn: 'root'
})
export class PdfLibraryService {
    constructor(private http: HttpClient) {
    }

    getFirstPageRenderer(url: string): Promise<RendererMethodType> {
        const promise = this.localCache[url] = this.localCache[url] || this.getFirstPageRendererInternal(url);

        return promise;
    }

    private async getFirstPageRendererInternal(url: string): Promise<RendererMethodType> {
        const buffer = await this.http.get(url, { responseType: 'arraybuffer' }).toPromise();
        const pdf = await pdfjsLib.getDocument({ data: buffer }).promise;
        const page = await pdf.getPage(1);

        return { render: (canvas: HTMLCanvasElement, size: {width: number, height: number}) => this.renderToCanvas(page, canvas, size) };
    }

    private async renderToCanvas(page, canvas: HTMLCanvasElement, size: {width: number, height: number}) {
        const pageViewport = page.getViewport({scale: 1});

        const scaleX = size.width / pageViewport.width;
        const scaleY = size.height / pageViewport.height;
        const scale = Math.min(scaleX, scaleY) * 2; // note: scale of X2 will look better, weird, ha?

        const renderViewport = page.getViewport({ scale });

        canvas.height = renderViewport.height;
        canvas.width = renderViewport.width;

        await page.render({ canvasContext: canvas.getContext('2d'), viewport: renderViewport }).promise;

        return true;
    }

    private localCache: { [key: string]: Promise<RendererMethodType> } = {};
}