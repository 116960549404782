import {Component, ViewChild, TemplateRef, AfterViewInit, Input, Output, EventEmitter} from '@angular/core';

import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SsoService} from '../../services/sso.service';
import {GoogleAnalyticsService} from '../../../../core';

@Component({
    selector: 'sso-modal',
    templateUrl: './sso-modal.component.html',
    styleUrls: ['./sso-modal.component.scss']
})

export class SsoModalComponent implements AfterViewInit{
    @Input() email: string;
    @Input() displayModalOnLogin;
    @Output() modalClose =  new EventEmitter<NgbModalRef>();

    @ViewChild('content') public content: TemplateRef<any>;
    public modalRef: NgbModalRef;
    @ViewChild('emailSent') public emailSent: TemplateRef<any>;
    public emailModalRef: NgbModalRef;

    constructor(private modalService: NgbModal, private ssoService: SsoService, private ga: GoogleAnalyticsService) {
    }

    ngAfterViewInit(): void {
        this.modalRef = this.modalService.open(this.content, {centered: true});
        if (this.displayModalOnLogin === 'true') {
          this.ga.sendEvent('Unmerged SSO', 'Merge reminder email', 'Email sent', null);
        }
        this.modalRef.result.then( result => {
            this.ga.sendEvent('SsoModal', 'Notification', 'close', 1);
        });
    }

    openEmailModal(): void {
        this.ssoService.resendEmail().subscribe();
        this.modalService.dismissAll('resentEmail');
        this.emailModalRef = this.modalService.open(this.emailSent, {centered: true});
        this.emailModalRef.result.then( result => {
            this.ga.sendEvent('SsoModal', 'resendEmail', 'close', 1);
        });
    }

    closeModel(modal) {
      if (modal === 'emailSent')
        this.modalClose.emit(this.emailModalRef);
      else
        this.modalClose.emit(this.modalRef);
    }
}
