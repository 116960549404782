import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, forkJoin, throwError } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { ServiceClientErrorModel } from '../../models';
import { IConfigurationService } from '../../interfaces';

// note: the list will grow over time, depending on JS demand of use, please keep it up to date
export type MicroserviceName = 'ares-api' | 'cart' | 'discount' | 'order' | 'payment' | 'inventory' | 'agreement' | 'warehouse' | 'auth';
const AwsAkrisMicroServices = ['agreement', 'warehouse'];

export type SupportKeyResponse = {supportKey: string};

@Injectable({
    providedIn: 'root'
})
export class ServiceClientService {
    private supportKeyResponse: SupportKeyResponse;

    constructor(private httpClient: HttpClient, private configuationService: IConfigurationService) {}

    getAsync<T>(serviceName: MicroserviceName, relativePath: string): Observable<T> {
        return this.sendAsync(serviceName, relativePath, 'GET', undefined);
    }

    postAsync<T>(serviceName: MicroserviceName, relativePath: string, body: any): Observable<T> {
        return this.sendAsync<T>(serviceName, relativePath, 'POST', body);
    }

    putAsync<T>(serviceName: MicroserviceName, relativePath: string, body: any): Observable<T> {
        return this.sendAsync<T>(serviceName, relativePath, 'PUT', body);
    }

    patchAsync<T>(serviceName: MicroserviceName, relativePath: string, body: any): Observable<T> {
        return this.sendAsync<T>(serviceName, relativePath, 'PATCH', body);
    }

    deleteAsync<T>(serviceName: MicroserviceName, relativePath: string): Observable<T> {
        return this.sendAsync<T>(serviceName, relativePath, 'DELETE', undefined);
    }

    isAuthenticated(): Promise<boolean> {
        return this.getAuthorization().then((auth) => {
            return auth.isAuthenticated;
        });
    }

    private sendAsync<T>(serviceName: MicroserviceName,
                         relativePath: string,
                         method: 'GET'|'POST'|'PUT'|'PATCH'|'DELETE',
                         body: any): Observable<T> {
        return forkJoin({
            absoluteUrl: this.getAbsoluteUrl(serviceName, relativePath),
            authorization: this.getAuthorization(),
            supportKey: this.getSupportKey()
        }).pipe(
            mergeMap(vars => {
                const url = vars.absoluteUrl;
                const headers = { 'Support-Key': vars.supportKey } as { 'Support-Key': string, Authorization: string };
                if (vars.authorization.isAuthenticated)
                    headers.Authorization = `Bearer ${vars.authorization.token}`;
                return this.httpClient
                    .request<{ response: T, body: T}>(method, url, { headers, body })
                    .pipe(catchError(error => this.handleError(error)));
            }),
            map(httpResponse => {
                // todo: we might want to catch any error codes here
                return httpResponse.response || httpResponse.body;
            })
        );
    }

    // note: this method might get extended when we want to construct valid query params, etc.
    private async getAbsoluteUrl(serviceName: MicroserviceName, relativePath: string): Promise<string> {
        const serviceHost = await this.getServiceHost(serviceName);
        return serviceHost + relativePath;
    }

    private async getServiceHost(serviceName: MicroserviceName): Promise<string> {
        const config = await this.configuationService.getConfiguration();
        const servicehost = serviceName === 'ares-api' ?
            config.aresApi :
            AwsAkrisMicroServices.includes(serviceName) ? config.awsAkrisServiceUrlPattern.replace('{ServiceName}', serviceName) :
            config.serviceUrlPattern.replace('{ServiceName}', serviceName);
        return servicehost;
    }

    private async getSupportKey(): Promise<string> {
        try {
            this.supportKeyResponse =
                this.supportKeyResponse ||
                await this.httpClient.get<SupportKeyResponse>('/api/account/supportkey').toPromise();
            return this.supportKeyResponse.supportKey;
        }
        catch (err) {
            return '';
        }
    }

    // note: we might extend this method to return "ups, you are not logged in yet..."
    private async getAuthorization(): Promise<{token: string, isAuthenticated: boolean}> {
        try {
            const tokenReference = await this.httpClient.get<{bearer: string}>('/api/account/token').toPromise();
            if (tokenReference.bearer) return { token: tokenReference.bearer, isAuthenticated: true };
            return { token: null, isAuthenticated: false };
        }
        catch (err) {
            return { token: null, isAuthenticated: false };
        }
    }

    private handleError(error: HttpErrorResponse) {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.warn(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);

        const errorToThrow = this.buildExceptionObject(error.status, error.error);
        return throwError(errorToThrow);
    }

    private buildExceptionObject(status: number, error: any): ServiceClientErrorModel {
        if (error == null)
            return {
                httpStatusCode: status,
                code: 0,
                message: 'Uncexpected error occurred.'
            };

        // so we either got error from Akris
        if (error.errorCode !== undefined)
            return {
                httpStatusCode: status,
                code: error.errorCode || 0,
                message: error.errorMessage || ''
            };

        // or from Ares
        const errors = error.errors || [];
        const internalError = (errors.length > 0 ? errors[0] : null) || {};
        return {
            httpStatusCode: status,
            code: internalError.code || 0,
            message: internalError.message || ''
        };
    }
}
