import { Component, forwardRef, Input, OnDestroy } from '@angular/core';

import { AccountModel, GoogleAnalyticsService } from 'client/angular/core';
import { GoogleTagDirectiveHost } from 'client/angular/core';
import { ChatService } from '../../services';

@Component({
    selector: 'ee-product-actions-contacts',
    templateUrl: './product-actions-contacts.component.html',
    styleUrls: ['./product-actions-contacts.component.scss'],
    providers: [{ provide: GoogleTagDirectiveHost, useExisting: forwardRef(() => ProductActionsContactsComponent)}]
})
export class ProductActionsContactsComponent implements OnDestroy, GoogleTagDirectiveHost {
    constructor(private chatService: ChatService) { }

    getGoogleTagData() {
        return {
            componentName: 'product-details-contact-us',
            textDetail: '',
            numericDetail: 0
        };
    }

    @Input() user: AccountModel;

    hideDialog: { destroy: () => void };

    showPractitionerLink() {
        return (this.user ?? {}).accountType === 'Practitioner';
    }

    isBusinessHours() {
        return this.chatService.isBusinessHours();
    }

    ngOnDestroy(): void {
        if (this.hideDialog != null)
            this.hideDialog.destroy();
    }

    openChat() {
        if (this.chatService.isBusinessHours()) {
            this.chatService.openChatWindow();
        } else {
            this.hideDialog = this.chatService.showChatUnavailableDialog();
        }
    }
}
