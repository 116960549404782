<table>
<tr>
    <td style="padding-right: 10px;"><i class="fa fa-circle oval" aria-hidden="true"></i></td>
    <td class="notification-list-item-header">{{notificationItem?.header}}</td>
</tr>
<tr>
    <td style="padding-right: 10px;"><i class="fa fa-circle oval-off " aria-hidden="true"></i></td>
    <td style="padding-right: 15px;">{{notificationItem?.body}} <span class="notification-action-button" (click)="notificationItem?.action(notificationItem)">{{notificationItem?.actionText}}</span></td>
</tr>
</table>
