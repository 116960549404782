import { Injectable } from '@angular/core';
import { ContentfulClientApi, createClient } from 'contentful';

import { ConfigurationService } from 'client/angular/core';

@Injectable({
    providedIn: 'root'
})
export class ContentfulFactoryService {
    constructor(private configurationService: ConfigurationService) {
      this.environment = configurationService.getEnvironmentConfiguration();
    }
    private environment;

    async getPreviewClient(): Promise<ContentfulClientApi> {
        const configuration = await this.configurationService.getConfiguration();
        const accessToken = configuration.contentfulPreviewToken;

        if ((accessToken || '') === '')
            throw new Error('You are not logged in or don`t have enough rights to access requested information.');

        return createClient({
            host: 'preview.contentful.com',
            space: this.environment.contentfulSpace,
            environment: this.environment.contentfulEnvironment,
            accessToken
        });
    }

    async getApiClient(): Promise<ContentfulClientApi> {
        return createClient({
            space: this.environment.contentfulSpace,
            environment: this.environment.contentfulEnvironment,
            accessToken: this.environment.contentfulToken
        });
    }
}
