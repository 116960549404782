import { Component } from '@angular/core';
import { OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {ConfigurationService} from '../../services';

@Component({
    selector: 'google-analytics-and-tag-manager',
    template: `
      <emerson-google-analytics *ngIf="gaAccountId != null" [gaAccountId]="gaAccountId"></emerson-google-analytics>
      <emerson-google-tag-manager *ngIf="gtmAccountId !=null" [gtmAccountId]="gtmAccountId"></emerson-google-tag-manager>
    `
})
export class GoogleAnalyticsAndTagManagerComponent implements OnInit{
    constructor(private configurationService: ConfigurationService, private render2: Renderer2, @Inject(DOCUMENT) public document: any) {
        this.environment = configurationService.getEnvironmentConfiguration();
        this.gtmAccountId = this.environment.googleTagManagerId;
        this.gaAccountId = this.environment.googleAnalyticsAccountId;
    }

    private environment;
    public gtmAccountId: string;
    public gaAccountId: string;

    ngOnInit() {
        if (!this.gaAccountId) return;
        const gtagScript1 = this.render2.createElement('script');
        gtagScript1.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.gaAccountId;
        const gtagScript2 = this.render2.createElement('script');
        gtagScript2.text = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${this.gaAccountId}');`;
        this.render2.appendChild(this.document.body, gtagScript1);
        this.render2.appendChild(this.document.body, gtagScript2);
    }
}
