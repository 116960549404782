import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {
    EmersonComponentsModule,
    GoogleTagManagerComponent,
    FeatureService,
    ConfigurationService as  TroveConfigurationService,
    UnleashService,
    LocalStrategyComputeProvider,
    IStrategyComputeProvider,
    ValidateaddressService
} from '@emersonecologics/emerson-angular-trove';
import { NotificationBellService } from './components/notification-bell/services/notification-bell.service';

import { AddressPipe, MarkdownPipe, UrlParameterPipe } from './pipes';
import { GoogleTagDirective } from './directives';
import { ConfigurationService } from './services';
import { IConfigurationService, ISupportKeyService } from './interfaces';
import { ButtonComponent, WaitingSpinnerComponent, ToastsComponent,
         SortDropdownComponent, ZoomableComponent, GoogleAnalyticsAndTagManagerComponent, GoogleRecaptchaComponent,
         ResponsiveImageComponent, ExpandablePanelComponent, NotificationBellComponent, NotificationBellListItemComponent } from './components';
import { AppTabLinkComponent } from './components/app-tab-link/app-tab-link.component';
import { SupportKeyService } from './services/support-key/support-key.service';

@NgModule({
    declarations: [
        AddressPipe,
        MarkdownPipe,
        UrlParameterPipe,
        WaitingSpinnerComponent,
        ToastsComponent,
        SortDropdownComponent,
        ZoomableComponent,
        GoogleAnalyticsAndTagManagerComponent,
        GoogleRecaptchaComponent,
        ResponsiveImageComponent,
        ExpandablePanelComponent,
        ButtonComponent,
        NotificationBellComponent,
        NotificationBellListItemComponent,
        GoogleTagDirective,
        AppTabLinkComponent,
    ],
    exports: [
        WaitingSpinnerComponent,
        AddressPipe,
        MarkdownPipe,
        ToastsComponent,
        SortDropdownComponent,
        ZoomableComponent,
        GoogleAnalyticsAndTagManagerComponent,
        GoogleRecaptchaComponent,
        ResponsiveImageComponent,
        ExpandablePanelComponent,
        ButtonComponent,
        GoogleTagManagerComponent,
        NotificationBellComponent,
        GoogleTagDirective,
        AppTabLinkComponent,
        UrlParameterPipe,
    ],
    imports: [
        CommonModule,
        NgbModule,
        EmersonComponentsModule
    ],
    providers: [
        { provide: IConfigurationService, useClass: ConfigurationService },
        { provide: IStrategyComputeProvider, useClass: LocalStrategyComputeProvider},
        NotificationBellService,
        TroveConfigurationService,
        UnleashService,
        FeatureService,
        ValidateaddressService,
        { provide: ISupportKeyService, useClass: SupportKeyService}
    ]
})
export class CoreModule { }
