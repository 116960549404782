import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'urlParameter'
})
export class UrlParameterPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        return value ? value.toLowerCase().replace(/[^a-z0-9]/gmi, '-') : value;
    }

}
