import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreModule } from 'client/angular/core';
import { BrandAgreementListComponent, SortableHeaderItemDirective } from './brand-agreements/components/brand-agreement-list/brand-agreement-list.component';
import { BrandAgreementPopupComponent } from './brand-agreements/components/brand-agreement-popup/brand-agreement-popup.component';

@NgModule({
    declarations: [
        BrandAgreementListComponent,
        SortableHeaderItemDirective,
        BrandAgreementPopupComponent
    ],
    entryComponents: [
    ],
    imports: [
        CommonModule,
        CoreModule,
        NgbModule
    ],
    providers: [
        DatePipe
    ]
})
export class AccountInfoModule { }
