<ng-template #content>
    <div class="modal-header">
        <h4 class="modal-title">It's Time To Simplify Your Sign-in.</h4>
        <button type="button" class="close ee-modal-close-btn" aria-label="Close" (click)="closeModel('content')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" >
        <p>Now it's easier to access your Emerson Wholesale and Wellevate® Virtual Dispensary accounts through a single shared login.</p>
        <p>Activate today by authenticating your {{email}} address now.</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn primary-btn ee-modal-btn"  (click)="openEmailModal()">SEND EMAIL</button>
    </div>
</ng-template>


<ng-template #emailSent>
    <div class="modal-header">
        <h4 class="modal-title">Email Sent</h4>
        <button type="button" class="close ee-modal-close-btn" aria-label="Close" (click)="closeModel('emailSent')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>An email has been sent to {{email}}.</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn primary-btn ee-modal-btn" (click)="closeModel('emailSent')">OK</button>
    </div>
</ng-template>
