import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ImageModel } from '../../models';

@Component({
    selector: 'product-carousel',
    templateUrl: './product-carousel.component.html',
    styleUrls: ['./product-carousel.component.scss']
})

export class ProductCarouselComponent implements OnInit {

    @Input() images: ImageModel[];

    activeImage: ImageModel;
    currentSlide: number;

    isImage() {
        return  ProductCarouselComponent.getExtension(this.activeImage.zoom) !== 'pdf' ? true : false;
    }

    private static getExtension(url: string) {
        if ((url || '') === '') return '';
        const fileName = url.split('/').pop();
        const splitted = fileName.split('.');
        return splitted.length > 1 ? splitted[splitted.length - 1].toLowerCase() : '';
    }

    constructor(private activeModal: NgbActiveModal) { }

    ngOnInit(): void {
        this.setImageIndex(this.activeImage);
    }

    onSelectionChanged(image: ImageModel) {
        this.setImageIndex(image);
    }

    setImageIndex(image: ImageModel) {
        for (let idx = 0; idx < this.images.length; idx++) {
            if (this.images[idx].thumb === image.thumb) {
                this.currentSlide = idx;
                this.activeImage = this.images[idx];
                break;
            }
        }
    }

    onDismiss() {
        this.activeModal.dismiss();
    }

    onPreviousClick() {
        const previous = this.currentSlide - 1;
        this.currentSlide = previous < 0 ? this.images.length - 1 : previous;
        this.activeImage = this.images[this.currentSlide];
    }

    onNextClick() {
        const next = this.currentSlide + 1;
        this.currentSlide = next === this.images.length ? 0 : next;
        this.activeImage = this.images[this.currentSlide];
    }
}