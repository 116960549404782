import { Injectable } from '@angular/core';
import { ISupportKeyService } from '../support-key/support-key.service';
import { WindowProvider } from '../window/window-provider';

interface WindowWithNewRelicApi extends Window {
    newrelic: any;
}

@Injectable({ providedIn: 'root' })
export class NewRelicService {
    constructor(private windowProvider: WindowProvider, private supportKeyService: ISupportKeyService){}

    async captureSessionContext() {
        const window = this.windowProvider.getWindow() as WindowWithNewRelicApi;
        const newRelic = window.newrelic;

        if (!newRelic) {
            return;
        }

        const supportKey = await this.supportKeyService.getSupportKey();
        const preExistingInteraction = newRelic.interaction();

        preExistingInteraction.setAttribute('supportKey', supportKey);
        newRelic.setCustomAttribute('supportKey', supportKey);
        newRelic.addToTrace({ supportKey });
    }
}
