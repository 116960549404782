<div [style.width.px]="width"
     [style.height.px]="height">

    <img *ngIf="isImage() && !displayError"
         [ngStyle]="{ 'max-width.px': fit ? undefined : width, 'max-height.px': fit ? undefined : height, 'width': fit ? '100%' : undefined }"
         [src]="src"
         [alt]="alt"
         (error)="onError($event)" />

    <div *ngIf="isPdf() && !displayError" [ngClass]="{ loading: pdfLoading, 'pdf-holder': true }" class2="pdf-holder">
        <div class="waiting">
            <waiting-spinner></waiting-spinner>
            <div>Loading...</div>
        </div>
        <canvas #pdfCanvas
                [ngStyle]="{ 'max-width.px': fit ? undefined : width, 'max-height.px': fit ? undefined : height, 'width': fit ? '100%' : undefined }"></canvas>
    </div>

    <div *ngIf="displayError" class="error">
        <img alt="image not available"
             [ngStyle]="{ 'max-width.px': fit ? undefined : width, 'max-height.px': fit ? undefined : height, 'width': fit ? '100%' : undefined }"
             src="../../../../../assets/images/icons/Image_Not_Available.png" />
    </div>
</div>