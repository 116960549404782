import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IConfigurationService } from 'client/angular/core/interfaces';
import { ConfigurationModel } from 'client/angular/core/models';
import { EnvironmentConfigurationModel } from '../../models/environment-configuration.model';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService implements IConfigurationService {
    constructor(private httpClient: HttpClient) {}

    private urlConfiguration: ConfigurationModel;
    private environmentConfiguration: EnvironmentConfigurationModel;

    async getConfiguration(): Promise<ConfigurationModel> {
        this.urlConfiguration =
            this.urlConfiguration ||
            await this.httpClient.get<ConfigurationModel>('/api/account/configUrl').toPromise();
        return this.urlConfiguration;
    }

    setEnvironmentConfiguration(): Promise<EnvironmentConfigurationModel> {
      return this.httpClient.get<EnvironmentConfigurationModel>('/api/configuration')
        .toPromise()
        .then(config => this.environmentConfiguration = config);
    }

    getEnvironmentConfiguration(): EnvironmentConfigurationModel {
      return this.environmentConfiguration;
    }
}
