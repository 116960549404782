import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ISortOptionModel } from 'client/angular/core';

@Component({
    selector: 'sort-dropdown',
    templateUrl: './sort-dropdown.component.html',
    styleUrls: ['./sort-dropdown.component.less'],
})
export class SortDropdownComponent<T> implements OnInit {
    constructor() {}

    @Input()
    items: T[];

    @Input()
    disabled = false;

    @Input()
    sortOption: ISortOptionModel<T>;

    @Output()
    sortOptionChange = new EventEmitter<ISortOptionModel<T>>();

    ngOnInit(): void {
        if (this.sortOption != null)
            return;

        if ((this.items || []).length > 0) {
            this.sortOption = { name: this.items[0], direction: 'asc' };
            this.sortOptionChange.emit(this.sortOption);
        } else {
            this.sortOption = { name: null, direction: 'asc' };
            this.sortOptionChange.emit(this.sortOption);
        }
    }

    onSelectSort(name: any) {
        if (this.sortOption.name === name)
            this.sortOption.direction = this.sortOption.direction === 'asc' ? 'desc' : 'asc';
        else
            this.sortOption = { name, direction: 'asc' };

        this.sortOptionChange.emit(this.sortOption);
    }
}