import { Injectable } from '@angular/core';
import { Entry } from 'contentful';

import { BannerModel } from '../../models';
import { ContentfulFactoryService } from '../contentful-factory/contentful-factory.service';

export type ContentfulBannerType = {
    desktopImage: any,
    mobileImage: any,

    actionUrl: string,

    brand: any[],
    condition: any[],
    category: any[],
    subCategory: any[],
    attribute: any[],
    searchTerms: string
};

export type SearchOptionsType = {
    brandIds: Set<number>,
    conditionIds: Set<number>,
    categoryIds: Set<number>,
    subCategoryIds: Set<number>,
    attributeIds: Set<number>,

    searchTerms: string
};

@Injectable({
    providedIn: 'root'
})
export class BannerService {
    constructor(private contentfulService: ContentfulFactoryService) { }

    public async getPreviewEntry(id: string): Promise<BannerModel> {
        const entry = await this.getSingleEntry(id);
        return BannerService.extractBannerData(entry);
    }

    public async findApplicableBanner(searchOptions: SearchOptionsType): Promise<BannerModel> {
        const allBanners = await this.getAllBanners();
        const firstMatchingBanner = BannerService.firstMatch(allBanners, searchOptions);
        return firstMatchingBanner;
    }

    private async getSingleEntry(id: string): Promise<Entry<ContentfulBannerType>> {

        const client = await this.contentfulService.getPreviewClient();

        try {
            return await client.getEntry<ContentfulBannerType>(id);
        } catch (e) {
            throw new Error('Unexpected error occurred.');
        }
    }

    private async getAllBanners(): Promise<BannerModel[]> {
        const client = await this.contentfulService.getApiClient();

        const contentfulData = await client.getEntries<ContentfulBannerType>({
            content_type: 'categoryBrandLandingPage',
            limit: 1000
        });

        const result = contentfulData.items
            .map(item => {
                try {
                    return BannerService.extractBannerData(item);
                } catch (e) {
                    // note: we might want to report it to newrelic, or into google tag manager...
                    // note: we might still want to show valid banners
                    return null;
                }
            })
            .filter(banner => banner != null)
            .sort((l, r) => {
                if (l.updatedAt.toString() === r.updatedAt.toString()) return 0;
                return l.updatedAt < r.updatedAt ? 1 : -1; // descending order
            });

        return result;
    }

    private static extractBannerData(entry: Entry<ContentfulBannerType>): BannerModel {
        const fields = entry.fields || {} as ContentfulBannerType;

        const desktopImageUrl = BannerService.getMediaUrl(fields.desktopImage, 'Desktop Image');
        const mobileImageUrl = fields.mobileImage != null ? BannerService.getMediaUrl(fields.mobileImage, 'Mobile Image') : desktopImageUrl;

        return {
            desktopImageUrl,
            mobileImageUrl,

            actionUrl: fields.actionUrl || '',

            brandIds: new Set<number>((fields.brand || []).map(elem => elem.fields.id)),
            categoryIds: new Set<number>((fields.category || []).map(elem => elem.fields.id)),
            subCategoryIds: new Set<number>((fields.subCategory || []).map(elem => elem.fields.id)),
            conditionIds: new Set<number>((fields.condition || []).map(elem => elem.fields.id)),
            attributeIds: new Set<number>((fields.attribute || []).map(elem => elem.fields.id)),

            searchTerms: fields.searchTerms || '',

            updatedAt: new Date(entry.sys.updatedAt),
        };
    }

    private static getMediaUrl(element: any, name: string): string {
        try {
            return element.fields.file.url;
        }
        catch (exc) {
            throw new Error(`Could not read image URL for ${name} field. Probably it is not yet set or not published in contentful?`);
        }
    }

    private static firstMatch(allBanners: BannerModel[], searchOptions: SearchOptionsType): BannerModel {
        if (allBanners.length === 0)
            return null;

        return allBanners.find(banner => BannerService.bannerMatches(banner, searchOptions)) || null;
    }

    private static bannerMatches(banner: BannerModel, searchOptions: SearchOptionsType): boolean {

        if (BannerService.intersects(searchOptions.brandIds, banner.brandIds))
            return true;

        if (BannerService.intersects(searchOptions.conditionIds, banner.conditionIds))
            return true;

        if (BannerService.intersects(searchOptions.categoryIds, banner.categoryIds))
            return true;

        if (BannerService.intersects(searchOptions.subCategoryIds, banner.subCategoryIds))
            return true;

        if (BannerService.intersects(searchOptions.attributeIds, banner.attributeIds))
            return true;

        if ((searchOptions.searchTerms || '') !== '' &&
            searchOptions.searchTerms !== '*' &&
            searchOptions.searchTerms.toLowerCase() === banner.searchTerms.toLowerCase())
            return true;

        return false;
    }

    private static intersects<T>(setA: Set<T>, setB: Set<T>) {
        if (setA == null || setB == null)
            return false;

        for (const elem of setB)
            if (setA.has(elem))
                return true;

        return false;
    }
}