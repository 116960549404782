import { Injectable } from '@angular/core';
import { BackorderItemModel } from 'client/angular/modules/order-history/models';
import { ISortOptionModel } from 'client/angular/core';

export type BackorderSortType = 'Order Date'|'Est Ship Date'|'Product Name'|'Item Code'|'Order Number';
export const BackorderSortTypes: BackorderSortType[] = ['Order Date', 'Est Ship Date', 'Product Name', 'Item Code', 'Order Number'];

@Injectable({
    providedIn: 'root'
})
export class BackorderFilteringService {
    filterAndSort(items: BackorderItemModel[],
                  substring: string,
                  sortOption: ISortOptionModel<BackorderSortType>
                  ): BackorderItemModel[] {

        const comparator = BackorderFilteringService.getComparator(sortOption);
        const filteredItems = BackorderFilteringService.filterItems(items, substring);
        return filteredItems.sort(comparator);
    }

    private static filterItems(items: BackorderItemModel[], substring: string) {
        if (items == null || (substring || '') === '')
            return [...(items || [])];

        const searchLowered = substring.toLowerCase();
        return items.filter(item => {
            return (item.itemCode || '').toLowerCase().indexOf(searchLowered) >= 0 ||
                   (item.itemName || '').toLowerCase().indexOf(searchLowered) >= 0 ||
                   (item.fulfillmentId || '').toLowerCase().indexOf(searchLowered) >= 0;
        });
    }

    private static getComparator(sortOption: ISortOptionModel<BackorderSortType>):
        (a: BackorderItemModel, b: BackorderItemModel) => number {
        const { propertyName, isText } = BackorderFilteringService.getPropertyInfo(sortOption);

        const ascendingComparer = (a: BackorderItemModel, b: BackorderItemModel) => {
            const aValue = a[propertyName];
            const bValue = b[propertyName];

            // note: dates don`t compare with ==, dates are reference types
            if ((aValue || '').toString() === (bValue || '').toString())
                return a.orderId === b.orderId
                     ? (a.itemCode === b.itemCode ? 0 : (a.itemCode || '').localeCompare(b.itemCode || ''))
                     : (a.orderId > b.orderId ? 1 : -1);

            if (isText)
                return (aValue || '').localeCompare(bValue || '');
            else
                return aValue > bValue ? 1 : -1;
        };

        const ascending = sortOption.direction === 'asc';
        return ascending ? ascendingComparer : (a, b) => ascendingComparer(b, a);
    }

    private static getPropertyInfo(sortOption: ISortOptionModel<BackorderSortType>) {
        switch (sortOption.name) {
            case 'Order Date': return { propertyName: 'orderDate', isText: false };
            case 'Est Ship Date': return { propertyName: 'expectedDeliverDate', isText: false };
            case 'Product Name': return { propertyName: 'itemName', isText: true };
            case 'Item Code': return { propertyName: 'itemCode', isText: true };
            case 'Order Number': return { propertyName: 'fulfillmentId', isText: true };
            default: return { propertyName: 'orderId', isText: false };
        }
    }
}