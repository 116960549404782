<div class="pos-relative product-description" flex-gt-xs="50" flex-xs="100" >
    <div *ngIf="isLoggedIn && item">
        <div *ngIf="hasLimitedQuantity()" class="product-detail-warning">
          <p>Insufficient Quantity<br/>
          <span>Only {{item.defaultInventory.stock}} units available.</span>
          </p>
        </div>

        <div *ngIf="isItemDiscontinued()" class="product-detail-warning">
          <p>Item Discontinued<br/>
          <span *ngIf="hasInventoryStock()">Only {{item.defaultInventory.stock}} units available.</span>
          </p>
        </div>

        <div *ngIf="isSpecialOrderItem()" class="product-detail-success">
          <p>Special Order Item.<br/>
          <span class="product-detail-warning">Product will be placed on backorder and will process and ship on the
            earliest possible date.</span>
          </p>
        </div>

        <div *ngIf="isVendorBackOrderLimitedStock()" class="product-detail-warning">
          <p>Limited Quantities Available<br/>
            <span>Currently on backorder with our brand partner.</span>
            <span class="product-detail-warning"> Only {{item.defaultInventory.stock}} units available.</span>
          </p>
        </div>

        <div *ngIf="isOutOfStock()" class="product-detail-warning">
          <p>Item Out of Stock</p>
          <span>Will ship on the earliest possible date.</span>
        </div>

        <div *ngIf="isVendorBackOrderNoStock()" class="product-detail-warning">
          <p>Item out of stock.<br/>
          <span>Currently on back order with our brand partner.</span></p>
        </div>

        <div *ngIf="hasUnsignedBrandAgreement()" class="product-detail-warning restricted-message">
          <p>Purchase Not Authorized<br/>
            <span>
              You will need to agree to the brand partner <a href="/account/view-brand-agreements">terms</a> in order to purchase this item.
            </span>
          </p>
        </div>

        <div *ngIf="isRestricted()" class="product-detail-warning restricted-message">
            <p>Purchase Not Authorized<br/>
              <span class="restricted-message">
                {{item.restrictedMessage}}
              </span>
            </p>
        </div>

        <div *ngIf="hasNoStockInformation()" class="product-detail-warning">
          <p *ngIf="!hasExpectedDeliveryDate()">Item Out of Stock<br/>
            <span>Will ship on the earliest possible date.</span>
          </p>
          <p *ngIf="hasExpectedDeliveryDate()">Item Out of Stock<br/>
            <span>Back in stock on  {{ item.defaultInventory.expectedDeliverDate | date: "MM/dd/yyyy" }}</span>
          </p>
        </div>

        <div *ngIf="isInStock()" class="product-detail-success">
          <p>In stock and ready to ship</p>
        </div>
    </div>

    <div *ngIf="item">
      <h2 class="product-description-title">Item Description</h2>
      <div class="product-description-brand">
        By <a href="javascript:;" (click)="goToBrandSearch()" class="brand-name-text" title="{{item.productDetails.brandName}}">{{item.productDetails.brandName}}</a>
      </div>

      <ee-safe-html [html]="displayProductDescription" class="product-description-text hidden-print"></ee-safe-html>
      <a href="javascript:;" (click)="changeDescriptionLimit()">
      <span *ngIf="isDescriptionOverLimit && !showFullDescription" class="hidden-print">More</span>
      <span *ngIf="isDescriptionOverLimit && showFullDescription" class="hidden-print">Less</span>
      </a>

      <ee-safe-html [innerHTML]="item.productDetails.description" class="product-description-text printable-description"></ee-safe-html>
    </div>
  </div>