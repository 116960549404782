import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

import { ResponsiveImageModel } from '../../models';

@Component({
    selector: 'ee-responsive-image',
    templateUrl: './responsive-image.component.html',
    styleUrls: ['./responsive-image.component.less'],
})
export class ResponsiveImageComponent implements OnInit {
    constructor(private observer: BreakpointObserver) { }

    @Input() images: { [key: string]: ResponsiveImageModel };

    @Output() clicked = new EventEmitter();

    current: ResponsiveImageModel;

    ngOnInit(): void {

        const allConditions = [...Object.keys(this.images)];
        this.observer.observe(allConditions).subscribe(p => {

            const matchingConditions = allConditions.filter(key => p.breakpoints[key]);
            this.current = matchingConditions.length > 0 ? this.images[matchingConditions[0]] : this.images[allConditions[0]];
        });
    }

    onClick() {
        this.clicked.emit();
    }
}