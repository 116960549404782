import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ImageModel } from '../../models';

@Component({
    selector: 'product-thumbnail',
    templateUrl: './product-thumbnails.component.html',
    styleUrls: ['./product-thumbnails.component.scss']
})
export class ProductThumbnailsComponent implements OnInit {

    @Input() images: ImageModel[];
    @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
    @Output() selectionChanged: EventEmitter<ImageModel> = new EventEmitter<ImageModel>();

    @Input() activeImage: ImageModel;

    ngOnInit(): void {
        this.activeImage = this.activeImage || this.images[0];
        this.selectionChanged.emit(this.activeImage);
    }

    onClick(event: ImageModel) {
        if (this.activeImage === event) return;
        this.activeImage = event;
        this.selectionChanged.emit(this.activeImage);
    }

}