import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationBellService } from './services/notification-bell.service';
import { NotificationItem } from './model/notification-item.model';


@Component({
  selector: 'emerson-notification-bell',
  templateUrl: './notification-bell.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./notification-bell.component.scss']
})
export class NotificationBellComponent implements OnInit, OnDestroy {

  notificationItemSubscription: Subscription;

  notificationListItems: NotificationItem[] = [];

  showNotificationList = false;


  notifyBellColor = '#76bd23';
  notifyTitleColor = '#999999';
  notifyBellHoverColor = 'black';
  notifyBellColorStyle = {color: this.notifyBellColor };
  notifyTitleColorStyle = {color: this.notifyTitleColor};

  shouldTextBeShow = true;
  constructor(private notificationBellService: NotificationBellService) {}

  ngOnInit() {
    this.notificationItemSubscription = this.notificationBellService.getAll().subscribe( notificationList => {
      this.notificationListItems = notificationList;
    });
  }

  ngOnDestroy() {
    this.notificationItemSubscription.unsubscribe();
  }


  openNotification(state: boolean) {
    this.showNotificationList = state;
  }

  changeStyle($event){
    this.notifyBellColorStyle = {color: $event.type === 'mouseover' ? this.notifyBellHoverColor : this.notifyBellColor };
    this.notifyTitleColorStyle = {color: $event.type === 'mouseover' ? this.notifyBellHoverColor : this.notifyTitleColor };
  }
}
