import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'ee-product-actions-price',
    templateUrl: './product-actions-price.component.html',
    styleUrls: ['./product-actions-price.component.scss']
})
export class ProductActionsPriceComponent implements OnInit {
    @Input() retailPrice: number;
    @Input() userPrice: number;
    margin: number;
    marginPercentage: number;

    ngOnInit() {
        this.margin = this.retailPrice - this.userPrice;
        this.marginPercentage = ( this.retailPrice - this.userPrice ) / this.retailPrice * 100;
    }
}