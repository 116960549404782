import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';

@Component({
    selector: 'ee-zoomable',
    templateUrl: './zoomable.component.html',
    styleUrls: ['./zoomable.component.less'],
})
export class ZoomableComponent {
    constructor() { }

    @ViewChild('hoverableContentHolder') hoverableContentHolder: ElementRef;
    @ViewChild('zoomedContentHolder') zoomedContentHolder: ElementRef;

    hovering = false;

    zoomedPosition: { top: number, left: number } = { top: 0, left: 0 };
    overlaySize: { width: number, height: number } = { width: 0, height: 0 };
    zoomedRect: { width: number, height: number, top: number, left: number } = { width: 0, height: 0, top: 0, left: 0 };
    frameRect: { width: number, height: number, top: number, left: number };
    fullSize: { width: number, height: number } = { width: 0, height: 0 };

    private static calculate(pos: number, thumbSize: number, largeSize: number, percentage: number) {
        const size = Math.floor(thumbSize * percentage / 100);

        let shift = Math.floor(pos - size / 2);
        if (shift + size > thumbSize) shift = thumbSize - size;
        if (shift < 0) shift = 0;

        const largeShift = -Math.floor( shift / thumbSize * largeSize );

        return { shift, size, largeShift };
    }

    private static getBoundingRectSize(elementRef: ElementRef): { width: number, height: number } {
        elementRef.nativeElement.children[0].style['vertical-align'] = 'top';
        const overlayContentRect = elementRef.nativeElement.getBoundingClientRect();
        return { width: Math.round(overlayContentRect.width), height: Math.round(overlayContentRect.height) };
    }

    onMouseOut() {
        this.hovering = false;
        this.overlaySize = { width: 0, height: 0 };
        this.fullSize = { width: 0, height: 0 };
    }

    onMouseIn(event) {
        this.hovering = true;

        this.overlaySize = ZoomableComponent.getBoundingRectSize(this.hoverableContentHolder);
        this.fullSize = ZoomableComponent.getBoundingRectSize(this.zoomedContentHolder);

        const hoveringRect = event.target.getBoundingClientRect();
        const browserRect = window.document.documentElement.getBoundingClientRect();

        const margin = 20;

        this.zoomedRect = {
            left: hoveringRect.right + margin,
            top: margin,
            width: Math.min(browserRect.width - hoveringRect.right - 2 * margin, this.fullSize.width),
            height: Math.min(browserRect.height - 2 * margin, this.fullSize.height)
        };
    }

    onMouseMove(event) {
        const windowSizePercentageX = 100 * this.zoomedRect.width / this.fullSize.width;
        const windowSizePercentageY = 100 * this.zoomedRect.height / this.fullSize.height;

        const xDimensions = ZoomableComponent.calculate(
                                parseInt(event.offsetX, 10),
                                this.overlaySize.width,
                                this.fullSize.width,
                                windowSizePercentageX);
        const yDimensions = ZoomableComponent.calculate(
                                parseInt(event.offsetY, 10),
                                this.overlaySize.height,
                                this.fullSize.height,
                                windowSizePercentageY);

        this.frameRect = { left: xDimensions.shift, width: xDimensions.size, top: yDimensions.shift, height: yDimensions.size };
        this.zoomedPosition = { left: xDimensions.largeShift, top: yDimensions.largeShift };
    }

    @HostListener('window:resize', [])
    onResize() {
        this.hovering = false;
    }
}