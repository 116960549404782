import {Component, OnInit} from '@angular/core';
import {GoogleAnalyticsService } from '../../../../../core';
import {MegaMenuService} from '../../../mega-menu.service';
import {FeaturedBrand} from '../../../model/featured-brand.model';

@Component({
    selector: 'ee-brand-section',
    templateUrl: './brand-section.component.html',
    styleUrls: ['./brand-section.component.scss']
})
export class BrandSectionComponent implements OnInit{
    public brands: FeaturedBrand[];

    constructor(private ga: GoogleAnalyticsService, private service: MegaMenuService) {
    }

    ngOnInit() {
        this.service.getFeaturedBrands().subscribe((data) => {
            data.sort((s1, s2) => {
                return s1.displayOrder >= s2.displayOrder ? s1.displayOrder > s2.displayOrder ? 1 : 0 : -1;
            });
            this.brands = data;
        });
    }

    public searchBrandUrl(brandName: string, brandId: number) {
        this.gaTrackingBrand(brandName);
        const brandIdUrl = '-B' + brandId.toString();
        const searchBrandUrl = '/shop/' + brandName + brandIdUrl;
        window.location.assign(searchBrandUrl);
    }

    gaTrackingBrand(brandName: string): void{
        this.ga.sendEvent('MegaMenu', 'Brand', brandName, 1);
    }
}
