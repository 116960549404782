import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceClientService } from 'client/angular/core';
import { Agreement, AgreementSignature, AgreementType, CustomerAccountModel } from '../models';


@Injectable({
    providedIn: 'root'
})
export class BrandAgreementsService{
    constructor(private client: ServiceClientService){
    }

    getBrandAgreementList(): Observable<Agreement[]> {
        return this.client.getAsync<Agreement[]>('agreement', `/agreements/${AgreementType.Brand}?excludeBodyMarkdown=false`);
    }

    signBrandAgreement(agreementId: number): Observable<AgreementSignature> {
        return this.client.postAsync('agreement', `/agreements/customerAgreements/${agreementId}/sign`, null);
    }

    sendDocusign(agreementId: number): Observable<AgreementSignature>{
        return this.client.postAsync('agreement', `/docusign/${agreementId}/send`, null);
    }

    resendDocusign(agreementId: number): Observable<AgreementSignature> {
        return this.client.postAsync('agreement', `/docusign/${agreementId}/resend`, null);
    }

    downloadDoocusignFile(agreementId: number): Observable<any> {
        return this.client.getAsync('agreement', `/docusign/${agreementId}/download.pdf`);
    }

    getUserEmail(): Observable<CustomerAccountModel>{
        return this.client.getAsync<CustomerAccountModel>('ares-api', `/account`);
    }

    hardResetDocusign(agreementId: number): Observable<AgreementSignature> {
        return this.client.postAsync('agreement', `/docusign/${agreementId}/hardReset`, null);
    }
}

