<div class="specials-container">
  <div class="specials-header">
    <div class="specials-header-text"><img class="specials-header-logo" alt="logo"
                                           src="/assets/images/EE-leaf-logo.svg">
      <a href="https://go.emersonecologics.com/specials" target="_blank">View All Offers</a></div>
  </div>
  <div class="specials-sub-header" [class]="{'specials-sub-header-mobile': mobileMenu}">Featured Special Offers</div>
  <div class="specials">
    <div [class]="{'specials-column': !mobileMenu, 'vertical-split': !mobileMenu}">
      <div class="special-product-card" [class]="{'special-product-card-mobile': mobileMenu}" ee-google-tag="User clicked on Specials brand - {{product.brandId}}"  (click)="clickHandler(product.brandId)" *ngFor="let product of specialProducts | slice: 0: 2;">
        <div class="special-product-link-container">
          <a [href]="">{{product.offerText}}</a>
        </div>
        <div class="special-product-image-container">
          <img class="special-product-image" alt="special product" [src]="product.offerImage">
        </div>
        <div class="special-coupon-container">
          <p class="coupon-label">Use Coupon Code</p>
          <p class="coupon-code">{{product.offerCouponCode}}</p>
        </div>
      </div>
    </div>
    <div [class]="{'specials-column': !mobileMenu}">
      <div class="special-product-card" [class]="{'special-product-card-mobile': mobileMenu}" ee-google-tag="User clicked on Specials brand - {{product.brandId}}" (click)="clickHandler(product.brandId)" *ngFor="let product of specialProducts | slice: 2: 4;">
        <div class="special-product-link-container">
          <a [href]="">{{product.offerText}}</a>
        </div>
        <div class="special-product-image-container">
          <img class="special-product-image" alt="special product" [src]="product.offerImage">
        </div>
        <div class="special-coupon-container">
          <p class="coupon-label">Use Coupon Code</p>
          <p class="coupon-code">{{product.offerCouponCode}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
