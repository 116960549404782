export enum NotificationStatus {
    New,
    Read
}
export class NotificationItem {
    header: string;
    body: string;
    actionText: string;
    Status: NotificationStatus;

    public action: (NotificationItem) => void;
    executeAction(): void {
        this.action(this);
    }

    equals(item: NotificationItem): boolean {
        return this.header === item.header && this.body === item.body;
    }
}