import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class SeoMetaService {
    private metadetails = {
        'default': {
            'title': 'Emerson Ecologics | The Largest Supplement Dispensary for Practitioners',
            'description': 'Choose from over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice &amp; clients success.'
        },
        'SearchResultsController': {
            'title': 'Search | Emerson Ecologics - Shop',
            'description': 'Search from over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },
        'ReorderProductsListComponent': {
            'title': 'Reorder Products | Emerson Ecologics - Account',
            'description': 'Reorder products that you bought previously while you choose from over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },
        'SupportController': {
            'title': 'Support | Emerson Ecologics',
            'description': 'Request Support from Emerson Ecologics to help you choose from over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },
        'SettingsController': {
            'title': 'Info | Emerson Ecologics - Account',
            'description': 'View your Account Info at Emerson Ecologics where you can choose from over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },
        'BackorderListComponent': {
            'title': 'Backorders | Emerson Ecologics - Account',
            'description': 'View your backorders while you choose from over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },
        'EqpPartnersController': {
            'title': 'EQP Partners | Emerson Ecologics - Quality',
            'description': 'Learn about our EQP Partners - Choose from over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },
        'MainController': {
            'title': 'Home | Emerson Ecologics',
            'description': 'Emerson Ecologics Home - Choose from over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },
        'OrderHistoryController': {
            'title': 'Order History | Emerson Ecologics - Account',
            'description': 'View your Order history while you choose from over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },
        'ShippingAddressListController': {
            'title': 'Shipping Address | Emerson Ecologics - Account',
            'description': 'Manage your shipping addresses while you choose from over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },
        'BrandsController': {
            'title': 'Brands | Emerson Ecologics - Shop',
            'description': 'View all Brands - Over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },
        'BrandAgreementsController': {
            'title': 'Brand Agreements | Emerson Ecologics - Account',
            'description': 'View your Brand Agreements while you choose from over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },
        'CreditCardListFrameController': {
            'title': 'Manage CC | Emerson Ecologics - Account',
            'description': 'Manage your payment methods while you choose from over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },
        'EmailPreferencesController': {
            'title': 'Email Preferences | Emerson Ecologics - Account',
            'description': 'Change your Email at Emerson Ecologics where you can choose from over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },
        'CredentialListController': {
            'title': 'Manage Certifications | Emerson Ecologics - Account',
            'description': 'Manage your Certificates while you choose from over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },
        'SearchWithQuality': {
            'title': 'Search | Emerson Ecologics - Quality Partner',
            'description': 'Search our Gold and Silver partners while you shop for over 300+ professional-grade supplement brands, educational resources, protocols & technology aimed to support your practice & clients success.'
        },

    }
    constructor(private metaService: Meta, private titleService: Title){
    }

    updateSeoMetaData(controller, pageController = null){
        var controllerName;
        if (pageController !== null) {
            controllerName = pageController;
        } else {
            controllerName = controller.constructor.name;
        }
        var titleService = this.titleService;
        var metaService = this.metaService;
        var metadetails = this.metadetails;
        if (controller.$scope) {
            controller.$scope.$on('$destroy', function(){
                titleService.setTitle(metadetails.default.title);
                metaService.updateTag({ name: 'description', content: metadetails.default.description });
            })
        }
        
        if (metadetails[controllerName]) {
            if (metadetails[controllerName].title) {
                titleService.setTitle(metadetails[controllerName].title);
            }
            if (metadetails[controllerName].description) {
                metaService.updateTag({ name: 'description', content: metadetails[controllerName].description });
            }
        }
        
    }
    setDefaultSeoMetaData() {
        this.titleService.setTitle(this.metadetails.default.title);
        this.metaService.updateTag({ name: 'description', content: this.metadetails.default.description });
    }
}
