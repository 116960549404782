import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BackorderItemModel } from '../../../models';
import { ServiceClientErrorModel, GoogleTagDirectiveHost } from 'client/angular/core';
import { BackorderDataService } from '../services/backorder-data/backorder-data.service';

export class BackorderCancellationDialogPresentation {
    title: string;
    body: string;
    primaryButtonText: string;
}

@Component({
    selector: 'ee-backorder-cancellation-dialog',
    templateUrl: './backorder-cancellation-dialog.component.html',
    styleUrls: ['./backorder-cancellation-dialog.component.scss'],
    providers: [{ provide: GoogleTagDirectiveHost, useExisting: forwardRef(() => BackorderCancellationDialogComponent) }]
})
export class BackorderCancellationDialogComponent implements GoogleTagDirectiveHost, OnInit {
    constructor(
        private activeModal: NgbActiveModal,
        private backorderDataService: BackorderDataService) { }

    getGoogleTagData() {
        return {
            componentName: this.viewAlternatesOrCancel === 'cancel' ? 'Back Order Cancellation' : 'Backorder View Alternates Dialog',
            textDetail: this.backorderItem.itemCode,
            numericDetail: this.backorderItem.productId
        };
    }

    @Input()
    viewAlternatesOrCancel: 'view-alternates'|'cancel' = null;

    @Input()
    backorderItem: BackorderItemModel;

    presentation: BackorderCancellationDialogPresentation;

    buttonClicked: 'primary' | 'secondary';
    status: 'done'|'cancelling'|'failed'|'initial' = 'initial';
    errorMessage = '';

    ngOnInit(): void {
        switch (this.viewAlternatesOrCancel){
            case 'cancel':
                this.presentation = {
                    title: 'Back Order Cancellation',
                    body: this.backorderItem.hasAlternatives
                        ? 'Are you sure you want to cancel this item from your back order? You also have the option to cancel this order and view alternative products.'
                        : 'Are you sure you want to cancel this item from your back order?',
                    primaryButtonText: 'Cancel Item'
                };
                break;
            case 'view-alternates':
                this.presentation = {
                    title: 'Reminder',
                    body: 'We process your back-ordered items as soon as they are restocked. You have the option to cancel this back order now before being redirected to alternative products that are available.',
                    primaryButtonText: 'View Alternatives'
                };
                break;
        }
    }

    onDoBoth() {
        this.proceedWithCancel({ shouldNavigateToAlternateOnSuccess: true, buttonClicked: 'secondary' });
    }

    onCancelOrView() {
        switch (this.viewAlternatesOrCancel) {
            case 'cancel':
                this.proceedWithCancel({ shouldNavigateToAlternateOnSuccess: false, buttonClicked: 'primary' });
                break;
            case 'view-alternates':
                this.activeModal.close({ viewAlternates: true, cancelled: false });
                break;
        }
    }

    onDismiss() {
        this.activeModal.dismiss('clickX');
    }

    private proceedWithCancel(options: { shouldNavigateToAlternateOnSuccess: boolean, buttonClicked: 'secondary' | 'primary' }) {
        this.buttonClicked = options.buttonClicked;
        this.status = 'cancelling';
        this.backorderDataService.cancelBackorderItem(this.backorderItem).subscribe(() => {
            this.activeModal.close({ viewAlternates: options.shouldNavigateToAlternateOnSuccess, cancelled: true });
            this.status = 'done';
        }, (error: ServiceClientErrorModel) => {
            this.errorMessage = error.message;
            this.status = 'failed';
        });
    }
}
