import { Component, Input, OnInit } from '@angular/core';
import { NotificationItem, NotificationStatus } from '../model/notification-item.model';
@Component({
  selector: 'emerson-notification-bell-list-item',
  templateUrl: './notification-bell-list-item.component.html',
  styleUrls: ['./notification-bell-list-item.component.scss']
})
export class NotificationBellListItemComponent implements OnInit {

  @Input() notificationItem: NotificationItem;
  constructor() { }

  ngOnInit() {
  }

}
