import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { ImageModel } from '../../models';

@Component({
    selector: 'touch-slide-image',
    templateUrl: './touch-slide-image.component.html',
    styleUrls: ['./touch-slide-image.component.less']
})
export class TouchSlideImageComponent implements AfterViewInit {

    @Input() images: ImageModel[];
    @ViewChild('sliderGroup') sliderGroup: ElementRef;
    @ViewChild('sliderContainer') sliderContainer: ElementRef;
    @ViewChild('sliderPaginationGroup') sliderPaginationGroup: ElementRef;
    private activeSlide = 0;

    constructor() {
    }

    ngAfterViewInit() {
        if (this.images.length > 1){
            const currentActivePagination =
                this.sliderPaginationGroup.nativeElement.querySelector(`#slider-pagination-${this.activeSlide}`);
            currentActivePagination.classList.add('is-active');
        }
    }

    onPan(e) {
        if (!e.isFinal) return;
        if (e.additionalEvent === 'panright') this.slideImage(this.activeSlide - 1);
        if (e.additionalEvent === 'panleft') this.slideImage(this.activeSlide + 1);
    }

    slideImage(index: number) {
        const previousSlideIndex = this.activeSlide;
        this.activeSlide = index;
        if (this.activeSlide < 0) this.activeSlide = 0;
        if (this.activeSlide > this.images.length - 1) this.activeSlide = this.images.length - 1;
        this.sliderGroup.nativeElement.classList.add('is-animating');
        const percentage = -(100 / this.images.length) * this.activeSlide;
        this.sliderGroup.nativeElement.style.transform = 'translateX( ' + percentage + '% )';
        setTimeout(() => {
            this.sliderGroup.nativeElement.classList.remove('is-animating');
        }, 400);

        if (this.images.length > 1){
            const previousActivePagination = this.sliderPaginationGroup.nativeElement.querySelector(`#slider-pagination-${previousSlideIndex}`);
            const activePagination = this.sliderPaginationGroup.nativeElement.querySelector(`#slider-pagination-${this.activeSlide}`);
            previousActivePagination.classList.remove('is-active');
            activePagination.classList.add('is-active');
        }
    }

}
