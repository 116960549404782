import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ServiceClientService } from 'client/angular/core';
import { InvoiceModel } from '../models';

@Injectable({
    providedIn: 'root'
})
export class OrderHistoryService {
    constructor(private client: ServiceClientService) {}

    getInvoice(invoiceNumber: string): Observable<InvoiceModel> {
        return this.client
                   .getAsync<InvoiceModel>('order', `/invoice/${invoiceNumber}`)
                   .pipe(this.treatNullAsErrorRxJsPipe());
    }

    private treatNullAsErrorRxJsPipe<T>() {
        return map<T, T>(value => {
            if (value == null)
                throw { httpStatusCode: 404, code: -1 };
            return value;
        });
    }
}