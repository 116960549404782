import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BackorderItemModel } from 'client/angular/modules/order-history/models';
import { BackorderCancellationDialogComponent } from '../../backorder-cancellation-dialog/backorder-cancellation-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class BackorderCancellationDialogService {
    constructor(private modalService: NgbModal) {}

    openCancelBackorderDialog(item: BackorderItemModel): Promise<{viewAlternates: boolean, cancelled: boolean, item: BackorderItemModel}> {
        return this.openDialog(item, 'cancel');
    }

    openShowSimilarItemsDialog(item: BackorderItemModel): Promise<{viewAlternates: boolean, cancelled: boolean, item: BackorderItemModel}> {
        return this.openDialog(item, 'view-alternates');
    }

    private openDialog(item: BackorderItemModel, viewAlternatesOrCancel: 'view-alternates'|'cancel')
                       : Promise<{ viewAlternates: boolean, cancelled: boolean, item: BackorderItemModel }> {
        const modalRef = this.modalService.open(BackorderCancellationDialogComponent, { centered: true });
        const component = modalRef.componentInstance as BackorderCancellationDialogComponent;
        component.viewAlternatesOrCancel = viewAlternatesOrCancel;
        component.backorderItem = item;

        return modalRef.result.then(result => {
            const viewAlternates = result.viewAlternates;
            const cancelled = result.cancelled;
            return { viewAlternates, item, cancelled };
        });
    }
}
