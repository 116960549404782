import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { ServiceClientService } from '../service-client/service-client.service';

@Injectable({
    providedIn: 'root'
})
export class ProductAlternativesService {

    constructor(private client: ServiceClientService) { }

    getProductIdsWithAlternatives(productIds: number[]): Observable<Set<number>> {
        if ((productIds || []).length === 0) return from([new Set<number>([])]);

        return this.client.postAsync<number[]>(
            'ares-api', '/products/contain-alternatives', productIds)
            .pipe(map(productIdsWithAlternatives => new Set<number>(productIdsWithAlternatives)));
    }

    getProductIdsWithAlternativesAsPromise(productIds: number[]): Promise<Set<number>> {
        return this.getProductIdsWithAlternatives(productIds).toPromise();
    }
}
