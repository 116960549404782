import { Component } from '@angular/core';
import { ToastService, Toast } from '../../services';

@Component({
    selector: 'ee-toasts',
    templateUrl: './toasts.component.html',
    styleUrls: ['./toasts.component.less'],
})
export class ToastsComponent {
    constructor(private toastService: ToastService) {}

    toasts() {
        return this.toastService.toasts;
    }

    remove(toast: Toast) {
        this.toastService.remove(toast);
    }
}