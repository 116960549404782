import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, forwardRef } from '@angular/core';

import { ToastService, GoogleTagDirectiveHost } from 'client/angular/core';
import { BackInStockDialogService, ProductDetailsService } from '../../services';
import { ItemModel } from '../../models';

@Component({
    selector: 'ee-product-actions-back-in-stock',
    templateUrl: './product-actions-back-in-stock.component.html',
    styleUrls: ['./product-actions-back-in-stock.component.scss'],
    providers: [{ provide: GoogleTagDirectiveHost, useExisting: forwardRef(() => ProductActionsBackInStockComponent)}]
})
export class ProductActionsBackInStockComponent implements OnInit, OnDestroy, GoogleTagDirectiveHost {
    constructor(private dialog: BackInStockDialogService,
                private service: ProductDetailsService,
                private toast: ToastService) { }

    getGoogleTagData() {
        return {
            componentName: 'Customer - Notify Me',
            textDetail: this.item.itemCode,
            numericDetail: 0
        };
    }

    @Input() item: ItemModel;
    @Input() outOfStockMessage: string;
    @Input() similarProductsExist: boolean;
    @Output() scrollToSimilarProducts = new EventEmitter<any>();

    dismissDialog: { destroy: () => void; };
    pendingSubscriptionCheck = false;
    subscribed = false;
    hasSubscriptionCheckError = false;
    unsubscribing = false;

    ngOnInit() {
        this.pendingSubscriptionCheck = true;
        this.service
            .subscribedToBackInStock(this.item.itemCode)
            .subscribe(subscribed => {
                this.pendingSubscriptionCheck = false;
                this.subscribed = subscribed;
            }, () => {
                this.pendingSubscriptionCheck = false;
                this.hasSubscriptionCheckError = true;
            });
    }

    onSubscribe() {
        const result = this.dialog.openDialog(
            this.item,
            this.outOfStockMessage,
            this.similarProductsExist);

        result.callback.then(action => {
            this.subscribed = action.subscribed;
            if (action.scrollToSimilarProducts)
                this.scrollToSimilarProducts.emit({});
        });

        this.dismissDialog = result;
    }

    onUnsubscribe() {
        this.unsubscribing = true;
        this.service
            .unsubscribeFromBackInStock(this.item.itemCode)
            .subscribe(() => {
                this.subscribed = false;
                this.toast.showSuccessText('Successfully unsubscribed.');
                this.unsubscribing = false;
            }, () => {
                this.toast.showFailedText('Unexpected error occurred, please try again later.');
                this.unsubscribing = false;
            });
    }

    ngOnDestroy(): void {
        (this.dismissDialog || { destroy: () => {} }).destroy();
    }
}
