import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WindowProvider {
    getWindow(): Window {
        return window;
    }
}

@Injectable({
    providedIn: 'root'
})
export class BrowserFeatureDetectorService {
    constructor(private windowProvider: WindowProvider) {}

    hasTouchScreen() {
        this.cachedValue = this.cachedValue || {
            hasTouchScreen: this.calculateHasTouchScreen()
        };

        return this.cachedValue.hasTouchScreen;
    }

    private getWindow(): Window {
        return this.windowProvider.getWindow();
    }

    private cachedValue: { hasTouchScreen: boolean } = null;

    private calculateHasTouchScreen(): boolean {
        // NOTE: in EDGE we have maxTouchPoints == 2, therefore, we will just skip all the IE and EDGE stuff
        if (
                (this.getWindow().document as any).documentMode != null ||
                /Edge/.test(this.getWindow().navigator.userAgent) ||
                /Edg/.test(this.getWindow().navigator.userAgent)
           )
           return false;

        // NOTE: borrowed idea from here: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
        const nav = this.getWindow().navigator as any;
        if ('maxTouchPoints' in nav)
            return nav.maxTouchPoints > 0;
        if ('msMaxTouchPoints' in nav)
            return nav.msMaxTouchPoints > 0;

        const mQ = this.getWindow().matchMedia && this.getWindow().matchMedia('(pointer:coarse)');
        if (mQ && mQ.media === '(pointer:coarse)')
            return !!mQ.matches;
        if ('orientation' in this.getWindow())
            return true;

        return false;
    }
}