<div *ngIf="banner != null" class="banner-holder">
    <div class="description">
        <div>Below is a preview of how landing page will look like when it is published in Contentful. You can change your browser width to see how the banner switches between desktop \ tablet and mobile presentation state.</div>
    </div>
    <ee-banner [banner]="banner" (clicked)="onClick()"></ee-banner>
</div>

<div *ngIf="banner == null && error == null" class="waiting-holder">
    <waiting-spinner size="45"></waiting-spinner>
    <div>Loading data from contentful...</div>
</div>

<div *ngIf="error != null" class="error">
    {{error}}
</div>