import { Component, OnInit, Input, ViewEncapsulation, AfterViewInit, ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'ee-safe-html',
    templateUrl: './safe-html.component.html',
    styleUrls: ['./safe-html.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SafeHtmlComponent implements OnInit, AfterViewInit {
    constructor() { }

    @ViewChild('myDiv') elementRef: ElementRef<HTMLDivElement>;

    @Input() html: string;
    @Input() spacing: '5' | '10' | '20';

    getSpacingStyle() {
        return this.spacing == null
               ? SafeHtmlComponent.defaultHostClass
               : `${SafeHtmlComponent.defaultHostClass} ee-safe-html-spacing-${this.spacing}`;
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        const nativeElement = this.elementRef.nativeElement;
        const toRemove = SafeHtmlComponent.findEmptyDomElements(nativeElement);

        toRemove.forEach(item => {
            if (item.remove) {
                item.element.remove();
            }
            else {
                const spacer = document.createElement('div');
                spacer.className = 'spacer';
                nativeElement.replaceChild(spacer, item.element);
            }
        });
    }

    private static findEmptyDomElements(nativeElement: HTMLDivElement) {
        const elements = nativeElement.childNodes;
        const toRemove = [];
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i] as any;
            const text = element.innerText || element.textContent || '';
            if (!(/\S/.test(text))) {
                toRemove.push({ element, remove: false, index: i });
            }
        }

        // mark as to remove in beginning
        for (let i = 0; toRemove.length > i && toRemove[i].index === i; i++) {
            toRemove[i].remove = true;
        }

        // mark as to remove at the end
        for (let i = toRemove.length - 1, j = elements.length - 1; i >= 0 && toRemove[i].index === j; i--, j--) {
            toRemove[i].remove = true;
        }

        return toRemove.map(item => ({ element: item.element, remove: item.remove }));
    }

    private static defaultHostClass = 'ee-safe-html';
}
