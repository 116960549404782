<div class="layout-row layout-wrap list-header">
    <h2 class="flex-100 flex-gt-sm-50">Back Orders</h2>

    <div class="flex-100 flex-gt-sm-25" *ngIf="backorderItems != null && totalNumberOfItems() > 1">
        <div class="sort-dropdown-holder">
            <sort-dropdown [sortOption]="sortOption" (sortOptionChange)="onSelectSort($event)" [disabled]="backorderItems.length <= 1" [items]="BackorderSortTypes"></sort-dropdown>
        </div>
    </div>

    <div class="flex-100 flex-gt-sm-25 filter-text-holder" *ngIf="backorderItems != null && totalNumberOfItems() > 1">
        <input
            type="text"
            placeholder="Find Item"
            [(ngModel)]="filterSubstring"
            (ngModelChange)="onFilterSubstringChanged()" />
        <div
            class="filtered-info"
            *ngIf="(filterSubstring||'').length > 0">showing {{backorderItems.length | number}} out of {{totalNumberOfItems() | number}}</div>
    </div>
</div>

<div *ngIf="status == 'fetched'" class="list-holder">
    <backorder-card
        *ngFor="let item of backorderItems | slice:10*(currentPage-1):10*(currentPage)"
        [backorderItem]="item"
        (viewAlternates)="onViewAlternates($event)"
        (cancelBackorder)="onCancelBackorder($event)"
        (redirectToPdp)="onRedirectToPdp($event)"
        ></backorder-card>

    <div *ngIf="backorderItems.length > 10" class="pagination-holder">
        <ngb-pagination
            class="d-flex justify-content-end"
            [collectionSize]="backorderItems.length"
            [(page)]="currentPage"
            [pageSize]="10"
            [maxSize]="5"
            [rotate]="true">
        </ngb-pagination>
    </div>

    <div *ngIf="backorderItems.length == 0" class="panel no-items">
        {{ totalNumberOfItems() == 0 ? 'There are no items on back order.' : 'There are no back order items matching your filter criteria.' }}
    </div>
</div>

<div *ngIf="status == 'loading'" class="panel loading">
    <waiting-spinner size="45"></waiting-spinner>
    <div>Loading...</div>
</div>

<div *ngIf="status == 'failed'" class="panel failed">
    {{errorMessage}}
</div>
