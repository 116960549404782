import { Component, OnInit } from '@angular/core';
import { Category } from '../../model/category.model';
import { MegaMenuService } from '../../mega-menu.service';
import {FeaturedProduct} from '../../model/featured-product.model';
import { AccountModel, AccountService, GoogleAnalyticsService } from '../../../../core';

@Component({
    selector: 'ee-mega-menu',
    templateUrl: './mega-menu.component.html',
    styleUrls: ['./mega-menu.component.scss']
})
export class MegaMenuComponent implements OnInit{

    public showMegaMenu: boolean;
    public accountInfo: AccountModel = null;

    public categories: Category[];
    public newProducts: FeaturedProduct[];
    public hasWideColumns: boolean;
    public menuHeight: number;

    public activeSection: string;
    public categoryDisplayRank = {
        'Nutritional Supplements': 10,
        'Vitamins & Minerals': 20,
        'Herbs & Acupuncture': 30,
        Homeopathy: 40,
        'Lab Tests & Medical Supplies': 50,
        'Personal Care & Healthy Living': 60,
        'Animal Health': 70
    };

    private sectionColumnWidthMap = {
        brand: false,
        newProducts: true,
        specials: true,
        learningResources: false
    };

    constructor(private service: MegaMenuService,
                private ga: GoogleAnalyticsService, private accountService: AccountService) {
        this.showMegaMenu = true;
    }

    setActiveCategory(category: Category): void {
        this.activeSection = category.category;
        this.hasWideColumns = category.subCategory.length > 18;
    }

    setActiveSection(section: string): void {
        this.hasWideColumns = this.sectionColumnWidthMap[section];
        this.activeSection = section;
    }

    ngOnInit(): void {
        this.service.getCategories().subscribe(
            data => {
                data.sort((s1, s2) => {
                    const v1: number = (s1.category in this.categoryDisplayRank) ? this.categoryDisplayRank[s1.category] : 1000;
                    const v2: number = (s2.category in this.categoryDisplayRank) ? this.categoryDisplayRank[s2.category] : 1000;
                    return v1 >= v2 ? v1 === v2 ? 0 : 1 : -1;
                });
                this.categories = data;
                this.hasWideColumns = this.categories[0].subCategory.length > 18;
                this.menuHeight = 60 * (this.categories.length + 4);
                this.categories.forEach((category) => {
                    category.subCategory.sort((sub1, sub2) => {
                        return sub1.subCategory >= sub2.subCategory ? sub1.subCategory === sub2.subCategory ? 0 : 1 : -1;
                    });
                });
            },
            error => this.menuHeight = 480
        );

        this.service.getFeaturedProducts().subscribe(
            data => {
                this.newProducts = data;
                this.newProducts.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
            }
        );

        this.accountService.getCachedAccountInfo().then(accountInfo => {
            this.accountInfo = accountInfo;
        }).catch(error => {
            this.accountInfo = null;
        });
    }

    urlStrip(taxonomyName: string): string{
        if (!taxonomyName) return '';
        return taxonomyName.replace(/[^\w\d\s]*/g, '').replace(/\s+/g, '-');
    }

    gaTrackingMegaMenu(action: string, label: string): void{
        this.ga.sendEvent('MegaMenu', action, label, 1);
    }

}
