import {Injectable} from '@angular/core';
import {Observable, forkJoin, from} from 'rxjs';
import {shareReplay, catchError, map} from 'rxjs/operators';

import {
  ServiceClientService,
  CartChangesService,
  AddressModel,
  AccountService,
  GoogleAnalyticsService
} from 'client/angular/core';

import {ProductDetailsModel, CartModel, CartSummaryModel, ItemModel} from '../../models';
import {AccountModel} from 'client/angular/core/models/';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailsService {
  private productDetailsCartEvents: any = {
    productAddToCart: {
      event: 'productAddToCart',
      customerType: 'practitioner',
      practitionerId: '',
      platform: '',
      ecommerce: {
        items: []
      }
    }
  };

  constructor(private client: ServiceClientService,
              private cartChanges: CartChangesService,
              private accountService: AccountService,
              private analyticsService: GoogleAnalyticsService) {

  }

  getProductDetails(productId: number, itemCode: string): Observable<ProductDetailsModel> {
    return forkJoin({
      details: this.client.getAsync<ProductDetailsModel>('ares-api', `/products/${productId}`),
      address: this.client
        .getAsync<AddressModel>('ares-api', '/account/address/default')
        .pipe(catchError(() => from([null as AddressModel])))
    }).pipe(
      map(vars => {
        const isCaliforniaAddress = vars.address != null &&
          vars.address.countryCode === 'US' &&
          vars.address.regionCode === 'CA';

        const CaOnly = 2;
        const NoCaOnly = 3;

        const preferredItems = vars.details.items.filter(it => {
          if (it.itemCode === itemCode)
            return true;
          if (isCaliforniaAddress)
            return it.prop65Restriction !== NoCaOnly;
          else
            return it.prop65Restriction !== CaOnly;
        });

        vars.details.description = vars.details.description || '';
        vars.details.items = preferredItems;

        return vars.details;
      })
    );
  }

  getCart(): Observable<CartModel> {
    const cartId = this.cartChanges.getCurrentCartId();
    const relativePath = cartId != null ? `/cart?cartId=${cartId}` : `/cart`;
    return this.client.getAsync<CartModel>('ares-api', relativePath);
  }

  getAccountInfo(): Observable<AccountModel> {
    return from(this.accountService.getCachedAccountInfo());
  }

  addItemToCurrentCart(itemCode: string, quantity: number, item?: ItemModel): Observable<CartSummaryModel> {
    const cartId = this.cartChanges.getCurrentCartId();
    const relativePath = cartId != null ? `/cart?cartId=${cartId}` : `/cart`;
    this.accountService.getCachedAccountInfo().then((account) => {
      this.analyticsService.sendGTMEvent(this.productDetailsCartEvents.productAddToCart, {
        // @ts-ignore
        practitionerId: account.customerId,
        ecommerce: {
          items: [{
            item: item.itemName,
            item_id: item.itemCode,
            price: item.userPrice,
            item_brand: item.productDetails.brandName,
            // @ts-ignore
            item_category: item.productDetails.category
          }]
        }
      });
    });
    return this.client.postAsync<CartSummaryModel>('ares-api', relativePath, {itemId: itemCode, quantity});
  }

  subscribeToBackInStock(itemCode: string): Observable<boolean> {
    return this.client.postAsync<boolean>('ares-api', `/products/${itemCode}/restock`, {});
  }

  subscribedToBackInStock(itemCode: string): Observable<boolean> {
    return this.client.getAsync<boolean>('ares-api', `/products/${itemCode}/restock`);
  }

  unsubscribeFromBackInStock(itemCode: string): Observable<boolean> {
    return this.client.deleteAsync<boolean>('ares-api', `/products/${itemCode}/restock`);
  }

  getAccountResult: Observable<AccountModel>;
}
