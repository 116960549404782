import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'waiting-spinner',
    templateUrl: './waiting-spinner.component.html',
    styleUrls: ['./waiting-spinner.component.less']
})
export class WaitingSpinnerComponent implements OnInit {
    @Input() size: number;
    @Input() color = '#4399c5';

    ngOnInit(): void {
        this.size = this.size > 0 ? this.size : 45;
    }
}