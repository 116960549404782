import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';

import { PdfLibraryService } from '../../services';

@Component({
    selector: 'ee-product-image',
    templateUrl: './product-image.component.html',
    styleUrls: ['./product-image.component.less']
})
export class ProductImageComponent implements OnInit, OnChanges {
    constructor(private pdfService: PdfLibraryService) { }

    @Input() width: string;
    @Input() height: string;
    @Input() src: string;
    @Input() alt: string;
    @Input() fit = false;
    @Output() productImageError = new EventEmitter<any>();

    @ViewChild('pdfCanvas') pdfCanvas: ElementRef;

    pdfLoading = false;
    displayError = false;

    ngOnInit(): void {
        this.tryRefetchPdf();
    }

    ngOnChanges(changes: SimpleChanges) {
        const change = changes.src || {} as SimpleChange;
        if (change.currentValue === change.previousValue)
            return;

        this.tryRefetchPdf();
    }

    onError(event) {
        this.productImageError.emit(event);
        this.displayError = true;
    }

    isPdf() {
        return ProductImageComponent.getExtension(this.src) === 'pdf';
    }

    isImage() {
        return !this.isPdf();
    }

    private tryRefetchPdf() {
        this.displayError = false;
        if (this.pdfLoading) return;
        if (this.isPdf()) this.startLoadingPdf();
    }

    private async startLoadingPdf() {
        const currentPdfSorce = this.src;

        try {
            this.pdfLoading = true;
            const renderer = await this.pdfService.getFirstPageRenderer(currentPdfSorce);
            if (currentPdfSorce === this.src) {
                const renderPosition = this.fit ? {
                    width: 1000,
                    height: 1000
                } : {
                    width: parseInt(this.width, 10),
                    height: parseInt(this.height, 10)
                };
                await renderer.render(this.pdfCanvas.nativeElement as HTMLCanvasElement, renderPosition);
            }
        } catch (e) {
            this.onError({error: e});
        }

        this.pdfLoading = false;

        if (currentPdfSorce !== this.src)
            this.tryRefetchPdf();
    }

    private static getExtension(url: string) {
        if ((url || '') === '') return '';
        const fileName = url.split('/').pop();
        const splitted = fileName.split('.');
        return splitted.length > 1 ? splitted[splitted.length - 1].toLowerCase() : '';
    }
}