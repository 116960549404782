import { Injectable } from '@angular/core';

export interface ILocalStorageService {
    save(key: string, value: any): void;
    get(key: string, defaultValue?: any): any;
}

@Injectable({
    providedIn: 'root'
})
export class LocalStorageFactoryService {
    toComponentService(componentName: string): ILocalStorageService {
        return {
            save: (key, value) => this.save(componentName, key, value),
            get: (key, defaultValue) => this.get(componentName, key, defaultValue)
        };
    }

    private save(componentName: string, key: string, value: any) {
        window.localStorage.setItem(`${key}@${componentName}`, JSON.stringify(value));
    }

    private get(componentName: string, key: string, defaultValue: any): any {
        const stringValue = window.localStorage.getItem(`${key}@${componentName}`);
        if (stringValue == null) return defaultValue;
        return JSON.parse(stringValue);
    }
}