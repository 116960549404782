import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { NotificationItem, NotificationStatus } from '../model/notification-item.model';

@Injectable({
    providedIn: 'root'
})


export class NotificationBellService {

    private items: any[] = [];

    private notificationListChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(this.items);

    constructor() {
        this.notificationListChange.subscribe((value) => {
            this.items = value;
        });
    }

    add(item: any) {
        if (!this.isNotificationOnList(item)) {
            this.items.push(item);
            this.notificationListChange.next(this.items);
        }
    }

    isNotificationOnList(item: NotificationItem): boolean {
        return this.items.filter(nItem => nItem.equals(item)).length > 0;
    }

    get(item: any): any {

        const res = this.items.filter(nItem => nItem.equals(item));

        if (res.length > 0) {
           return res[0];
        }
        return null;
    }

    getAll(): Observable<any[]> {
        return this.notificationListChange.asObservable();
    }

    remove(item: any){
        this.items = this.items.filter(nItem => !nItem.equals(item));
        this.notificationListChange.next(this.items);
    }

    removeAll(){
        this.items = [];
        this.notificationListChange.next(this.items);
    }

    markAsRead(item: any) {
        const index = this.items.indexOf(item);
        if ( index >= 0) {
            item.Status = NotificationStatus.Read;
            this.items[index] = item;
            this.notificationListChange.next(this.items);
        }
    }
}
