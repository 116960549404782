<div *ngIf="invoice != null" class="invoice">

    <div class="print-button-holder">
        <span class="order-print" onclick="window.print();">
            <span class="material-icons">print</span>
            <span class="text">Print Invoice</span>
        </span>
    </div>

    <div class="invoice-header">
        <div class="logo">
            <img src="./../../../../../assets/images/Emerson_Logo_AFSC-01-1926x567.png" width="146" height="44" alt="emerson logo" />
        </div>
        <div class="emerson-info">
            <div>Emerson Ecologics, LLC.</div>
            <div>1750 Elm St</div>
            <div>Suite 1200</div>
            <div>Manchester NH 03104</div>
            <div>1-800-654-4432</div>
        </div>
        <div class="general-info">
            <div>Invoice: #{{invoice.invoiceNumber}}</div>
            <div>Order: #{{invoice.fulfillmentId}}</div>
            <div *ngIf="invoice.poNumber">Purchase Order #: {{invoice.poNumber}}</div>
            <div>Invoice date: {{invoice.invoiceDate | date : 'MM/dd/y'}}</div>
            <div>Customer #: {{invoice.customerId}}</div>
        </div>
    </div>

    <div class="two-columns">
        <div class="block-with-header">
            <div class="title">Sold to</div>
            <div class="content">
                <div>{{invoice.soldToAddress | address:AddressPipeType.Recipient}}</div>
                <div>{{invoice.soldToAddress | address:AddressPipeType.Street}}</div>
                <div>{{invoice.soldToAddress | address:AddressPipeType.City}}</div>
            </div>
        </div>
        <div class="block-with-header">
            <div class="title">Ship to</div>
            <div class="content">
                <div>{{invoice.shipToAddress | address:AddressPipeType.Recipient}}</div>
                <div>{{invoice.shipToAddress | address:AddressPipeType.Street}}</div>
                <div>{{invoice.shipToAddress | address:AddressPipeType.City}}</div>
            </div>
        </div>
    </div>

    <div class="two-columns">
        <div class="block-with-header">
            <div class="title">Payment Method</div>
            <div class="content">{{invoice.paymentMethod}}</div>
        </div>

    </div>

    <div class="items">
        <table>
            <tr>
                <th>Brand</th>
                <th>Item</th>
                <th>Description</th>
                <th>Ordered</th>
                <th>Shipped</th>
                <th>MSRP</th>
                <th>Unit Price</th>
                <th>Amount</th>
            </tr>
            <tr *ngFor="let item of invoice.lineItems">
                <td>{{item.brandCode}}</td>
                <td>{{item.itemCode}}</td>
                <td>{{item.description}}</td>
                <td>{{item.quantityOrdered | number}}</td>
                <td>{{item.quantityShipped | number}}</td>
                <td>{{item.retailPrice | currency}}</td>
                <td>{{item.unitPrice | currency}}</td>
                <td>{{item.lineTotal | currency}}</td>
            </tr>
        </table>
    </div>

    <div class="totals">
        <div>
            <div>Subtotal</div>
            <div>Freight</div>
            <div>Sales Tax</div>
            <div>Trade Discount</div>
            <div>Additional Charges</div>
            <div class="bold">Total</div>
        </div>
        <div>
            <div>{{invoice.subtotal || 0 | currency}}</div>
            <div>{{invoice.shippingCost || 0 | currency}}</div>
            <div>{{invoice.salesTax || 0 | currency}}</div>
            <div>{{invoice.discountAmount || 0 | currency}}</div>
            <div>{{invoice.additionalCharges || 0 | currency}}</div>
            <div class="bold">{{invoice.total || 0 | currency}}</div>
        </div>
    </div>

    <div class="health-related-info">
        <span>Health Related Information.</span> The information and contents provided by Emerson Ecologics are for informational purposes only and are not meant to substitute for the advice provided by your doctor or other health care professionals.  You should not use the information available from Emerson Ecologics (including, but not limited to, information that may be provided on the Emerson Ecologics Web Site, printed materials or verbally by healthcare or service professionals employed by or contracting with Emerson Ecologics) for diagnosing or treating a health problem or disease, or prescribing any medication.  Information and statements regarding dietary and other health care supplements have not been evaluated by the Food and Drug Administration and are not intended to diagnoze, treat, cure or prevent any disease.  You should read carefully all product packaging prior to use and consult with your health care professionals.
    </div>

    <div class="divider">
        Terms Customers - Please cut along line and use this as your payment stub
    </div>

    <div class="two-columns terms-customers">
        <div class="address-panels">
            <div>
                <div>From:</div>
                <div>{{invoice.soldToAddress | address:AddressPipeType.Recipient}}</div>
                <div>{{invoice.soldToAddress | address:AddressPipeType.Street}}</div>
                <div>{{invoice.soldToAddress | address:AddressPipeType.City}}</div>
            </div>
            <div>
                <div>To:</div>
                <div>{{invoice.shipToAddress | address:AddressPipeType.Recipient}}</div>
                <div>{{invoice.shipToAddress | address:AddressPipeType.Street}}</div>
                <div>{{invoice.shipToAddress | address:AddressPipeType.City}}</div>
            </div>
        </div>
        <div class="blocks-panels">
            <div class="block-with-header">
                <div class="title">Customer Number</div>
                <div class="content">{{invoice.customerId}}</div>
            </div>
            <div class="block-with-header">
                <div class="title">Invoice Number</div>
                <div class="content">{{invoice.invoiceNumber}}</div>
            </div>
            <div class="block-with-header">
                <div class="title">Shipment Total</div>
                <div class="content">{{invoice.total | currency }}</div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="invoice == null && status != null" class="invoice">
    <div *ngIf="status == PageStatus.Waiting" class="centered-message">
        Waiting for invoice data, please, be patient...
    </div>
    <div *ngIf="status == PageStatus.Custom" class="centered-message">
        {{errorMessage}}
    </div>
    <div *ngIf="status == PageStatus.Unauthorized" class="centered-message">
        You are not authorized to view the selected invoice data.
    </div>
    <div *ngIf="status == PageStatus.Unexpected" class="centered-message">
        Unexpected network error occurred, please, <a (click)="requestData()">try again</a> later.
    </div>
</div>
