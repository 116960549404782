import { Injectable } from '@angular/core';
import { ServiceClientService } from 'client/angular/core/services/service-client/service-client.service';
import { AccountModel } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class AccountService{
    private accountInfo: Promise<AccountModel>;
    constructor(private client: ServiceClientService){
    }

    async getAccountInfo(): Promise<AccountModel> {
        const isAuthenticated = await this.client.isAuthenticated();
        if (isAuthenticated) {
            return await this.client.getAsync<AccountModel>('ares-api', '/account').toPromise();
        } else {
            return Promise.reject('user not login');
        }
    }

    async getCachedAccountInfo(): Promise<AccountModel> {
        const isAuthenticated = await this.client.isAuthenticated();
        if (isAuthenticated) {
            this.accountInfo =
                this.accountInfo ||
                this.client.getAsync<AccountModel>('ares-api', '/account').toPromise();
        }
        return await this.accountInfo;
    }
}
