<div class="new-products-container">
    <div class="new-products-header">
        <div class="new-products-header-text"><a href="/shop/search?ff=1&fft=new&term=*&new=1"><img class="new-products-header-logo" alt="logo" src="/assets/images/EE-leaf-logo.svg">
            Shop All New Products</a> </div>
    </div>
    <div class="new-products-sub-header">Featured New Products</div>
    <div class="new-products">
        <div [class]="{'new-products-column': true, 'vertical-split': i % 2 === 0}" *ngFor="let product of newProducts | slice: 0: 4; let i = index">
            <ul [class]="{'new-product-card': true, 'horizontal-split': i < 2}">
                <li class="new-product-link-container">
                    <a (click)="gaTrackingNewProduct(product)" href="/products/detail/{{product.attributeName | urlParameter}}/{{product.productName | urlParameter}}/{{product.productId}}/{{product.itemId}}">{{product.productName}}</a>
                </li>
                <li class="new-product-image-container">
                    <a (click)="gaTrackingNewProduct(product)" href="/products/detail/{{product.attributeName | urlParameter}}/{{product.productName | urlParameter}}/{{product.productId}}/{{product.itemId}}">
                        <img class="new-product-image" [alt]="product.itemName" [src]="product.itemImageUrl">
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

