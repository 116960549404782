import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../core';
import { MegaMenuComponent } from './component/mega-menu/mega-menu.component';
import { NewProductSectionComponent } from './component/mega-menu/new-product-section/new-product-section.component';
import { BrandSectionComponent } from './component/mega-menu/brand-section/brand-section.component';
import { MobileAccordionMenuComponent } from './component/mobile-accordion-menu/mobile-accordion-menu.component';
import { LearningResourceSectionComponent } from './component/mega-menu/learning-resource-section/learning-resource-section.component';
import { MegaMenuService } from './mega-menu.service';
import { SpecialsSectionComponent } from './component/mega-menu/specials-section/specials-section.component';
import { ReorderProductsSectionComponent } from './component/mega-menu/reorder-products-section/reorder-products-section.component';

@NgModule({
    declarations: [
        MegaMenuComponent,
        NewProductSectionComponent,
        MobileAccordionMenuComponent,
        SpecialsSectionComponent,
        BrandSectionComponent,
        LearningResourceSectionComponent,
        ReorderProductsSectionComponent,
        MobileAccordionMenuComponent
    ],
    entryComponents: [
    ],
    imports: [
        BrowserModule,
        CommonModule,
        HttpClientModule,
        NgbModule,
        CoreModule
    ],
    providers: [ MegaMenuService ]
})
export class MegaMenuModule { }
