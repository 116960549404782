import { Injectable } from '@angular/core';

export type Toast = { body: string, failed: boolean };

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    toasts: Toast[] = [];

    showSuccessText(body: string) {
        this.toasts.push({ body, failed: false });
    }

    showFailedText(body: string) {
        this.toasts.push({ body, failed: true });
    }

    remove(toast: Toast) {
        this.toasts = this.toasts.filter(t => t !== toast);
    }
}