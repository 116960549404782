import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';

@Component({
    selector: 'ee-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

    constructor(private hostElement: ElementRef) { }

    @Input() styling: 'orange' | 'green' | 'outline' | 'link' = 'orange';
    @Output() clicked = new EventEmitter<any>();
    @Input() waiting = false;
    @Input() disabled = false;

    awaitingForFirstFocus = false;
    @ViewChild('button') buttonRef: ElementRef;
    @HostListener('focus') onHolderFocus() {
        this.stopInitialFocus();
        this.buttonRef.nativeElement.focus();
    }

    ngOnInit(): void {
        // note: in order to allow autofocus in dialogs we need to perform a workaround trick
        //       ee-button element should be focusable, so that NgbModal can say element.focus
        //       therefore, we are setting tabindex to 0 if we detect that button is autofocusable
        //       once the focus call has been detected, we will set tabindex to -1 so that we keep working as always
        const hasNgbAutofocus = (this.hostElement.nativeElement as HTMLElement).hasAttribute('ngbautofocus');
        if (hasNgbAutofocus) {
            (this.hostElement.nativeElement as HTMLElement).setAttribute('tabindex', '0');
            this.awaitingForFirstFocus = true;
        }
    }

    onButtonFocus() {
        this.stopInitialFocus();
    }

    getClass() {
        return {
            'btn-primary': this.styling === 'orange',
            'btn-secondary': this.styling === 'green',
            'btn-outline-primary': this.styling === 'outline' || this.styling === 'link',
            'btn-outline-transparent-border': this.styling === 'link'
        };
    }

    onClick() {
        this.clicked.emit({});
    }

    private stopInitialFocus() {
        if (this.awaitingForFirstFocus) {
            // note: timeout is needed to avoid unnecessary exception from Angular
            // setTimeout(() => { this.tabindex = -1; }, 0);
            // this.tabindex = -1;
            this.awaitingForFirstFocus = false;
            (this.hostElement.nativeElement as HTMLElement).removeAttribute('tabindex');
        }
    }
}
