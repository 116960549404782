import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { ImageModel } from '../../models';
import { ServiceClientService, ConfigurationService } from 'client/angular/core';

export type AresApiImageType = {
    imageGuid: string,
    extension: string,
    itemCode: string
};

export type AresApiSizeType = {
    ProductDetail: string,
    ProductDetailThumb: string,
    ProductZoom: string
};

export type AresApiImagesResponseType = {
    images: AresApiImageType[],
    imageSizes: AresApiSizeType
};

@Injectable({
    providedIn: 'root'
})
export class ImagesService {
    constructor(private client: ServiceClientService, private config: ConfigurationService) {}

    getItemImages(productId: number, itemCode: string): Observable<ImageModel[]> {
        return forkJoin({
            imagesResponse: this.client.getAsync<AresApiImagesResponseType>('ares-api', `/products/getImages/${productId}`),
            config: this.config.getConfiguration()
        }).pipe(
            map(vars => {
                const imageSizes = vars.imagesResponse.imageSizes;
                const staticResourceUrlPrefix = vars.config.staticResourceUrlPrefix;
                const responseImages = vars.imagesResponse.images || [];

                // note: just in case we don`t have itemCode specified
                if ((itemCode || '') === '' )
                    itemCode = responseImages.length > 0 ? responseImages[0].itemCode : '';
                const images = responseImages.filter(im => (im.itemCode || '').toLowerCase() === (itemCode || '').toLowerCase());

                images.forEach(im => { im.extension = im.extension || 'png'; });

                return images.map(im => {
                    let detail = `${staticResourceUrlPrefix}${im.imageGuid}-${imageSizes.ProductDetail.replace(', ', '-')}.${im.extension}`;
                    let thumb = `${staticResourceUrlPrefix}${im.imageGuid}-${imageSizes.ProductDetailThumb.replace(', ', '-')}.${im.extension}`;
                    let zoom = `${staticResourceUrlPrefix}${im.imageGuid}-${imageSizes.ProductZoom.replace(', ', '-')}.${im.extension}`;

                    if (im.extension === 'pdf') {
                        detail = zoom = `${staticResourceUrlPrefix}${im.imageGuid}.pdf`;
                        thumb = `${staticResourceUrlPrefix}${im.imageGuid}-${imageSizes.ProductDetailThumb.replace(', ', '-')}.png`;
                    }

                    return { detail, thumb, zoom };
                });
            })
        );
    }
}
