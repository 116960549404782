import { Injectable } from '@angular/core';

import { AddressModel } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class AddressService {
    private usAndTerritories: string[] = ['AS', 'GU', 'MP', 'PR', 'US', 'USA', 'VI'];

    public isDomesticAddress(address: AddressModel) {
        const countryCodeUpperCase = ((address || {}).countryCode || '').toUpperCase();
        const countryCodeInList = this.usAndTerritories.indexOf(countryCodeUpperCase) >= 0;
        return countryCodeInList;
    }
}