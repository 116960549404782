<div class="dialog">

    <a class="cancel" (click)="onDismiss()">X</a>
    <div class="thumb">
        <product-thumbnail [images]="images" [activeImage]="activeImage" (selectionChanged)="onSelectionChanged($event)"
            [orientation]="'horizontal'"></product-thumbnail>
    </div>

    <div class="carousel">
        <pinch-zoom [backgroundColor]="'white'" style="height: 100%;">
            <div style="height: 100%;" [ngClass]="{'image-style': isImage()}">
                <ee-product-image alt="detailed image"
                                 [fit]="true"
                                 [src]="activeImage.zoom"></ee-product-image>
            </div>
        </pinch-zoom>
    </div>

    <!-- controls -->
    <button *ngIf="images.length > 1" class="control prev" (click)="onPreviousClick()">
        <span class="arrow left"></span>
    </button>
    <button *ngIf="images.length > 1" class="control next" (click)="onNextClick()">
        <span class="arrow right"></span>
    </button>
</div>