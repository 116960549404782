import { Injectable } from '@angular/core';
import { ServiceClientService } from 'client/angular/core/services/service-client/service-client.service';
import { WarehouseModel } from '../../models/warehouse.model';


@Injectable({
    providedIn: 'root'
})
export class WarehouseService{
    private allWarehouses: Promise<WarehouseModel[]>;
    constructor(private client: ServiceClientService){
    }

    async getAllWarehouses(): Promise<WarehouseModel[]> {
        this.allWarehouses =
            this.allWarehouses ||
            this.client.getAsync<WarehouseModel[]>('warehouse', `/GetAllWarehouses/`).toPromise();
        return await this.allWarehouses;
    }
}
