import { Directive, HostListener, Input } from '@angular/core';
import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';
import { GoogleTagDirectiveHost } from './google-tag-directive-host';

@Directive({
    selector: '[ee-google-tag]'
})
export class GoogleTagDirective {

    constructor(
        private host: GoogleTagDirectiveHost,
        private googleAnalytics: GoogleAnalyticsService) {
    }

    @Input('ee-google-tag') action: string;

    @HostListener('click') onClick() {
        const data = this.getData();

        this.googleAnalytics.sendEvent(data.title, data.action, data.label, data.value);
    }

    private getData(): { title: string, action: string, label: string, value: number } {
        const data = this.getHostData();

        return {
            title: data.componentName == null ? this.genericData.componentName : data.componentName,
            action: this.action || this.genericData.action,
            label: data.textDetail == null ? this.genericData.textDetail : data.textDetail,
            value: data.numericDetail || this.genericData.numericDetail
        };
    }

    private getHostData() {
        return this.host.getGoogleTagData();
    }

    private genericData = {
        componentName: 'Undetermined Event Category',
        action: 'Undetermined Event Action',
        textDetail: 'Undetermined Event Label',
        numericDetail: 0
    };
}
