<div class="modal-header">
    <h4 class="modal-title" ngbAutofocus>Item out of stock</h4>
    <button type="button" class="close btn btn-secondary" (click)="onDismiss()" ee-google-tag="X button">X</button>
</div>
<div class="modal-body">
    <h3>{{item?.itemName}}</h3>
    <p>{{outOfStockMessage}}</p>

    <div class="buttons">
        <p class="error" *ngIf="!hasSuccess" [ngClass]="{ 'visible' : hasError }">Failed to connect to server, please try again later.</p>
        <p class="success" *ngIf="hasSuccess">Successfully subscribed!</p>
        <ee-button
            *ngIf="!hasSuccess"
            class="action-item"
            styling="orange"
            ee-google-tag="Email Me When Available Button"
            [waiting]="pending"
            (clicked)="onSubscribe()">Email me when available</ee-button>
        <ee-button
            *ngIf="hasSuccess"
            class="action-item"
            styling="orange"
            ee-google-tag="Clicking 'Close' button after subscribed"
            (clicked)="onCloseDialog()">Close</ee-button>
        <ee-button
            *ngIf="similarProductsExist"
            styling="link"
            ee-google-tag="View Similar Items button"
            (clicked)="onViewSimilar()">View Similar Products</ee-button>
    </div>
</div>