<ee-product-actions-contacts class="only-for-print"
    [user]="user"></ee-product-actions-contacts>

<div class="free-shipping-banner">
    <div class="printable-background"><div></div></div>
    <div class="free-shipping-banner-text">FREE Shipping on orders over $250!</div>
</div>

<div class="panels">
    <ee-product-actions-price
        *ngIf="user != null && item != null"
        [retailPrice]="item.retailPrice"
        [userPrice]="item.userPrice"></ee-product-actions-price>

    <div *ngIf="item != null"
         class="printable-only-retail-price">
        <div>
            <div>QTY 1</div>
            <div>PRODUCT {{item.containerCount}} {{item.productDetails.deliveryMeasureAbbreviation}}</div>
            <div *ngIf="item.retailPrice > 0">Retail Price {{item.retailPrice | currency}}</div>
        </div>
    </div>

    <ee-product-actions-add
        *ngIf="user != null && item != null"
        [item]="item"
        [maximumValue]="getMaximumItemQuantityAllowed()"
        [showAddButton]="showAddButton()"
        [disableAddButton]="disableAddButton()"
        (itemSelected)="onItemSelected($event)"
        (itemAdded)="onItemAdded($event)"></ee-product-actions-add>

    <div *ngIf="isViewSimilarProductsButtonVisible()">
        <button type="button"
                class="btn btn-secondary view-similar-products-button"
                (click)="onViewSimilarProducts()">View Similar Products</button>
    </div>

    <ee-product-actions-back-in-stock
        *ngIf="user != null && showBackInStockSubscription && item != null"
        [item]="item"
        [outOfStockMessage]="outOfStockMessage"
        [similarProductsExist]="similarProductsExist"
        (scrollToSimilarProducts)="onViewSimilarProducts()"></ee-product-actions-back-in-stock>

    <ee-product-actions-free-shipping
        *ngIf="cart != null"
        [cart]="cart"></ee-product-actions-free-shipping>

    <ee-product-actions-unauthenticated
        *ngIf="notLoggedIn"></ee-product-actions-unauthenticated>
</div>

<ee-product-actions-contacts class="only-for-non-print"
    [user]="user"></ee-product-actions-contacts>
