
<div class="amount-and-dropdown-placeholder">
    <div class="amount" *ngIf="showAddButton && !disableAddButton">
        <label>QTY</label>
        <div>
            <button class="amount-left"
                    type="button"
                    (click)="addToAmount(-1)"
                    [disabled]="amount <= 1 || processing">-</button>

            <input type="text"
                   [ngModel]="amount"
                   [disabled]="processing"
                   (blur)="onBlur($event)"
                   (input)="onInput($event)" />

            <button class="amount-right"
                    type="button"
                    [disabled]="processing || amount >= maximumValue"
                    (click)="addToAmount(1)">+</button>
        </div>
    </div>
    <div class="dropdown">
        <label>Product</label>
        <div>
            <select *ngIf="item != null && item.productDetails != null && item.productDetails.items != null && item.productDetails.items.length > 0"
                    [disabled]="item.productDetails.items.length <= 1"
                    [(ngModel)]="selectedIndex"
                    (change)="onItemSelected()">
                <option *ngFor="let item of item.productDetails.items; let i = index"
                        value="{{i}}">{{item.containerCount}} {{item.productDetails.deliveryMeasureAbbreviation}}</option>
            </select>
        </div>
    </div>
</div>

<div *ngIf="showAddButton">
    <button type="button"
            class="btn btn-primary add-button"
            (click)="onAddItem()"
            [disabled]="disableAddButton || processing">
        <waiting-spinner *ngIf="processing" [size]="15" color="#1d3f50"></waiting-spinner>
        <span>Add to Cart</span>
    </button>
</div>