import { ServiceClientService } from 'client/angular/core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Category } from './model/category.model';
import {FeaturedProduct} from './model/featured-product.model';
import {FeaturedBrand} from './model/featured-brand.model';

@Injectable({
    providedIn: 'root'
})
export class MegaMenuService {
    constructor(private client: ServiceClientService){
    }

    getCategories(): Observable<Category[]>{
        return this.client.getAsync<Category[]>('ares-api', '/products/categories');
    }

    getFeaturedBrands(): Observable<FeaturedBrand[]>{
        return this.client.getAsync<FeaturedBrand[]>('ares-api', '/products/brands/featured?section=ShopMenu');
    }

    getFeaturedProducts(): Observable<FeaturedProduct[]>{
        return this.client.getAsync<FeaturedProduct[]>('ares-api', '/products/featured?section=ShopMenu');
    }

}
